/* eslint react/prop-types: 0 */
import {
  Box,
  Grid,
  SimpleGrid,
  Stack
} from '@mantine/core';
import React, { memo, useMemo } from 'react';
import { SectionPosition } from '../../js/generated/enums/SectionPosition';
import { PublishedQuestion } from './PublishedAssessmentQuestion';
import { useSelector } from 'react-redux';
import { selectPageById } from './pagesSlice';
import { selectBlockById } from './blocksSlice';

/**
 * @param {PublishedAssessmentPage} page
 */
export const PublishedAssessmentPage = memo(function PublishedAssessmentPage ({ pageId }) {
  const page = useSelector(state => selectPageById(state, pageId))
  const displayParams = useMemo(() => {
    return page?.show ? {} : { display: 'none' }
  }, [page?.show])

  console.debug('Rendering blocks for page', page)
  return (
    <Box w='100%' { ...displayParams }>
      <Stack gap='xl'>
        {!!page?.blockIds && page.blockIds.map(elem => <PublishedAssessmentBlock key={elem} blockId={elem} />)}
      </Stack>
    </Box>
  )
})

/**
 *
 * @param {PublishedAssessmentBlock} block
 */
const PublishedAssessmentBlock = memo(function PublishedAssessmentBlock ({ blockId }) {
  const block = useSelector(state => selectBlockById(state, blockId))
  const displayParams = useMemo(() => {
    return block?.show ? {} : { display: 'none' }
  }, [block?.show])

  console.debug('Rendering sections for block', block)
  if (!block) {
    return <></>
  }
  return (
    <>
      {block.hasCenter
        ? (
          <SimpleGrid cols={1} { ...displayParams }>
            <PublishedAssessmentSection section={block.sections[SectionPosition.Center]} />
          </SimpleGrid>
          )
        : (
          <Grid columns={2} align='flex-start' { ...displayParams }>
            <Grid.Col span={1}>
              <SimpleGrid cols={1}>
                <PublishedAssessmentSection section={block.sections[SectionPosition.Left]} />
              </SimpleGrid>
            </Grid.Col>
            <Grid.Col span={1}>
              <SimpleGrid cols={1}>
                <PublishedAssessmentSection section={block.sections[SectionPosition.Right]} />
              </SimpleGrid>
            </Grid.Col>
          </Grid>
          )}
    </>
  )
})

/**
 * @param {PublishedAssessmentPageSection} section
 */
function PublishedAssessmentSection ({ section }) {
  console.debug('Rendering question ids for section', section)
  return (
    <>
      {section.questionIds.map((questionId, index) => <PublishedQuestion key={questionId} questionId={questionId} />)}
    </>
  )
}
