import axios from 'axios'

export function getAccount (id) {
  return axios({
    method: 'GET',
    url: `api/v1/accounts/${id}`
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw err
    })
}

export async function myAccountList (json) {
  return axios({
    method: 'get',
    url: '/api/v1/accounts/my-account-list',
    data: json
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw err
    })
}

export async function invite (json) {
  return axios({
    method: 'post',
    url: '/api/v1/accounts/invite',
    data: json
  })
    .then(() => true)
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function inviteAdmin (json) {
  console.debug(json)

  return axios({
    method: 'post',
    url: '/api/v1/accounts/invite-admin',
    data: json
  })
    .catch(err => {
      console.error(err)
      throw err
    })
}

export async function signup (json) {
  return axios({
    method: 'post',
    url: '/api/v1/accounts/signup',
    data: json
  })
    .then(() => true)
    .catch(err => {
      console.error(err)
      return false
    })
}

/*
* Routes used by both standard and AP accounts
*/
export async function myAccount (apiBasePath, json) {
  return axios({
    method: 'get',
    url: `${apiBasePath}/my-account`,
    data: json
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw err
    })
}

export async function updateMyAccount (apiBasePath, json) {
  return axios({
    method: 'post',
    url: `${apiBasePath}/my-account`,
    data: json
  })
    .then(() => true)
    .catch(err => {
      console.error(err)
      throw err
    })
}

export async function updatePassword (apiBasePath, json) {
  return axios({
    method: 'post',
    url: `${apiBasePath}/my-account/update-password`,
    data: json
  })
    .then(() => true)
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function sendTwoFactorCode (apiBasePath, json) {
  return axios({
    method: 'post',
    url: `${apiBasePath}/my-account/two-factor-authentication`,
    data: json
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw err
    })
}

/*
* Routes unique to AP accounts to get around Symfony firewall.
*/
export async function myAccountData (json) {
  return axios({
    method: 'POST',
    url: 'assessment-platform/api/v1/accounts/my-account/data',
    data: json
  })
    .then(() => true)
    .catch(err => {
      console.error(err)
      throw err
    })
}

export async function myAccountDataStatus (json) {
  return axios({
    method: 'GET',
    url: 'assessment-platform/api/v1/accounts/my-account/data/status',
    data: json
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw err
    })
}
