import { createContext } from 'react'

/**
 * @typedef {object} InviteConfigTemplate
 * @property {Object<id: number>} phase
 * @property {number} days_until_display_deadline
 * @property {number} [seconds_until_expiration_date]
 * @property {InviteConfig} invite_config
 */

/**
 * @typedef {object} InviteConfig
 * @property {string} [internal_note]
 * @property {string} [expiration_date]
 * @property {string} [link_prefix]
 * @property {string} [auto_comment]
 * @property {number} [thank_you_style]
 * @property {boolean} [collect_demographics]
 * @property {boolean} [email_on_submit]
 */

/**
 * @type {InviteConfigTemplate|null}
 */
const defaultValue = null

const InviteConfigTemplatesContext = createContext(defaultValue)

export default InviteConfigTemplatesContext
