import React, { useState } from 'react'
import { Badge, Button, Flex, Group, Modal, Paper, SimpleGrid, TagsInput, Text, ThemeIcon, Title, Tooltip } from '@mantine/core'
import {
  IconFile,
  IconFileDescription,
  IconFileDownload,
  IconFileUpload,
  IconPdf,
  IconPhoto,
  IconTable,
  IconTrash,
  IconTxt
} from '@tabler/icons-react'
import { showNotification } from '@mantine/notifications'
import _ from 'lodash'
import { Link, useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { GoBackButton } from '../../core/GoBackButton'
import { useDeleteFileMutation } from '../../../redux/query/hire/filesApi.slice'
import { useGetLoggedInAccountQuery } from '../../../redux/query/account/accountsApi.slice'
import ErrorLoading from '../../core/ErrorLoading'
import Loading from '../../core/Loading'
import { isGranted } from '../../../util/account/account.util'

export default function FileHome ({ downloadBtn, files, cycleId }) {
  const [fileToRemove, setFileToRemove] = useState(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const [deleteFile, { isLoading: isDeleting }] = useDeleteFileMutation()
  const { data: account, isError, isLoading } = useGetLoggedInAccountQuery()

  if (isError) {
    return <ErrorLoading />
  }

  if (isLoading) {
    return <Loading />
  }

  const tags = searchParams.getAll('tags[]')

  const remove = () => {
    deleteFile({ id: fileToRemove.id })
      .unwrap()
      .then(() => {
        showNotification({
          message: 'Successfully deleted file',
          color: 'success'
        })
      })
      .catch(() => {
        showNotification({
          title: 'Something went wrong',
          message: 'There was an error deleting this file',
          color: 'red'
        })
      })
      .finally(() => {
        setFileToRemove(null)
      })
  }

  const selectToDelete = file => setFileToRemove(file)

  const FileIcon = ({ file, ...props }) => {
    const ext = _.last(file.identifier.split('.'))
    const icons = {
      doc: <IconFileDescription {...props} />,
      docx: <IconFileDescription {...props} />,
      xls: <IconTable {...props} />,
      xlsx: <IconTable {...props} />,
      pdf: <IconPdf {...props} />,
      txt: <IconTxt {...props} />,
      jpg: <IconPhoto {...props} />,
      png: <IconPhoto {...props} />
    }
    return icons[ext] || <IconFile />
  }

  return (
    <>
      <Modal opened={fileToRemove !== null} onClose={() => setFileToRemove(null)} title={<Title order={6}>Are you sure you want to delete this file?</Title>}>
        <Group>
          <Button color='red.6' type='submit' onClick={() => remove() } loading={isDeleting}>Delete</Button>
          <Button color='gray.6' onClick={() => setFileToRemove(null)}>Cancel</Button>
        </Group>
      </Modal>
      <Group spacing='xs' mb='md'>
        {downloadBtn}
        <Button disabled={!isGranted(account, 'ROLE_PORTAL_CLIENT')} color='blue.6' component={Link} to='upload' leftSection={<IconFileUpload size={18}/>}>Upload</Button>
        <GoBackButton href={`/cycles/${cycleId}/results`} />
      </Group>
      <TagsInput data={_.union(...files.map(file => file.tags), searchParams.getAll('tags[]'))} value={tags} onChange={tags => setSearchParams({ 'tags[]': tags })} label='Filter by tags'/>
      <SimpleGrid cols={{ lg: 3, md: 2 }} spacing='lg' verticalSpacing='lg' mt='sm' >
        {files.map(file => (
          (_.size(tags) === 0 || _.size(_.intersection(tags, file.tags)) > 0) &&
          <Paper shadow='sm' radius='md' withBorder style={{ display: 'flex', flexDirection: 'column', position: 'relative' }} key={file.id}>
            <Group justify='space-between'>
              <Group mb='sm' position='left' gap='sm'>
                {file.tags.map(tag => <Badge key={tag} color='pink' variant='light'>{tag}</Badge>)}
              </Group>
              <ThemeIcon size='xl' variant='subtle'><FileIcon file={file} style={{ width: '70%', height: '70%' }} /></ThemeIcon>
            </Group>
            <Tooltip label={file.identifier} position='bottom' placement='center'>
              <Text weight={700} mb='sm' fz='sm' truncate='end'>{file.identifier}</Text>
            </Tooltip>
            <Group justify='space-between'>
              <Text fz='sm'>{file.applicant && `${file.applicant.first_name} ${file.applicant.last_name}`}</Text>
              <Text fz='sm'>{dayjs(file.uploaded).format('MM/DD/YYYY')}</Text>
            </Group>
            <Flex style={{ flexGrow: 1 }}>
              <Text size='sm' c='dimmed'>{file.description}</Text>
            </Flex>
            <SimpleGrid cols={2} mt='lg'>
              <Button variant='light' color='blue' leftSection={<IconFileDownload />} onClick={() => {
                window.location.href = '/api/v1/files/' + file.id + '/download'
              }}>Download</Button>
              <Button disabled={!isGranted(account, 'ROLE_PORTAL_CLIENT')} variant='light' color='red' leftSection={<IconTrash />} onClick={() => selectToDelete(file)}>Delete</Button>
            </SimpleGrid>
          </Paper>
        ))}
      </SimpleGrid>
    </>
  )
}
