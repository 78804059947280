import React, { useMemo, useState, useEffect } from 'react'
import { List, Modal, Text } from '@mantine/core'
import { getUsersWithAccess } from '../../../js/api/cycle_repository'

export default function CycleAccessModal ({ cycleId, hasAdminAccess, opened = false, close = () => {} }) {
  const { clientUsers, portalUsers } = useCycleUserAccess(cycleId, hasAdminAccess)

  return (
    <Modal
      opened={opened}
      onClose={close}
      title='Users and Viewers with Access'
    >
      <List withPadding>
        <h4>Portal Users</h4>
        {clientUsers.length === 0 && <Text fs="italic">No users with access</Text>}
        {clientUsers.map((user, index) => (
          <List.Item key={index}>
            <Item user={user} />
          </List.Item>
        ))}
        <br />
        <h4>Portal Viewers</h4>
        {portalUsers.length === 0 && <Text fs="italic">No viewers with access</Text>}
        {portalUsers.map((viewer, index) => (
          <List.Item key={index}>
            <Item user={viewer} />
          </List.Item>
        ))}
      </List>
    </Modal>
  )
}

function Item ({ user }) {
  return (
    <Text>
      {user.first_name} {user.last_name} - <i>{user.email}</i>
    </Text>
  )
}

function useCycleUserAccess (cycleId, hasAdminAccess) {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [usersWithAccess, setUsersWithAccess] = useState([])

  useEffect(() => {
    if (!hasAdminAccess) return
    setIsLoading(true)
    getUsersWithAccess(cycleId)
      .then(response => {
        setError(null)
        setUsersWithAccess(response)
      })
      .catch(err => {
        setError(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [hasAdminAccess, cycleId])

  // Process users in a single useMemo
  const filteredUsers = useMemo(() => {
    const clientUsers = []
    const portalUsers = []

    if (usersWithAccess.length > 0) {
      clientUsers.push(...usersWithAccess.filter(user =>
        user.roles.map(r => r.name).includes('ROLE_PORTAL_CLIENT')
      ))

      portalUsers.push(...usersWithAccess.filter(user =>
        user.roles.map(r => r.name).includes('ROLE_PORTAL_USER')
      ))
    }

    return {
      clientUsers,
      portalUsers,
      isLoading,
      error
    }
  }, [usersWithAccess, isLoading, error])

  return filteredUsers
}
