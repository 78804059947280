import { RichTextEditor } from '@mantine/tiptap';
import { IconPercentage } from '@tabler/icons-react';
import Dropdown from './Dropdown';
import _ from 'lodash';
import { Menu } from '@mantine/core';
import React from 'react';

export function PlaceholdersControl ({ editor, platform, category }) {
  const applicant = []
  const candidate = []
  const facilitator = []
  const cycle = []
  const links = []

  if (platform === 0) {
    applicant.push(
      { wildcard: '%CANDIDATE%', label: 'Applicant\'s full name' },
      { wildcard: '%CANDIDATE_FIRST_NAME%', label: 'Applicant\'s first name' }
    )

    cycle.push(
      { wildcard: '%COMPANY%', label: 'Organization\'s name' },
      { wildcard: '%LOCATION%', label: 'Location\'s name' },
      { wildcard: '%JOB%', label: 'Job\'s name' },
      { wildcard: '%DEADLINE_DATE%', label: 'Deadline date' }
    )

    if (category === 3 || category === 6) {
      links.push({ wildcard: '%LINK%', label: 'Assessment Platform invite' })
    }

    if (category === 7 || category === 8 || category === 12) {
      links.push({ wildcard: '%KEYSURVEY_INVITE_LINK%', label: 'KeySurvey Prescreen invite' })
    }

    if (category === 9 || category === 10 || category === 12) {
      links.push({ wildcard: '%PRESCREEN_INVITE_LINK%', label: 'HireScore Prescreen invite' })
    }

    if (category === 12) {
      links.push({ wildcard: '%JOB_LINK%', label: 'Cycle\'s Job Posting URL' })
      links.push({ wildcard: '%UNSUBSCRIBE%', label: 'Unsubscribe Link' })
    } else {
      links.push({ wildcard: '%PHASE_INVITE%', label: 'Phase Invite' })
    }
  } else if (platform === 1) {
    candidate.push(
      { wildcard: '%CANDIDATE%', label: 'Candidate\'s full name' },
      { wildcard: '%CANDIDATE_EMAIL%', label: 'Candidate\'s email address' },
      { wildcard: '%CANDIDATE_PASSWORD%', label: 'Candidate\'s login password' }
    )

    facilitator.push(
      { wildcard: '%FACILITATOR%', label: 'Facilitator\'s full name' },
      { wildcard: '%FACILITATOR_EMAIL%', label: 'Facilitator\'s email address' },
      { wildcard: '%FACILITATOR_PHONE%', label: 'Facilitator\'s phone number' }
    )

    cycle.push(
      { wildcard: '%JOB%', label: 'Job\'s name' }
    )

    links.push(
      { wildcard: '%URL%', label: 'Login page' }
    )
  } else if (platform === 2) { // 2 === Edit Questions. Note mirrored logic in AnswerEditor for mini-placeholders.
    cycle.push(
      { wildcard: '%COMPANY%', label: 'Organization\'s name' },
      { wildcard: '%JOB%', label: 'Job\'s name' },
      { wildcard: '%LOCATION%', label: 'Cycle\'s location' }
    )
  }

  const PlaceholdersButton = (
    <RichTextEditor.Control
      aria-label='Insert placeholder'
      title='Insert placeholder'
    >
      <IconPercentage size='1rem' />
    </RichTextEditor.Control>
  )

  return (
    <Dropdown closeOnItemClick={false} trigger='click' target={PlaceholdersButton}>
      {
        _.size(candidate) > 0 && (
          <>
            <Menu.Label>Candidate</Menu.Label>
            {_.map(candidate, placeholder => (<Menu.Item key={placeholder.wildcard} onClick={() => editor?.chain().insertContent(placeholder.wildcard).focus(null, { scrollIntoView: true }).run()}>{placeholder.label}</Menu.Item>))}
          </>
        )
      }
      {
        _.size(applicant) > 0 && (
          <>
            <Menu.Label>Applicant</Menu.Label>
            {_.map(applicant, placeholder => (<Menu.Item key={placeholder.wildcard} onClick={() => editor?.chain().insertContent(placeholder.wildcard).focus(null, { scrollIntoView: true }).run()}>{placeholder.label}</Menu.Item>))}
          </>
        )
      }
      {
        _.size(facilitator) > 0 && (
          <>
            <Menu.Label>Facilitator</Menu.Label>
            {_.map(facilitator, placeholder => (<Menu.Item key={placeholder.wildcard} onClick={() => editor?.chain().insertContent(placeholder.wildcard).focus(null, { scrollIntoView: true }).run()}>{placeholder.label}</Menu.Item>))}
          </>
        )
      }
      {
        _.size(cycle) > 0 && (
          <>
            <Menu.Label>Cycle</Menu.Label>
            {_.map(cycle, placeholder => (<Menu.Item key={placeholder.wildcard} onClick={() => editor?.chain().insertContent(placeholder.wildcard).focus(null, { scrollIntoView: true }).run()}>{placeholder.label}</Menu.Item>))}
          </>
        )
      }
      {
        _.size(links) > 0 && (
          <>
            <Menu.Label>Links</Menu.Label>
            {_.map(links, placeholder => (<Menu.Item key={placeholder.wildcard} onClick={() => editor?.chain().insertContent(placeholder.wildcard).focus(null, { scrollIntoView: true }).run()}>{placeholder.label}</Menu.Item>))}
          </>
        )
      }
    </Dropdown>
  )
}
