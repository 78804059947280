import React from 'react'
import { Button, Group, SimpleGrid } from '@mantine/core'
import OrganizationTable from './OrganizationTable'
import { Link } from 'react-router-dom'

export default function OrganizationHome () {
  return (
    <SimpleGrid cols={1}>
      <Group>
        <Button component={Link} to='create' color='teal.6'>Create Organization</Button>
      </Group>
      <OrganizationTable />
    </SimpleGrid>
  )
}
