import React, { memo, useCallback, useState } from 'react';
import { Button, Group, Title } from '@mantine/core';
import { BaseModal } from './BaseModal';
import { ImportQuestionListItem } from './ImportQuestionsModal';
import { IconChevronsDown, IconChevronsUp, IconExchange } from '@tabler/icons-react'
import { QuestionTypeSelection } from './AddNewQuestion';

const fakeSelectDispatch = (action) => console.debug('Selection dispatch not implemented for settings modal.', { action })

export const QuestionSettingsModal = memo(function QuestionSettingsModal ({ questionTypes, editQuestionId, question, setEditQuestionId, dispatch }) {
  return (
    <BaseModal
      title='Question Settings'
      size='70%'
      showing={!!editQuestionId}
      onClose={() => setEditQuestionId(null)}
      keepMounted={false}
    >
      <QuestionSettingsActions
        questionTypes={questionTypes}
        dispatch={dispatch}
        question={question}
        mode={editQuestionId?.mode}
        setEditQuestionId={setEditQuestionId}
      />
      {!!question && (
        <ImportQuestionListItem
          question={question}
          selected={false}
          dispatch={fakeSelectDispatch}
        />
      )}
    </BaseModal>
  )
})

const insertAbovePrompt = 'Inserting Question Above'
const changeQuestionTypePrompt = 'Changing Question Type'
const insertBelowPrompt = 'Inserting Question Below'

const QuestionSettingsActions = memo(function QuestionSettingsActions ({ questionTypes, dispatch, question, mode, setEditQuestionId }) {
  const [editMode, setEditMode] = useState(mode)
  const onClose = useCallback(() => {
    setEditQuestionId(null)
  }, [setEditQuestionId])

  const onCancel = useCallback(() => {
    setEditMode(null)
  }, [])

  const isModifying = editMode === changeQuestionTypePrompt
  return (
    <>
      {!editMode && (
        <Group justify='space-between' mx='xl' mb='xl'>
          <Button leftSection={<IconChevronsUp />} onClick={() => setEditMode(insertAbovePrompt)}>
            Add Question Above
          </Button>
          <Button leftSection={<IconExchange />} onClick={() => setEditMode(changeQuestionTypePrompt)}>
            Change Question Type
          </Button>
          <Button leftSection={<IconChevronsDown />} onClick={() => setEditMode(insertBelowPrompt)}>
            Add Question Below
          </Button>
        </Group>
      )}
      {!!editMode && (
        <>
          <Title mb='lg'>{editMode}</Title>
          <QuestionTypeSelection
            questionTypes={questionTypes}
            dispatch={dispatch}
            onCancel={onCancel}
            onClose={onClose}
            newPosition={editMode === insertBelowPrompt ? (question?.position ?? -1) + 1 : question?.position }
            modifying={isModifying ? (question?.id ?? null) : null}
          />
        </>
      )}
    </>
  )
})
