import axios from 'axios'
import { AssessmentResponse, assessmentResponseFromId } from '../generated/enums/AssessmentResponse'
import { formatResponseData } from '../util/DataUtil'

export function getAssessment (id, params = null, cancelToken = null) {
  const config = {
    method: 'GET',
    url: `api/v1/assessments/${id}`
  }
  if (params) {
    config.params = params
  }
  if (cancelToken) {
    config.cancelToken = cancelToken
  }

  return axios(config)
    .then(response => response.data)
    .catch(err => {
      if (axios.isCancel(err)) {
        console.info('Cancelled axios assessment data request via token.', config)
      } else {
        console.error('Assessment data request error.', err)
        throw err
      }
    })
}

export function getAssessments (json, cancelToken = null) {
  const config = {
    method: 'GET',
    url: 'api/v1/assessments',
    params: json
  }
  if (cancelToken) {
    config.cancelToken = cancelToken
  }
  return axios(config)
    .then(response => formatResponseData(response.data))
    .catch(err => {
      if (axios.isCancel(err)) {
        console.info('Cancelled axios assessments request via token.', config)
      } else {
        console.error('Assessments request error.', err)
        throw err
      }
    })
}

export function getAssessmentPurposes (cancelToken = null) {
  const config = {
    method: 'GET',
    url: 'api/v1/assessments/types'
  }
  if (cancelToken) {
    config.cancelToken = cancelToken
  }
  return axios(config)
    .then(response => response.data)
    .catch(err => {
      if (axios.isCancel(err)) {
        console.info('Cancelled axios assessment purposes (types) request via token.', config)
      } else {
        console.error('Assessment purposes (types) request error.', err)
        throw err
      }
    })
}

export function postEditForm (data, id = null) {
  return axios.post(window.location.pathname, data)
    .then(response => {
      if (response.status === 202) {
        const json = response.data ?? {}
        return { success: true, status: 202, ...json }
      }
      console.error('Unexpected response to post edit form.', response, id, data)
      return { success: false, nonRecoverable: true, status: 500 };
    })
    .catch(err => {
      console.error('Error posting edited form data.', err, id, data)
      throw err
    })
}

export function publish (id, json) {
  return axios({
    method: 'POST',
    url: `api/v1/assessments/${id}/publish`,
    data: json
  })
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function exportAssessment (id, json) {
  return axios({
    method: 'POST',
    url: `api/v1/assessments/${id}/export`,
    data: json,
    responseType: 'arraybuffer'
  })
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function fetchPublishedAssessmentQuestions (id, json = null, cancelToken = null) {
  const config = {
    method: 'POST',
    url: `/testenv/assessments/api/${id}/preview`
  }
  if (json) {
    config.data = json
  }
  if (cancelToken) {
    config.cancelToken = cancelToken
  }
  return axios(config)
    .then(response => ({ responseType: assessmentResponseFromId(response.status), error: false, data: response.data ?? {} }))
    .catch(err => {
      if (axios.isCancel(err)) {
        console.info('Cancelled axios published assessment questions request via token.', config)
      } else if (err.response?.status && assessmentResponseFromId(err.response.status) !== AssessmentResponse.UnexpectedError) {
        console.info('Got expected error response from assessment questions request', config, err.response.status, err.response, err.request, err)
        return { error: true, responseType: assessmentResponseFromId(err.response.status), data: err.response.data }
      } else {
        console.error('Published assessment questions request unexpected error.', err, err.response, err.request, err.response?.status, err.request?.status)
        return { error: true, responseType: AssessmentResponse.UnexpectedError, data: {} }
      }
    })
}
