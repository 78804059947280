import React from 'react'
import { Anchor, Button, Flex } from '@mantine/core'

export default function CreateButtons () {
  return (
    <Flex w='auto'>
      <Button variant='filled' color='success' href='/skillbuilder/processes' component={Anchor}>Create Cycle</Button>
    </Flex>
  )
}
