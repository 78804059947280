import { createContext } from 'react';

/**
 * @type {{ timeLimit: ?int, displayNumbers: ?boolean, next: ?string, recordResponse: ?string, assessmentId: ?string, fetch: ?string, currentPageIndex: int, auth: object, previousSubmissionLocation: ?string, previousSubmissionOffset: ?int }}
 */
export const defaultPublishedAssessmentContext = {
  displayNumbers: false, next: null, recordResponse: null, timeLimit: null, assessmentId: null, fetch: null, currentPageIndex: 0, auth: {}, previousSubmissionLocation: null, previousSubmissionOffset: null
}

export const PublishedAssessmentContext = createContext({ ...defaultPublishedAssessmentContext })
