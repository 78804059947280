import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import DisplayTrigger from '../display/DisplayTrigger'
import PropTypes from 'prop-types'

export default function TriggerCreateForm ({ statusSet, onSubmit, integrations = {} }) {
  const methods = useForm({
    defaultValues: {
      name: '',
      strategy: '1',
      onetime: true,
      enabled: true,
      conditions: [{ code: 'default' }],
      consequents: [{ code: 'default' }],
      metadata: {
        days_until_display_deadline: 10,
        emails: [{ template: null, schedule: null, after_execution: null, email_program: null }]
      }
    },
    shouldUnregister: true
  })

  return (
    <FormProvider {...methods} >
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <DisplayTrigger
          statusSet={statusSet}
          integrations={integrations}
        />
      </form>
    </FormProvider>
  )
}

TriggerCreateForm.propTypes = {
  statusSet: PropTypes.object.isRequired,
  integrations: PropTypes.object,
  onSubmit: PropTypes.func.isRequired
}
