import { ApiAbstractIds, ApiMethods, apiSlice, ApiTags, providesList } from '../../../react/api'
import { cycleListTag, paramsToQuery } from '../../../react/util/queries';
import { formatResponseData } from '../../../js/util/DataUtil';
import { rowsFailed, rowsLoaded } from '../../../react/core/ReactTable/tableRowsSlice';

export const cyclesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCycles: builder.query({
      query: (params) => `cycles${paramsToQuery(params)}`,
      providesTags: (result, error, id) => providesList(result, ApiTags.Cycles)
    }),
    getCycle: builder.query({
      query: id => `cycles/${id}`,
      providesTags: (result, error, id) => {
        if (typeof result === 'undefined') {
          return [{ type: ApiTags.Cycles, id: id }];
        }

        return [
          { type: ApiTags.Cycles, id: id },
          ...result.triggers.map(trigger => ({ type: ApiTags.Triggers, id: trigger.id }))
        ]
      }
    }),
    getCycleIntegrations: builder.query({
      query: id => `cycles/${id}/integrations`,
      providesTags: (result, error, id) => [{ type: ApiTags.Cycles, id: id }]
    }),
    getCycleFiles: builder.query({
      query: id => `cycles/${id}/files`,
      providesTags: result => providesList(result, ApiTags.Files)
    }),
    uploadCycleFile: builder.mutation({
      query: ({ id, ...data }) => {
        const formData = new FormData()
        formData.append('file', data.file)
        formData.append('description', data.description)

        data.tags.map(tag => formData.append('tags[]', tag))

        return {
          url: `cycles/${id}/files`,
          method: ApiMethods.Post,
          body: formData
        }
      },
      invalidatesTags: () => [{ type: ApiTags.Files, id: ApiAbstractIds.List }]
    }),
    getBatteries: builder.query({
      query: (params) => `cycles/batteries${paramsToQuery(params)}`,
      transformResponse: (response) => formatResponseData(response),
      providesTags: (result) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.Batteries, id: id })),
              { type: ApiTags.Batteries, id: ApiAbstractIds.PartialList }
            ]
          : [{ type: ApiTags.Batteries, id: ApiAbstractIds.PartialList }]
    }),
    getCycleApplicantsDetails: builder.query({
      query: ({ cycleId, namespace, ...params }) => `cycles/${cycleId}/applicants/details${paramsToQuery(params)}`,
      providesTags: (result, _, { cycleId: cycleIdParam }) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.Applicants, id: id })),
              { type: ApiTags.Applicants, id: `cycle-${cycleIdParam}` }
            ]
          : [{ type: ApiTags.Applicants, id: `cycle-${cycleIdParam}` }],
      onQueryStarted: async function onQueryStarted (params, { dispatch, queryFulfilled, requestId }) {
        console.info('Applicants table query started', { params, requestId })
        if (params?.namespace) {
          try {
            const { data } = await queryFulfilled
            console.info('Applicants table query succeeded', { data, requestId })
            dispatch(rowsLoaded({ data: data, namespace: params.namespace, requestId: requestId }))
          } catch (err) {
            console.warn('Applicants table query failed', { err })
            dispatch(rowsFailed({ error: err?.message ?? 'Query failed', namespace: params.namespace }))
          }
        }
      }
    }),
    getCycleTableModuleInfos: builder.query({
      query: ({ cycleId, limit }) => `cycles/${cycleId}/table-modules?limit=${limit ?? 0}`,
      providesTags: (result, _, { cycleId: cycleIdParam }) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.ModuleInfos, id: id })),
              { type: ApiTags.ModuleInfos, id: `cycle-${cycleIdParam}` }
            ]
          : [{ type: ApiTags.ModuleInfos, id: `cycle-${cycleIdParam}` }]
    }),
    getCycleStatuses: builder.query({
      query: ({ cycleId, limit }) => `cycles/${cycleId}/status?limit=${limit ?? 0}`,
      providesTags: (result, _, { cycleId: cycleIdParam }) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.Statuses, id: id })),
              { type: ApiTags.Statuses, id: `cycle-${cycleIdParam}` }
            ]
          : [{ type: ApiTags.Statuses, id: `cycle-${cycleIdParam}` }]
    }),
    getCycleRegions: builder.query({
      query: ({ cycleId, limit }) => `cycles/${cycleId}/regions?limit=${limit ?? 0}`,
      providesTags: (result, _, { cycleId: cycleIdParam }) =>
        result?.items
          ? [
              ...result.items.map((regionName) => ({ type: ApiTags.Regions, id: regionName })),
              { type: ApiTags.Regions, id: `cycle-${cycleIdParam}` }
            ]
          : [{ type: ApiTags.Regions, id: `cycle-${cycleIdParam}` }]
    }),
    getCycleDegrees: builder.query({
      query: ({ cycleId, limit }) => `cycles/${cycleId}/degrees?limit=${limit ?? 0}`,
      providesTags: (result, _, { cycleId: cycleIdParam }) =>
        result?.items
          ? [
              ...result.items.map((degreeName) => ({ type: ApiTags.Degrees, id: degreeName })),
              { type: ApiTags.Degrees, id: `cycle-${cycleIdParam}` }
            ]
          : [{ type: ApiTags.Degrees, id: `cycle-${cycleIdParam}` }]
    }),
    getCycleEmailTemplates: builder.query({
      query: id => `cycles/${id}/templates`,
      providesTags: (result, error, id) => [
        { type: ApiTags.Cycles, id: id },
        ...providesList(result, ApiTags.EmailTemplates)
      ]
    }),
    getCycleTextTemplates: builder.query({
      query: id => `cycles/${id}/text-templates`,
      providesTags: (result, error, id) => [
        { type: ApiTags.Cycles, id: id },
        ...providesList(result, ApiTags.TextTemplates)
      ]
    }),
    getCycleBatteriesLastSubmissions: builder.query({
      query: ({ cycleId, batteryId }) => `cycles/${cycleId}/batteries/${batteryId}/last-submissions`,
      providesTags: (result, error, { cycleId, batteryId }) => [
        { type: ApiTags.Cycles, id: cycleId },
        { type: ApiTags.Batteries, id: batteryId },
        ...providesList(result, ApiTags.BatterySubmissions)
      ]
    }),
    addCycleBattery: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `cycles/${id}/batteries`,
        method: ApiMethods.Post,
        body: body
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ApiTags.Cycles, id: id }]
    }),
    editCycleBattery: builder.mutation({
      query: ({ id, batteryId, ...body }) => ({
        url: `cycles/${id}/batteries/${batteryId}`,
        method: ApiMethods.Patch,
        body: body
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ApiTags.Cycles, id: id }]
    }),
    deleteCycleBattery: builder.mutation({
      query: ({ id, batteryId }) => ({
        url: `cycles/${id}/batteries/${batteryId}`,
        method: ApiMethods.Delete
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ApiTags.Cycles, id: id }]
    }),
    toggleCycleBattery: builder.mutation({
      query: ({ id, batteryId }) => ({
        url: `cycles/${id}/batteries/${batteryId}/toggle`,
        method: ApiMethods.Post
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ApiTags.Cycles, id: id }],

      /**
       * This function assumes the toggling will succeed in order to update the toggle button quicker.
       * See: https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates#optimistic-updates
       */
      onQueryStarted: async ({ id, batteryId }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData('getCycle', id, draft => {
            const linkedBattery = draft.cycle_batteries.find(linkedBattery => linkedBattery.id === batteryId)

            if (linkedBattery) {
              linkedBattery.active = !linkedBattery.active
            }
          })
        )

        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    }),
    deleteCycleTrigger: builder.mutation({
      query: ({ id, triggerId }) => ({
        url: `cycles/${id}/triggers/${triggerId}`,
        method: ApiMethods.Delete
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ApiTags.Cycles, id: id }]
    }),
    toggleCycleTrigger: builder.mutation({
      query: ({ id, triggerId }) => ({
        url: `cycles/${id}/triggers/${triggerId}/toggle`,
        method: ApiMethods.Post
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ApiTags.Cycles, id: id }],
      /**
       * This function assumes the toggling will succeed in order to update the toggle button quicker.
       * See: https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates#optimistic-updates
       */
      onQueryStarted: async ({ id, triggerId }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData('getCycle', id, draft => {
            const trigger = draft.triggers.find(trigger => trigger.id === triggerId)

            if (trigger) {
              trigger.enabled = !trigger.enabled
            }
          })
        )

        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    }),
    getCycleApplicants: builder.query({
      query: ({ cycleId, ...params }) => `cycles/${cycleId}/applicants${paramsToQuery(params)}`,
      providesTags: (result, _, { cycleId: cycleIdParam }) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: ApiTags.Applicants, id: id })),
              { type: ApiTags.Applicants, id: cycleListTag(cycleIdParam) }
            ]
          : [{ type: ApiTags.Applicants, id: cycleListTag(cycleIdParam) }]
    }),
    getCycleCourtesyLetterRecipients: builder.query({
      query: (cycleId) => `cycles/${cycleId}/courtesy-letter-recipients`,
      providesTags: (result, _, cycleIdParam) =>
        result?.data
          ? [
              ...result.data.map((id) => ({ type: ApiTags.Applicants, id: id })),
              { type: ApiTags.Applicants, id: cycleListTag(cycleIdParam) }
            ]
          : [{ type: ApiTags.Applicants, id: cycleListTag(cycleIdParam) }]
    }),
    getAssignedCyclePhases: builder.query({
      query: (cycleId) => `cycles/${cycleId}/assigned_phases`,
      providesTags: (result, _, cycleIdParam) =>
        [{ type: ApiTags.CyclesPhases, id: cycleListTag(cycleIdParam) }]
    }),
    getAssignedCyclesGroupPhases: builder.query({
      query: (cycleIds) => `cycles/assigned_phases${paramsToQuery({ cycleIds })}`,
      providesTags: (result, _, cycleIdsParam) =>
        [...(cycleIdsParam?.map(cycleIdParam => ({ type: ApiTags.CyclesPhases, id: cycleListTag(cycleIdParam) })) ?? null)]
    }),
    getCycleStageGenerationStatus: builder.query({
      query: (cycleId) => `cycles/${cycleId}/stage_generation_status`,
      providesTags: (result, _, cycleIdParam) => [
        { type: ApiTags.CyclesPhases, id: cycleListTag(cycleIdParam) },
        { type: ApiTags.CyclesStages, id: cycleListTag(cycleIdParam) },
        { type: ApiTags.Assessments, id: cycleListTag(cycleIdParam) }
      ]
    }),
    generateCycleStages: builder.mutation({
      query: ({ cycleId, ...body }) => ({
        url: `cycles/${cycleId}/generate_stages`,
        method: ApiMethods.Post,
        body: body
      }),
      invalidatesTags: (result, error, { cycleId: cycleIdParam }) => [
        { type: ApiTags.CyclesPhases, id: cycleListTag(cycleIdParam) },
        { type: ApiTags.CyclesStages, id: cycleListTag(cycleIdParam) },
        { type: ApiTags.CycleOpenInvites, id: cycleListTag(cycleIdParam) },
        { type: ApiTags.AssessmentInvites, id: cycleListTag(cycleIdParam) },
        { type: ApiTags.CyclePasses, id: cycleListTag(cycleIdParam) },
        { type: ApiTags.StageProgresses, id: cycleListTag(cycleIdParam) }
      ]
    })
  })
})

export const {
  useGetCyclesQuery,
  useGetCycleQuery,
  useGetCycleIntegrationsQuery,
  useGetCycleFilesQuery,
  useUploadCycleFileMutation,
  useGetBatteriesQuery,
  useGetCycleApplicantsDetailsQuery,
  useGetCycleTableModuleInfosQuery,
  useGetCycleStatusesQuery,
  useGetCycleRegionsQuery,
  useGetCycleDegreesQuery,
  useGetCycleEmailTemplatesQuery,
  useGetCycleTextTemplatesQuery,
  useGetCycleBatteriesLastSubmissionsQuery,
  useAddCycleBatteryMutation,
  useEditCycleBatteryMutation,
  useDeleteCycleBatteryMutation,
  useToggleCycleBatteryMutation,
  useDeleteCycleTriggerMutation,
  useToggleCycleTriggerMutation,
  useGetCycleApplicantsQuery,
  useGetCycleCourtesyLetterRecipientsQuery,
  useGetAssignedCyclePhasesQuery,
  useGetAssignedCyclesGroupPhasesQuery,
  useGetCycleStageGenerationStatusQuery,
  useGenerateCycleStagesMutation
} = cyclesApi
