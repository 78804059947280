import { Title, Center, Space, Stack } from '@mantine/core';
import React from 'react';
import { ThankYouStyle } from '../../../../js/generated/enums/ThankYouStyle';
import NoticeAlert from '../../../core/Alert/NoticeAlert';

export function CyclePassThankYou () {
  const dataContainer = document.getElementById('react-app-container')
  const thankYouStyle = parseInt(dataContainer?.getAttribute('data-thankYouStyle') ?? ThankYouStyle.ThankYouForYourTime.toString())
  return (
    <>
      <Space h='xl' />
      {thankYouStyle === ThankYouStyle.KnockedOut ? <PassportQualificationMessage /> : <PassportThankYouForYourTimeMessage />}
    </>
  )
}

function PassportQualificationMessage () {
  return (
    <Stack gap='lg'>
      <Title ta='center'>
        Thank you for the anonymous information you’ve provided.
      </Title>
      <Title ta='center'>
        Currently, your qualifications do not match the requirements of this position.
      </Title>
      <Title order={3} ta='center'>
        Feel free to complete the Online Application again if your qualifications change.
      </Title>
      <Center>
        <NoticeAlert title='Additional opportunities'>
          You may also sign up for job alerts on jobodds.com if you would like to be automatically notified of other
          positions that match your interests.<br/>
          Registration is free and confidential.
        </NoticeAlert>
      </Center>
    </Stack>
  )
}

function PassportThankYouForYourTimeMessage () {
  return (
    <Center>
      <Title>
        Thank you for your time! Be sure to check your email regularly for additional information.
      </Title>
    </Center>
  )
}
