import { useParams } from 'react-router-dom';
import { CyclePageTitle } from '../nav/CyclePageTitle';
import { DiagnosticsNavButtonTabs } from '../nav/DiagnosticsNavButton';
import { rem, Space, Tabs } from '@mantine/core';
import { LinksNavButton } from '../nav/LinksNavButton';
import { OpenInvitesTable } from '../open/OpenInvitesTable';
import React, { useState } from 'react';
import { AssessmentInvitesTable } from '../sent/AssessmentInvitesTable';
import { IconUserCircle, IconWorld } from '@tabler/icons-react';
import { CycleStageReplacementApp } from '../stages/CycleStageReplacementApp';

export function LinksList () {
  const { cycleId } = useParams()
  const [stagesValid, setStagesValid] = useState(null)
  return (
    <>
      <CyclePageTitle cycleId={cycleId} pageName='Links' active={DiagnosticsNavButtonTabs.Links} />
      <Space h='xs' />
      <LinksNavButton cycleId={cycleId} />
      <Space h='md' />
      <LinksTables cycleId={cycleId} />
      <CycleStageReplacementApp cycleId={cycleId} valid={stagesValid} setValid={setStagesValid} />
    </>
  )
}

const iconStyle = { width: rem(12), height: rem(12) }

function LinksTables ({ cycleId, namespace = 'cycles-links' }) {
  return (
    <>
      <Tabs defaultValue='public'>
        <Tabs.List>
          <Tabs.Tab value='public' leftSection={<IconWorld style={iconStyle} />}>
            Public Links
          </Tabs.Tab>
          <Tabs.Tab value='phase' leftSection={<IconUserCircle style={iconStyle} />}>
            Private Invite Links
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value='public'>
          <>
            <Space h='sm' />
            <OpenInvitesTable cycleId={cycleId} namespace={namespace} />
          </>
        </Tabs.Panel>

        <Tabs.Panel value='phase'>
          <>
            <Space h='sm' />
            <AssessmentInvitesTable cycleId={cycleId} namespace={namespace} />
          </>
        </Tabs.Panel>
      </Tabs>
    </>
  )
}
