import React from 'react'
import Logo from './Logo'
import Base from '../../react/layout/Base'
import MenuWrapper from './MenuWrapper'
import { Container } from '@mantine/core'
import * as classes from './Sidebar.module.scss'

/**
 * This component renders the side bar of HireScore.
 * @returns {JSX.Element}
 */
function SideBar () {
  return (
    <Base>
      <Container className={classes.container}>
        <Logo />
        <MenuWrapper />
      </Container>
    </Base>
  )
}

export default SideBar
