import React, { useCallback, useState } from 'react'
import { useCycleAssessment } from '../util/SkillBuilder/CycleAssessmentHooks'
import { showNotification } from '@mantine/notifications'
import {
  exportSkillBuilderCycleAssessment
} from '../../js/api/skillbuilder_repository';
import { AssessmentPDFExport } from '../build/assessment/AssessmentPDFExport';
import _ from 'lodash';
import Content from '../layout/Content'

const configToAssessmentParamsMap = {
  order_by_competency: 'orderByCompetency',
  randomization_seed: 'randomSeed'
}

const configToExportParamsMap = {
  order_by_competency: 'orderByCompetency',
  show_correct_answer: 'displayCorrectAnswers',
  show_competencies: 'displayCompetencies'
}

export default function Export () {
  const assessmentId = document.getElementById('export-skillbuilder-assessment-container').getAttribute('data-assessment-id')
  const cycleId = document.getElementById('export-skillbuilder-assessment-container').getAttribute('data-cycle-id')
  const [assessmentParams, setAssessmentParams] = useState({
    order_by_competency: false,
    randomization_seed: 0
  })
  const [assessment, querying] = useCycleAssessment(cycleId, assessmentId, assessmentParams)

  const updateAssessmentParams = useCallback((newConfig) => {
    let anyChanged = false
    const newParams = { ...assessmentParams }
    for (const [paramKey, configKey] of Object.entries(configToAssessmentParamsMap)) {
      const newConfigValue = newConfig[configKey] ?? null
      if (newParams[paramKey] !== newConfigValue) {
        newParams[paramKey] = newConfigValue
        anyChanged = true
        if (newConfigValue === null) {
          console.error('Unable to find expected config key when updating assessment params.', configKey, newConfig)
        }
      }
    }
    if (anyChanged) {
      console.debug('Updating assessment params. old|new|pdfConfig', assessmentParams, newParams, newConfig)
      setAssessmentParams(newParams)
    }
  }, [assessmentParams])

  const assessmentName = assessment?.name ?? 'Assessment'

  const exportLegacyAssessment = useCallback((pdfParams) => {
    const exportParams = Object.fromEntries(
      Object.entries(configToExportParamsMap)
        .map(([exportKey, pdfKey]) => [exportKey, pdfParams[pdfKey] ?? null])
    )
    console.info('Exporting to legacy pdf.', { exportParams, pdfParams })

    return exportSkillBuilderCycleAssessment(cycleId, assessmentId, exportParams)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${assessmentName} Export.pdf`)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
        URL.revokeObjectURL(url)
      })
      .catch(err => {
        const data = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(err.response.data)))

        if (err.response.status === 400 && data?.type === 'validation_error') {
          _.forEach(data.errors, error => {
            showNotification({
              title: 'Something went wrong',
              message: error,
              color: 'red',
              autoClose: 3000
            })
          })
        } else {
          showNotification({
            message: 'Something went wrong',
            color: 'red',
            autoClose: 3000
          })
        }
      })
  }, [cycleId, assessmentId, assessmentName])

  return (
    <Content>
      <AssessmentPDFExport
        assessment={assessment}
        querying={!!querying}
        updateAssessmentParams={updateAssessmentParams}
        exportLegacyAssessment={exportLegacyAssessment}
      />
    </Content>
  )
}
