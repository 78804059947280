import React, { memo, useCallback, useContext, useState } from 'react';
import { PublishedAssessmentContext } from './PublishedAssessmentContext';
import { Box, Button, Group, Modal, Space, Stack, Text } from '@mantine/core';
import { ImportResponse } from './AssessmentResponseHandler';
import NoticeAlert from '../core/Alert/NoticeAlert';
import { renderDateFromSource } from '../hire/cycle/invites/formatUtil';
import { importPreviousAssessmentSubmission } from '../../js/api/published_assessment_repository';
import { AssessmentResponse } from '../../js/generated/enums/AssessmentResponse';

export const PreviousSubmissionImportHandler = memo(function PreviousSubmissionImportHandler () {
  const {
    previousSubmissionLocation,
    previousSubmissionOffset
  } = useContext(PublishedAssessmentContext)
  console.debug('PreviousSubmissionImportHandler updating', { previousSubmissionLocation, previousSubmissionOffset })

  const [show, setShow] = useState(!!previousSubmissionLocation)
  const [responseData, setResponseData] = useState({
    responseType: null,
    responseData: null,
    success: false,
    fail: false
  })
  const [loading, setLoading] = useState(false)

  const importAssessment = useCallback(async () => {
    console.debug('Called import previous submission', { previousSubmissionLocation, loading })
    if (loading) {
      return
    }
    setLoading(true)
    const response = await importPreviousAssessmentSubmission(previousSubmissionLocation)
    const failed = !response?.responseType || response.responseType === AssessmentResponse.UnexpectedError
    setResponseData({
      responseType: response?.responseType ?? AssessmentResponse.UnexpectedError,
      responseData: response?.data,
      success: !failed,
      fail: failed
    })
    setLoading(false)
  }, [previousSubmissionLocation, loading])

  if (!previousSubmissionLocation) {
    return null
  }

  return (
    <>
      <ImportSubmissionModal
        opened={show}
        close={() => setShow(false)}
        previousSubmissionOffset={previousSubmissionOffset}
        importAssessment={importAssessment}
        loading={loading}
      />
      <ImportResponse attemptImport={importAssessment} {...responseData} />
    </>
  )
})

function ImportSubmissionModal ({ opened, close, previousSubmissionOffset, importAssessment, loading }) {
  const previousSubmissionDate = renderDateFromSource(previousSubmissionOffset ? new Date(previousSubmissionOffset * 1000) : null)
  return (
    <Modal
      opened={opened}
      onClose={close}
      withCloseButton={false}
      centered
      overlayProps={{ blur: 3, backgroundOpacity: 0.7 }}
      withinPortal
      zIndex={900}
      size='lg'
    >
      <Box w='100%'>
        <Stack>
          <NoticeAlert title='Import Previous Submission' fz='xl'>
            <Text fz='lg'>You previously completed this assessment{previousSubmissionDate ? ` on ${previousSubmissionDate}` : ''}. Would you like to import those responses and skip the assessment?</Text>
          </NoticeAlert>
          <Space h='md' />
          <Group justify='space-between' grow wrap='nowrap'>
            <Button
              color='gray'
              size='lg'
              onClick={close}
              disabled={loading}
            >
              Retake Assessment
            </Button>
            <Button
              color='success'
              size='lg'
              onClick={importAssessment}
              loading={loading}
            >
              Use Previous
            </Button>
          </Group>
        </Stack>
      </Box>
    </Modal>
  )
}
