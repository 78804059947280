/* eslint react/prop-types: 0 */
import React, { memo, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Alert,
  Box,
  Center,
  Group,
  Loader,
  Skeleton,
  Space,
  Stack,
  Radio
} from '@mantine/core';
import { Sparkline } from '@mantine/charts';
import { IconInfoCircle } from '@tabler/icons-react';
import { AssessmentInviteType } from '../../../../js/generated/enums/AssessmentInviteType';
import { FunnelChart, Tooltip, Funnel, LabelList, ResponsiveContainer, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, Legend, PieChart, Pie, Cell } from 'recharts';
import * as transitionClasses from './CycleInviteHome.module.scss';
import { useIsAdmin } from './CyclesHooks';
import { CycleStageReplacementApp } from './stages/CycleStageReplacementApp';
import { formatDateFromSource, renderDateFromSource } from './formatUtil';
import { CyclePageTitle } from './nav/CyclePageTitle';
import { CycleSummaryCard } from './summaries/CycleSummaryCard';
import { useCyclePassAnalytics } from './passes/CyclePassHooks';
import { DiagnosticsNavButtonTabs } from './nav/DiagnosticsNavButton';

export function CycleInviteAnalytics () {
  const { cycleId } = useParams()
  const [isAdmin, adminLoading] = useIsAdmin()
  const [analytics, analyticsQuerying] = useCyclePassAnalytics(cycleId, !cycleId)
  const [stagesValid, setStagesValid] = useState(null)

  const querying = (analyticsQuerying || adminLoading)
  return (
    <>
      <CycleInviteAnalyticsHeader cycleId={cycleId} cycle={analytics} />
      {!!querying && <CycleInviteAnalyticsCardPlaceholder />}
      {!querying && !analytics && <CycleInviteAnalyticsCardError />}
      {!querying && !!analytics && <CycleInviteAnalyticsCard cycle={analytics} cycleId={cycleId} isAdmin={isAdmin} />}
      <CycleStageReplacementApp cycleId={cycleId} valid={stagesValid} setValid={setStagesValid} />
    </>
  )
}

function CycleInviteAnalyticsHeader ({ cycleId, cycle }) {
  const recruitedByMonth = useMemo(() => {
    return getRecruitedByMonth(cycle)
  }, [cycle])
  return (
    <Box mx='2rem'>
      <CyclePageTitle cycleId={cycleId} pageName='Analytics' active={DiagnosticsNavButtonTabs.Analytics}>
        {!!recruitedByMonth?.length && (
          <Sparkline
            w={200}
            h={60}
            data={recruitedByMonth}
            curveType="linear"
            color="blue"
            fillOpacity={0.6}
            strokeWidth={2}
          />
        )}
      </CyclePageTitle>
    </Box>
  )
}

function CycleInviteAnalyticsCardPlaceholder () {
  return (
    <Box mx='2rem'>
      <Space h='xl' />
      <Stack justify='flex-start'>
        <Skeleton height={12} radius="xl" miw='40rem' w='100%' />
        <Skeleton height={12} mt={10} radius="xl" miw='40rem' w='100%' />
        <Skeleton height={12} mt={10} miw='24rem' w="60%" radius="xl" />
        <Skeleton height={12} mt={10} radius="xl" miw='40rem' w='100%' />
        <Skeleton height={12} mt={10} radius="xl" miw='40rem' w='100%' />
        <Skeleton height={12} mt={10} miw='24rem' w="60%" radius="xl" />
      </Stack>
      <Space h='xl' />
      <Center>
        <Loader type="bars" />
      </Center>
    </Box>
  )
}

function getRecruitedByMonth (cycle) {
  let firstYear = null
  let firstMonth = null
  const dataSegments = !cycle?.cycle_passes?.length
    ? []
    : cycle.cycle_passes.reduce((acc, pass) => {
      const passCreatedAt = formatDateFromSource(pass.created_at)
      const monthKey = passCreatedAt.getMonth()
      const yearKey = passCreatedAt.getYear()
      if (firstYear === null) {
        firstYear = yearKey
        firstMonth = monthKey
      }
      if (!acc[yearKey]) {
        acc[yearKey] = []
      }
      if (!acc[yearKey][monthKey]) {
        acc[yearKey][monthKey] = []
      }
      acc[yearKey][monthKey].push(pass)
      return acc
    }, [])
  const today = new Date()
  const currentYear = today.getYear()
  const currentMonth = today.getMonth()
  let yearCounter = firstYear
  let monthCounter = firstMonth - 1
  let stop = !cycle
  const dataBuckets = []
  while (!stop) {
    const yearRecords = dataSegments[yearCounter] ?? []
    const monthRecords = yearRecords[monthCounter] ?? []
    dataBuckets.push(monthRecords.length)

    if (monthCounter < 11) {
      monthCounter += 1
    } else {
      yearCounter += 1
      monthCounter = 0
    }
    if ((yearCounter > currentYear) || ((yearCounter === currentYear) && (monthCounter > currentMonth))) {
      stop = true
    }
  }
  return dataBuckets
}

function CycleInviteAnalyticsCard ({ cycle, cycleId, isAdmin = false }) {
  console.debug('Cycle analytics card updating', { cycleId, cycle, isAdmin })

  return (
    <>
      <Box>
        {cycle.cycle_passes?.length ? <CycleInviteActivitySummary cycle={cycle} /> : <CycleNoInvitesSentSummary cycle={cycle} />}
        <Stack justify='flex-start' align='center'>
          {!!cycle && <CycleSummaryCard cycle={cycle} isAdmin={isAdmin} />}
        </Stack>
      </Box>
      <Space h='xxl' />
      <Space h='xxl' />
      <Space h='xxl' />
    </>
  )
}

const CHARTS_COLOR_OPTIONS = ['#FFADAD', '#FFD6A5', '#FDFFB6', '#CAFFBF', '#9BF6FF', '#A0C4FF', '#BDB2FF', '#FFC6FF', '#FFFFFC', '#84DCC6', '#FF686B', '#3a5a40', '#023047', '#3d405b']

function getSourceFromPass (pass) {
  if (!pass.assessment_invites?.length) {
    return 'Unknown'
  }
  const firstInvite = pass.assessment_invites[0]
  const firstInviteType = firstInvite.type
  return (firstInviteType === AssessmentInviteType.Public) ? firstInvite.open_invite_recipient?.open_invite?.name ?? 'Unknown Open Invite' : firstInviteType
}

function getLastStageFromPass (pass, hiredIndex) {
  if (pass.applicant?.status?.tier?.id === 2) {
    return { name: 'Hired', index: hiredIndex }
  }
  if (!pass.stage_progresses?.length) {
    return { name: null, index: 0 }
  }
  const lastStageProgress = pass.stage_progresses[pass.stage_progresses.length - 1]
  return { name: lastStageProgress.stage?.assessment?.name ?? null, index: lastStageProgress.stage?.index }
}

function mapPassesToSourceStage (cycle, passes) {
  const getUnknownStageName = (index) => {
    return cycle.stages[index]?.assessment?.name ?? `Unknown Stage #${index + 1}`
  }

  const dataBuckets = {}
  const dataCategories = new Set()
  for (const pass of passes) {
    // const stage = getLastStageFromPass(pass)
    const stage = getLastStageFromPass(pass, cycle.stages.length)
    const { name, index } = (stage ?? {})
    const source = getSourceFromPass(pass)
    if (!dataBuckets[source]) {
      dataBuckets[source] = {}
    }
    const stageName = name ?? getUnknownStageName(index)
    if (!dataBuckets[source][stageName]) {
      dataBuckets[source][stageName] = 0
    }
    dataBuckets[source][stageName] = dataBuckets[source][stageName] + 1
    dataCategories.add(stageName)
  }

  const data = []
  const labelGroups = Object.keys(dataBuckets).map((label, index) => ({ name: label, opacity: 0.1, color: CHARTS_COLOR_OPTIONS[index % CHARTS_COLOR_OPTIONS.length] }))
  // const allCategories = [ ...cycle.stages.map((stage, index) => stage.assessment?.name ?? `Unknown Stage #${index + 1}`), ...['Interview', 'Hired'], ...dataCategories ]
  const allCategories = [...cycle.stages.map((stage, index) => stage.assessment?.name ?? `Unknown Stage #${index + 1}`), ...['Hired'], ...dataCategories]
  const orderedCategories = [...(new Set(allCategories))]

  // Format radar and funnel chart data
  const allSourcesFunnel = []
  const funnels = Object.fromEntries(Object.keys(dataBuckets).map(sourceName => [sourceName, []]))
  let categoryCounter = 0
  let maxFromSingleSourceAnyCategoryCount = 0
  for (const category of orderedCategories) {
    const totals = { category }
    let categorySumAllSources = 0
    const categoryFillColor = CHARTS_COLOR_OPTIONS[categoryCounter % CHARTS_COLOR_OPTIONS.length]

    for (const [bucket, bucketData] of Object.entries(dataBuckets)) {
      const categoryTotalForSource = bucketData[category] ?? 0
      maxFromSingleSourceAnyCategoryCount = Math.max(maxFromSingleSourceAnyCategoryCount, categoryTotalForSource)
      totals[bucket] = categoryTotalForSource // Radar
      categorySumAllSources += categoryTotalForSource // All sources funnel
      funnels[bucket].push({ name: category, value: categoryTotalForSource, fill: categoryFillColor }) // Individual source funnel
    }
    data.push(totals)
    allSourcesFunnel.push({ name: category, value: categorySumAllSources, fill: categoryFillColor })
    categoryCounter += 1
  }

  // Format pie chart data
  const pieData = []
  for (const { name, color } of labelGroups) {
    const bucketData = dataBuckets[name] ?? {}
    let value = 0
    for (const stageTotal of Object.values(bucketData)) {
      value += stageTotal
    }
    pieData.push({ name, value, color })
  }
  return [data, labelGroups, pieData, funnels, allSourcesFunnel, { maxFromSingleSourceAnyCategoryCount }]
}

const FUNNEL_CHART_MARGIN = { top: 50, right: 50, bottom: 0, left: 0 }

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.6
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text x={x} y={y} fill='black' textAnchor='middle' dominantBaseline='central'>
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

const centerOffsetTolerance = 0.05

function renderPolarAngleAxis ({ payload, x, y, cx, cy, ...rest }) {
  const dynamicYOffset = 5
  const yBelow = y > (cy + (cy * centerOffsetTolerance))
  const yAbove = !yBelow && (y < (cy - (cy * centerOffsetTolerance)))
  const newY = yBelow ? y + (2 * dynamicYOffset) : (yAbove ? y - dynamicYOffset : y)
  return (
    <text
      {...rest}
      y={newY}
      x={x}
      fill='white'
    >
      {payload.value}
    </text>
  )
}

function renderPieLabel (props) {
  console.debug('Rendering pie label', props)
  const { viewBox, value } = props
  const { cx, cy, startAngle, endAngle, outerRadius } = viewBox
  const midAngle = (startAngle + endAngle) / 2
  const x = cx + outerRadius * Math.cos(-midAngle * RADIAN)
  const y = cy + outerRadius * Math.sin(-midAngle * RADIAN)

  const dynamicXOffset = 5
  const xRight = x > (cx + (cx * centerOffsetTolerance))
  const xLeft = !xRight && (x < (cx - (cx * centerOffsetTolerance)))
  const textAnchor = xRight ? 'start' : (xLeft ? 'end' : 'middle')
  const newX = xRight ? x + dynamicXOffset : (xLeft ? x - dynamicXOffset : x)

  const dynamicYOffset = 10
  const yBelow = y > (cy + (cy * centerOffsetTolerance))
  const yAbove = !yBelow && (y < (cy - (cy * centerOffsetTolerance)))
  const newY = yBelow ? y + (2 * dynamicYOffset) : (yAbove ? y - dynamicYOffset : y)

  console.debug('Calculated pie label props', { textAnchor, newX, newY, value, midAngle, x, y, startAngle, endAngle })
  return (
    <text
      x={newX}
      y={newY}
      fill='white'
      textAnchor={textAnchor}
    >
      {value}
    </text>
  )
}

const inactiveGray = '#D3D3D3AA'

// TODO ensure open invite source names are unique per cycle and don't conflict with built-in/reserved (private, email, public, unknown, account)
const CycleInviteActivitySummary = memo(function CycleInviteActivitySummary ({ cycle }) {
  const [funnel, setFunnel] = useState('__ALL__')
  const [radarData, radarBuckets, pieData, funnelPerSource, allSourcesFunnel, { maxFromSingleSourceAnyCategoryCount }] = useMemo(() => {
    return mapPassesToSourceStage(cycle, cycle.cycle_passes)
  }, [cycle])
  const [radarDataColors, setRadarDataColors] = useState(Object.fromEntries(radarBuckets.map(bucket => [bucket.name, { color: bucket.color, active: true }])))

  const toggleRadarActive = ({ dataKey }) => {
    console.debug('Toggling radar active from click.', dataKey)
    if (dataKey) {
      setRadarDataColors(prev => ({ ...prev, [dataKey]: { ...(prev[dataKey] ?? {}), active: !prev[dataKey]?.active } }))
    }
  }

  console.debug('Invite activity summary component updating.', { cycle, radarData, radarBuckets, pieData, maxFromSingleSourceAnyCategoryCount })
  return (
    <>
      <Radio.Group
        value={funnel}
        onChange={setFunnel}
        name='FunnelSource'
        mb='1rem'
      >
        <Group justify='center' >
          <Radio value='__ALL__' label='All' />
          {Object.keys(funnelPerSource).map(source => <Radio key={source} value={source} label={source} />)}
        </Group>
      </Radio.Group>
      <Box pos='relative' miw='100%' w='100%'>
        <Box pos='absolute' top='0px' left='0px' miw='100%' w='100%'>
          <ResponsiveContainer width='100%' height={500}>
            <FunnelChart margin={FUNNEL_CHART_MARGIN}>
              <Tooltip />
              <Funnel
                dataKey='value'
                data={funnelPerSource[funnel] ?? allSourcesFunnel}
                isAnimationActive
              >
                <LabelList position='left' fill='#000' stroke='none' dataKey='name' />
              </Funnel>
            </FunnelChart>
          </ResponsiveContainer>
        </Box>
      </Box>
      <Box>
        <div className={transitionClasses.wavySpacer}></div>
        <Box bg='#29292f'>
          <Group mt={0}>
            <ResponsiveContainer width='45%' height={500}>
              <RadarChart data={radarData}>
                <PolarGrid />
                <PolarAngleAxis dataKey='category' tick={renderPolarAngleAxis} />
                <PolarRadiusAxis angle={30} domain={[0, maxFromSingleSourceAnyCategoryCount]} />
                {radarBuckets.map(bucket => <Radar key={bucket.name} name={bucket.name} dataKey={bucket.name} stroke={radarDataColors[bucket.name]?.active ? bucket.color : inactiveGray} fill={radarDataColors[bucket.name]?.active ? bucket.color : inactiveGray} fillOpacity={radarDataColors[bucket.name]?.active ? bucket.opacity : 0} strokeOpacity={radarDataColors[bucket.name]?.active ? 1 : 0} />)}
                <Legend onClick={toggleRadarActive} wrapperStyle={{ userSelect: 'none', cursor: 'pointer' }}/>
              </RadarChart>
            </ResponsiveContainer>
            <ResponsiveContainer width='45%' height={500}>
              <PieChart width={400} height={400}>
                <Pie data={pieData} dataKey='value' nameKey='name' fill='#8884d8' labelLine={false} label={renderCustomizedLabel}>
                  <LabelList dataKey='name' position='outside' offset={10} content={renderPieLabel} />
                  {pieData.map((entry) => (
                    <Cell key={entry.name} fill={entry.color} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Group>
        </Box>
        <Box pos='relative' miw='100%' w='100%'>
          <Box pos='absolute' top='0px' left='0px' miw='100%' w='100%' style={{ zIndex: -1 }}>
            <div className={transitionClasses.flippedWavySpacer}></div>
          </Box>
        </Box>
      </Box>
      <Box miw='100%' w='100%' h='15rem'/>
    </>
  )
})

function CycleNoInvitesSentSummary ({ cycle }) {
  return (
    <Alert variant='light' title='No Invite Recipient Data' icon={<IconInfoCircle />}>
      {`This cycle was created on ${renderDateFromSource(cycle.created_at)}. It has no invite recipients!`}
    </Alert>
  )
}

function CycleInviteAnalyticsCardError () {
  return (
    <>
      <Space h='xl' />
      <Alert variant='light' title='Query Error' icon={<IconInfoCircle />}>
        Oh no! There was a query error. Try refreshing the page while we work on a way to fix it!
      </Alert>
    </>
  )
}
