/* eslint react/prop-types: 0 */
import React, { useEffect, useMemo, useRef } from 'react';
import { getAnimationConfigFromInviteStyle } from './LandingPageStyle';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadFull } from 'tsparticles';
import { InviteStyle } from '../../../../../js/generated/enums/InviteStyle';

export function LandingPageBackground ({ init, setInit, landingStyle = null }) {
  console.debug('Landing page background updating', { init, landingStyle })
  const staticStyleRef = useRef(landingStyle ?? InviteStyle.Default)

  // this should be run only once per application lifetime
  useEffect(() => {
    if (staticStyleRef.current === InviteStyle.Skip) {
      setInit(true)
      return
    }
    initParticlesEngine(async (engine) => {
      await loadFull(engine)
    }).then(() => {
      setInit(true)
    })
  }, [setInit])

  const particlesLoaded = async (container = null) => {
    console.log('Particles loaded', container)
  }

  const options = useMemo(
    () => getAnimationConfigFromInviteStyle(staticStyleRef.current),
    []
  )

  if (init && (landingStyle !== InviteStyle.Skip)) {
    return (
      <div style={{ zIndex: -1, position: 'absolute' }}>
        <Particles
          id="tsparticles"
          particlesLoaded={particlesLoaded}
          options={options}
        />
      </div>
    )
  }
  return (
    <>
    </>
  )
}
