import axios from 'axios'
import { AssessmentResponse, assessmentResponseFromId } from '../generated/enums/AssessmentResponse'

export async function beginPublishedAssessment (url, json = null, cancelToken = null) {
  const config = {
    method: 'POST',
    url: url
  }
  if (json) {
    config.data = json
  }
  if (cancelToken) {
    config.cancelToken = cancelToken
  }
  return axios(config)
    .then(response => ({ responseType: assessmentResponseFromId(response.status), error: false, data: response.data ?? {} }))
    .catch(err => {
      if (axios.isCancel(err)) {
        console.info('Cancelled axios published assessment assessment start request via token.', config)
      } else if (err.response?.status && assessmentResponseFromId(err.response.status) !== AssessmentResponse.UnexpectedError) {
        console.info('Got expected error response from start request', config, err.response.status, err.response, err.request, err)
        return { error: true, responseType: assessmentResponseFromId(err.response.status), data: err.response.data }
      } else {
        console.error('Published assessment start request unexpected error.', err, err.response, err.request, err.response?.status, err.request?.status)
        return { error: true, responseType: AssessmentResponse.UnexpectedError, data: {} }
      }
    })
}

export async function submitPublishedAssessmentPage (url, data, cancelToken = null) {
  const config = {
    method: 'POST',
    url: url,
    data: data
  }
  if (cancelToken) {
    config.cancelToken = cancelToken
  }
  return axios(config)
    .then(response => ({ error: false, responseType: assessmentResponseFromId(response.status), data: response.data ?? {} }))
    .catch(err => {
      if (axios.isCancel(err)) {
        console.info('Cancelled axios published assessment page submit request via token.', config, data)
      } else if (err.response?.status && assessmentResponseFromId(err.response.status) !== AssessmentResponse.UnexpectedError) {
        console.info('Got expected error response from submission', config, err.response.status, err)
        return { error: true, responseType: assessmentResponseFromId(err.response.status), data: err.response.data }
      } else {
        console.error('Published assessment page submit request error.', err, err.response, err.request, err.response?.status, err.request?.status)
        return { error: true, responseType: AssessmentResponse.UnexpectedError, data: {} }
      }
    })
}

export async function importPreviousAssessmentSubmission (url, cancelToken = null) {
  const config = {
    method: 'POST',
    url: url
  }
  if (cancelToken) {
    config.cancelToken = cancelToken
  }
  return axios(config)
    .then(response => ({ error: false, responseType: assessmentResponseFromId(response.status), data: response.data ?? {} }))
    .catch(err => {
      if (axios.isCancel(err)) {
        console.info('Cancelled axios previous submission import request via token.', config)
      } else if (err.response?.status && assessmentResponseFromId(err.response.status) !== AssessmentResponse.UnexpectedError) {
        console.info('Got expected error response from previous submission import', config, err.response.status, err)
        return { error: true, responseType: assessmentResponseFromId(err.response.status), data: err.response.data }
      } else {
        console.error('Previous submission import request error.', err, err.response, err.request, err.response?.status, err.request?.status)
        return { error: true, responseType: AssessmentResponse.UnexpectedError, data: {} }
      }
    })
}

export async function submitPublishedAssessmentSingleQuestionResponse (url, data, cancelToken = null) {
  const config = {
    method: 'POST',
    url: url,
    data: data
  }
  if (cancelToken) {
    config.cancelToken = cancelToken
  }
  return axios(config)
    .then(response => ({ error: false, responseType: assessmentResponseFromId(response.status), responseCode: response.status, data: response.data ?? {} }))
    .catch(err => {
      if (axios.isCancel(err)) {
        console.info('Cancelled axios published assessment single question response submit request via token.', config, data)
      } else if (err.response?.status && assessmentResponseFromId(err.response.status) !== AssessmentResponse.UnexpectedError) {
        console.info('Got expected error response from single question response submit.', config, err.response.status, { err })
        return { error: true, responseType: assessmentResponseFromId(err.response.status), responseCode: err.response.status, data: err.response.data }
      } else {
        console.error('Published assessment single question response submit request error.', err, err.response, err.request, err.response?.status, err.request?.status)
        return { error: true, responseType: AssessmentResponse.UnexpectedError, responseCode: err.response?.status ?? null, data: {} }
      }
    })
}
