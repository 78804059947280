import { ApiAbstractIds, ApiMethods, apiSlice, ApiTags } from '../../../react/api'

export const filesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    deleteFile: builder.mutation({
      query: ({ id }) => ({
        url: `files/${id}`,
        method: ApiMethods.Delete
      }),
      invalidatesTags: () => [{ type: ApiTags.Files, id: ApiAbstractIds.List }]
    })
  })
})

export const {
  useDeleteFileMutation
} = filesApiSlice
