import { apiSlice, ApiTags, providesList } from '../../../react/api'
import { paramsToQuery } from '../../../react/util/queries'

export const publishedAssessmentsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getPublishedAssessments: builder.query({
      query: params => `published-assessments${paramsToQuery(params)}`,
      providesTags: result => providesList(result, ApiTags.PublishedAssessments)
    }),
    getPublishedAssessment: builder.query({
      query: id => `published-assessments/${id}`,
      providesTags: (result, error, id) => [{ type: ApiTags.PublishedAssessments, id: id }]
    })
  })
})

export const { useGetPublishedAssessmentsQuery, useGetPublishedAssessmentQuery } = publishedAssessmentsApi
