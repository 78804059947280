import Base from '../../../layout/Base';
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes
} from 'react-router-dom';
import React from 'react';
import { CyclePassDetail } from './passes/CyclePassDetail';
import { PhaseInviteDetail } from './sent/PhaseInviteDetail';
import { CycleStages } from './stages/CycleStages';
import { CycleInviteAnalytics } from './CycleInviteAnalytics';
import { Box } from '@mantine/core';
import { OpenInvitesCreate } from './open/OpenInvitesCreate';
import { AssessmentInviteEdit } from './sent/AssessmentInviteEdit';
import { CycleStageProgressDetail } from './progresses/StageProgressDetail';
import { CycleStageDetail } from './stages/CycleStageDetail';
import { PublicLinkDetail } from './open/PublicLinkDetail';
import { PhaseInvitesList, PassportsPhaseInvitesList } from './sent/PhaseInvitesList';
import { PublicLinksList } from './open/PublicLinksList';
import { CyclePassesList } from './passes/CyclePassesList';
import { StageProgressesList } from './progresses/StageProgressesList';
import { InvitePreview } from './landing/InvitePreview';
import { CyclePassEditEmail } from './passes/CyclePassEditEmail';
import { LinksList } from './landing/LinksList';

export function CycleInviteApp () {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/invites/manage/cycles/:cycleId' element={<CycleInviteAppWrapper />}>
          <Route index element={<CycleInviteHome />} />
          <Route path='analytics'>
            <Route index element={<CycleInviteAnalytics />} />
          </Route>
          <Route path='sent' element={<CycleInviteAppFrame />}>
            <Route index element={<PhaseInvitesList />} />
            <Route path='create/new'>
              <Route index element={<AssessmentInviteEdit />} />
              <Route path='preview' element={<InvitePreview />} />
            </Route>
          </Route>
          <Route path='open' element={<CycleInviteAppFrame />}>
            <Route index element={<PublicLinksList />} />
            <Route path='create/new'>
              <Route index element={<OpenInvitesCreate />} />
              <Route path='preview' element={<InvitePreview />} />
            </Route>
            <Route path=':openInviteId'>
              <Route index element={<PublicLinkDetail />} />
              <Route path='edit' element={<OpenInvitesCreate />} />
              <Route path='preview' element={<InvitePreview />} />
              <Route path='invitees/:inviteeId'>
                <Route path='preview' element={<InvitePreview />} />
              </Route>
            </Route>
          </Route>
          <Route path='passes' element={<CycleInviteAppFrame />}>
            <Route index element={<CyclePassesList />} />
            <Route path=':passId'>
              <Route index element={<CyclePassDetail />} />
              <Route path='edit'>
                <Route path='email' element={<CyclePassEditEmail />} />
              </Route>
              <Route path='invites'>
                <Route index element={<PassportsPhaseInvitesList />} />
                <Route path='create/new'>
                  <Route index element={<AssessmentInviteEdit />} />
                  <Route path='preview' element={<InvitePreview />} />
                </Route>
                <Route path=':inviteId'>
                  <Route index element={<PhaseInviteDetail />} />
                  <Route path='edit' element={<AssessmentInviteEdit />} />
                  <Route path='preview' element={<InvitePreview />} />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path='stages' element={<CycleInviteAppFrame />}>
            <Route index element={<CycleStages />} />
            <Route path=':stageId'>
              <Route index element={<CycleStageDetail />} />
            </Route>
          </Route>
          <Route path='progresses' element={<CycleInviteAppFrame />}>
            <Route index element={<StageProgressesList />} />
            <Route path=':progressId'>
              <Route index element={<CycleStageProgressDetail />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

function CycleInviteAppWrapper () {
  return (
    <Base>
      <Outlet />
    </Base>
  )
}

function CycleInviteAppFrame () {
  return (
    <Box mx='2rem'>
      <Outlet />
    </Box>
  )
}

function CycleInviteHome () {
  return (
    <Box mx='2rem'>
      <LinksList />
    </Box>
  )
}
