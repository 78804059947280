/* eslint react/prop-types: 0 */
import React, { memo, useCallback, useState, useRef } from 'react';
import {
  Checkbox,
  Paper,
  Grid,
  NumberInput,
  TextInput,
  Center,
  Box,
  ActionIcon,
  Transition,
  Collapse,
  Space,
  Slider,
  Popover,
  Button, Stack
} from '@mantine/core';
import { useClickOutside, useDisclosure } from '@mantine/hooks';
import { QuestionStateUpdate } from './QuestionsState';
import { hasFixedAnswers } from '../../../../js/modules/Build/Assessment/QuestionType';
import { isPresetRating, QuestionType } from '../../../../js/generated/enums/QuestionType';
import { MAX_SCORE_PRECISION } from './util';
import { useDispatch } from 'react-redux';
import { associatedAnswersChanged } from './UnpublishedQuestionLogic/unpublishedLogicSlice';
import { IconPercentage, IconSettings, IconX } from '@tabler/icons-react';
import { COL_SPANS, getKnockoutColor, OrderColumn } from './AnswersList';
import * as checkboxStyles from './AnswersListCheckbox.module.scss';
import { TagColumn } from './AnswerTagEditor';

const KNOCKOUT_CONTROL_CLOSE_DELAY = 500
const slideTransition = {
  in: { opacity: 1, transform: 'translateX(100%)' },
  out: { opacity: 0, transform: 'translateX(0)' },
  transitionProperty: 'transform, opacity'
}
const answerEditorInputStyles = {
  input: { fontSize: '1rem' }
}

/**
 * @param {Answer} answer
 * @param {QuestionType} questionType
 * @param {boolean} hasDependentLogic
 * @param dragging
 * @param dispatch
 */
const AnswerEditor = memo(function AnswerEditor ({ answer, position, hasDependentLogic, questionType, dragging, dispatch, handleProps }) {
  const [content, setContent] = useState(answer.content)
  const contentDirty = useRef(null)
  const reduxDispatch = useDispatch()
  const isPresetRatingQuestion = isPresetRating(questionType)
  const editContentRef = useClickOutside(() => {
    window.clearTimeout(contentDirty.current)
    if (answer.content !== content) {
      dispatch({ type: QuestionStateUpdate.UpdateAnswer, answerId: answer.id, questionId: answer.questionId, newAttributes: { content } })
      if (hasDependentLogic) {
        reduxDispatch(associatedAnswersChanged({ questionId: answer.questionId }))
      }
    }
  })

  const updateAnswerProps = useCallback((newAttributes) => {
    dispatch({ type: QuestionStateUpdate.UpdateAnswer, answerId: answer.id, questionId: answer.questionId, newAttributes: newAttributes })
  }, [answer.id, answer.questionId, dispatch])

  const updateAnswerText = useCallback(() => {
    window.clearTimeout(contentDirty.current)
    dispatch({ type: QuestionStateUpdate.UpdateAnswer, answerId: answer.id, questionId: answer.questionId, newAttributes: { content } })
    if (hasDependentLogic) {
      reduxDispatch(associatedAnswersChanged({ questionId: answer.questionId }))
    }
  }, [answer.id, answer.questionId, content, dispatch, reduxDispatch, hasDependentLogic])

  const bufferAnswerText = useCallback((event) => {
    window.clearTimeout(contentDirty.current)
    const initialContent = event.currentTarget.value
    setContent(initialContent)
    contentDirty.current = window.setTimeout(() => {
      dispatch({
        type: QuestionStateUpdate.UpdateAnswer,
        answerId: answer.id,
        questionId: answer.questionId,
        newAttributes: { content: initialContent }
      })
      if (hasDependentLogic) {
        reduxDispatch(associatedAnswersChanged({ questionId: answer.questionId }))
      }
    }, 1000)
  }, [answer.id, answer.questionId, setContent, dispatch, reduxDispatch, hasDependentLogic])

  const isInterview = questionType === QuestionType.Interview
  const isFillInTheBlank = questionType === QuestionType.FillInTheBlank
  const isInterviewOrFillInBlank = isInterview || isFillInTheBlank
  const isChooseAll = questionType === QuestionType.ChooseAllThatApply

  // TODO use of LeftSection in text input is prevented by styling from elsewhere in site, likely foundation.

  const newPlaceholder = 'New Answer'
  const answerIsNew = content === newPlaceholder

  const addPlaceholderToContent = (placeholder) => {
    window.clearTimeout(contentDirty.current)
    const initialContent = (answerIsNew ? '' : content) + placeholder
    setContent(initialContent)
    editContentRef.current.focus()
    editContentRef.current.setSelectionRange(initialContent.length, initialContent.length)
    contentDirty.current = window.setTimeout(() => {
      dispatch({
        type: QuestionStateUpdate.UpdateAnswer,
        answerId: answer.id,
        questionId: answer.questionId,
        newAttributes: { content: initialContent }
      })
      if (hasDependentLogic) {
        reduxDispatch(associatedAnswersChanged({ questionId: answer.questionId }))
      }
    }, 1000)
  }

  return (
    <Paper
      bg={dragging ? 'blue.1' : 'gray.2'}
      pos='relative'
    >
      <Grid justify='flex-start' align='center'>
        {isFillInTheBlank
          ? null
          : <OrderColumn position={position} handleProps={handleProps} />
        }
        <Grid.Col span={COL_SPANS.answers}>
          <Box py={4}>
            <TextInput
              aria-label='Answer content input'
              styles={answerEditorInputStyles}
              placeholder={answerIsNew ? 'New Answer' : 'Blank Answer'}
              value={answerIsNew ? '' : content}
              onChange={bufferAnswerText}
              onBlur={updateAnswerText}
              disabled={hasFixedAnswers(questionType) && !isInterviewOrFillInBlank}
              ref={editContentRef}
              rightSection={hasFixedAnswers(questionType) && !isInterviewOrFillInBlank
                ? null
                : (
                <Popover withArrow trapFocus shadow='md'>
                  <Popover.Target>
                    <ActionIcon variant='subtle' aria-label='Placeholders'>
                      <IconPercentage size='1rem' />
                    </ActionIcon>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <Stack gap={0}>
                      <Button onClick={() => addPlaceholderToContent('%COMPANY%')} variant='subtle'>Organization Name</Button>
                      <Button onClick={() => addPlaceholderToContent('%JOB%')} variant='subtle'>Job Name</Button>
                      <Button onClick={() => addPlaceholderToContent('%LOCATION%')} variant='subtle'>Cycle Location</Button>
                    </Stack>
                  </Popover.Dropdown>
                </Popover>
                  )}
            />
          </Box>
        </Grid.Col>
        {isInterview
          ? null
          : (
          <Grid.Col span={COL_SPANS.points}>
            <NumberInput
              aria-label='Answer score input'
              fz='1rem'
              styles={answerEditorInputStyles}
              value={answer.score}
              decimalScale={MAX_SCORE_PRECISION}
              placeholder='points'
              step={1}
              onChange={(value) => dispatch({ type: QuestionStateUpdate.SetAnswerScore, answerId: answer.id, questionId: answer.questionId, newScore: (value || 0) }) }
            />
          </Grid.Col>
            )}
        {(isInterview || isChooseAll || isPresetRatingQuestion)
          ? null
          : (
            <>
              <Grid.Col span={COL_SPANS.correct}>
                <Center>
                  <Checkbox
                    aria-label='Answer is correct checkbox'
                    checked={!!answer.correct}
                    disabled={isFillInTheBlank}
                    onChange={(event) => dispatch({ type: QuestionStateUpdate.SetCorrectAnswer, answerId: answer.id, questionId: answer.questionId, newScore: (event.currentTarget.checked ? 1 : 0) })}
                  />
                </Center>
              </Grid.Col>
              <Grid.Col span={COL_SPANS.knockout} >
                <KnockoutEditor answer={answer} updateAnswerProps={updateAnswerProps} />
              </Grid.Col>
            </>
            )}
          <TagColumn answer={answer} dispatch={dispatch} />
      </Grid>
    </Paper>
  )
})

const KnockoutEditor = memo(function KnockoutEditor ({ answer, updateAnswerProps }) {
  const knockoutColor = getKnockoutColor(answer.knockoutValue)

  const hasKnockoutValue = !!answer.knockoutValue || answer.knockoutValue === 0
  const [sliderValue, setSliderValue] = useState(hasKnockoutValue ? (answer.knockoutValue * -1) : null)
  const [showSlider, { toggle: toggleSlider, close: closeSlider }] = useDisclosure(false)
  const [showSliderControl, { open: openControl, close: closeControl }] = useDisclosure(false, { onClose: () => closeSlider() })
  const displaySliderDelayTimer = useRef(null)

  const updateKnockoutValue = useCallback((value) => {
    setSliderValue(value)
    const submitValue = value ? value * -1 : null
    updateAnswerProps({ knockoutValue: submitValue })

    if (!showSliderControl && value > 0) {
      openControl()
    }
  }, [updateAnswerProps, openControl, showSliderControl])

  const handleKnockoutCheckboxChange = useCallback((checked) => {
    if (checked) {
      openControl()
    } else {
      closeControl()
    }
    updateKnockoutValue(checked)
  }, [updateKnockoutValue, openControl, closeControl])

  const handleOpenControl = useCallback(() => {
    window.clearTimeout(displaySliderDelayTimer.current)
    openControl()
  }, [openControl])

  const handleMouseEnter = useCallback(() => {
    if (answer.knockoutValue) {
      handleOpenControl()
    }
  }, [handleOpenControl, answer.knockoutValue])

  const handleMouseLeave = useCallback(() => {
    window.clearTimeout(displaySliderDelayTimer.current)
    displaySliderDelayTimer.current = window.setTimeout(() => {
      if (!showSlider) {
        closeControl()
      }
    }, KNOCKOUT_CONTROL_CLOSE_DELAY)
  }, [closeControl, showSlider])

  return (
    <Box pos='relative' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Center>
        <Checkbox
          aria-label='Answer is knockout checkbox'
          icon={IconX}
          classNames={checkboxStyles}
          disabled={!!answer.correct}
          color={knockoutColor ?? 'gray'}
          checked={!!sliderValue}
          onChange={(event) => handleKnockoutCheckboxChange(event.currentTarget.checked ? 1 : null)}
        />
        <Transition mounted={showSliderControl} transition={slideTransition}>
          {(transitionStyles) => (
            <ActionIcon
            onClick={toggleSlider}
            variant={showSlider ? 'active-subtle' : 'subtle'}
            pos='absolute'
            style={{ ...transitionStyles, zIndex: 1 }}
            >
              <IconSettings />
            </ActionIcon>
          )}
        </Transition>
      </Center>
      <Collapse in={showSlider && showSliderControl} w='100%'>
        <Space h='xs' />
        <Slider
          w='100%'
          color={knockoutColor}
          label={value => value * -1}
          value={sliderValue ?? 1}
          min={0}
          max={1}
          step={0.1}
          onChange={setSliderValue}
          onChangeEnd={updateKnockoutValue}
          />
      </Collapse>
    </Box>
  )
})

export default AnswerEditor
