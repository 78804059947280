import { createSelector, createSlice } from '@reduxjs/toolkit'

export const tableSelectedRowsSlice = createSlice({
  name: 'selectedRows',
  initialState: {},
  reducers: {
    selectOne: (state, action) => {
      const { id, row, namespace = '' } = action.payload
      console.assert(namespace !== '', 'Selected table row without providing a namespace')
      console.groupCollapsed(`Selected Row From Table: ${namespace}`)
      console.table([row])
      console.groupEnd()

      if (!Object.prototype.hasOwnProperty.call(state, namespace)) {
        state[namespace] = {};
      }

      state[namespace][id] = row
    },
    unselectOne: (state, action) => {
      const { id, namespace = '' } = action.payload
      console.assert(namespace !== '', 'Unselected table row without providing a namespace')

      delete state[namespace][id]
    },
    selectBulk: (state, action) => {
      const { rows, namespace = '' } = action.payload
      console.assert(namespace !== '', 'Bulk selected table rows without providing a namespace')
      console.groupCollapsed(`Bulk Selected Rows From Table: ${namespace}`)
      console.table(rows)
      console.groupEnd()

      if (!Object.prototype.hasOwnProperty.call(state, namespace)) {
        state[namespace] = {};
      }

      rows.forEach(row => {
        state[namespace][row.id.toString()] = row
      })
    },
    unselectBulk: (state, action) => {
      const { keys, namespace = '' } = action.payload
      console.assert(namespace !== '', 'Bulk unselected table rows without providing a namespace')

      keys.forEach((key) => delete state[namespace][key])
    },
    unselectAll: (state, action) => {
      const { namespace = '' } = action.payload
      console.assert(namespace !== '', 'Unselected all table rows without providing a namespace')

      state[namespace] = {}
    }
  }
})

const selectedRows = (state) => state.tableSelectedRows

const namespace = (state, namespace) => namespace

export const selectAllSelectedKeys = createSelector(
  [selectedRows, namespace],
  (rows, namespace) => Object.prototype.hasOwnProperty.call(rows, namespace) ? Object.keys(rows[namespace]) : []
)

export const selectAllSelectedValues = createSelector(
  [selectedRows, namespace],
  (rows, namespace) => Object.prototype.hasOwnProperty.call(rows, namespace) ? Object.values(rows[namespace]) : []
)

export const selectHasAnyRowsSelected = createSelector(
  [selectedRows, namespace],
  (rows, namespace) => Object.prototype.hasOwnProperty.call(rows, namespace) ? !!(Object.keys(rows[namespace]).length) : false
)

export const selectIsRowSelected = createSelector(
  [selectedRows, namespace, (state, rowId) => rowId],
  (rows, namespace, rowId) => Object.prototype.hasOwnProperty.call(rows, namespace) ? !!rows[namespace][rowId] : false
)

export const { selectOne, unselectOne, selectBulk, unselectBulk, unselectAll } = tableSelectedRowsSlice.actions

export default tableSelectedRowsSlice.reducer
