import React from 'react'
import { Select } from '@mantine/core'
import { IconBuildings } from '@tabler/icons-react'

/**
 * This component renders an organization select.
 * @typedef {object} OrganizationSelectProps The props of the component.
 * @property {string} data The select options.
 * @property {string} [placeholder] The placeholder for the select. Defaults to "Organization".
 * @property {string} name The name to reference the select on a form.
 * @property {boolean} value The value of the select.
 * @property {function} onChange The onChange callback.
 * @property {function} onFocus The onFocus callback.
 * @property {function} onBlur The onBlur callback.
 * @property {React.Node} error The error component.
 * @param {OrganizationSelectProps} props
 * @returns {React.ReactElement}
 */
function OrganizationsSelect ({
  data,
  placeholder = 'Organization',
  name,
  value,
  onChange,
  onFocus,
  onBlur,
  error
}) {
  return (
    <Select
      placeholder={placeholder}
      leftSection={<IconBuildings size='1.5rem' />}
      searchable
      clearable
      data={data}
      name={name}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      error={error}
    />
  )
}

export default OrganizationsSelect
