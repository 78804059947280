import { useParams } from 'react-router-dom';
import { CyclePageTitle } from '../nav/CyclePageTitle';
import { Space } from '@mantine/core';
import { CyclePassTable } from './CyclePassTable';
import React from 'react';
import { DiagnosticsNavButtonTabs } from '../nav/DiagnosticsNavButton';

export function CyclePassesList () {
  const { cycleId } = useParams()

  return (
    <>
      <CyclePageTitle cycleId={cycleId} pageName='Passports' active={DiagnosticsNavButtonTabs.Passports} />
      <Space h='md' />
      <CyclePassTable cycleId={cycleId} />
    </>
  )
}
