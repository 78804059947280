import {
  IconAsterisk,
  IconAward,
  IconAssembly,
  IconBan,
  IconBookmark,
  IconEmpathize,
  IconExclamationMark,
  IconFaceIdError,
  IconFaceId,
  IconFileArrowLeft,
  IconFileArrowRight,
  IconFileBroken,
  IconFileCertificate,
  IconFileCheck,
  IconFileDislike,
  IconFileLike,
  IconFileShredder,
  IconFlagOff,
  IconFlag,
  IconFlameOff,
  IconFlame,
  IconFlare,
  IconHeartMinus,
  IconHeartOff,
  IconHeartPlus,
  IconHeart,
  IconHeartbeat,
  IconHeartsOff,
  IconHearts,
  IconHomeCheck,
  IconHomeCancel,
  IconPlane,
  IconPlaneOff,
  IconTag
} from '@tabler/icons-react'; import { TagIcon } from '../../js/generated/enums/TagIcon'
import React from 'react'

function tagIconFromEnum (tag, props) {
  switch (tag) {
    case TagIcon.Asterisk:
      return <IconAsterisk {...props} />
    case TagIcon.Award:
      return <IconAward {...props} />
    case TagIcon.Assembly:
      return <IconAssembly {...props} />
    case TagIcon.Ban:
      return <IconBan {...props} />
    case TagIcon.Bookmark:
      return <IconBookmark {...props} />
    case TagIcon.Empathize:
      return <IconEmpathize {...props} />
    case TagIcon.ExclamationMark:
      return <IconExclamationMark {...props} />
    case TagIcon.FaceIdError:
      return <IconFaceIdError {...props} />
    case TagIcon.FaceId:
      return <IconFaceId {...props} />
    case TagIcon.FileArrowLeft:
      return <IconFileArrowLeft {...props} />
    case TagIcon.FileArrowRight:
      return <IconFileArrowRight {...props} />
    case TagIcon.FileBroken:
      return <IconFileBroken {...props} />
    case TagIcon.FileCertificate:
      return <IconFileCertificate {...props} />
    case TagIcon.FileCheck:
      return <IconFileCheck {...props} />
    case TagIcon.FileDislike:
      return <IconFileDislike {...props} />
    case TagIcon.FileLike:
      return <IconFileLike {...props} />
    case TagIcon.FileShredder:
      return <IconFileShredder {...props} />
    case TagIcon.FlagOff:
      return <IconFlagOff {...props} />
    case TagIcon.Flag:
      return <IconFlag {...props} />
    case TagIcon.FlameOff:
      return <IconFlameOff {...props} />
    case TagIcon.Flame:
      return <IconFlame {...props} />
    case TagIcon.Flare:
      return <IconFlare {...props} />
    case TagIcon.HeartMinus:
      return <IconHeartMinus {...props} />
    case TagIcon.HeartOff:
      return <IconHeartOff {...props} />
    case TagIcon.HeartPlus:
      return <IconHeartPlus {...props} />
    case TagIcon.Heart:
      return <IconHeart {...props} />
    case TagIcon.Heartbeat:
      return <IconHeartbeat {...props} />
    case TagIcon.HeartsOff:
      return <IconHeartsOff {...props} />
    case TagIcon.Hearts:
      return <IconHearts {...props} />
    case TagIcon.HomeCheck:
      return <IconHomeCheck {...props} />
    case TagIcon.HomeCancel:
      return <IconHomeCancel {...props} />
    case TagIcon.Plane:
      return <IconPlane {...props} />
    case TagIcon.PlaneOff:
      return <IconPlaneOff {...props} />
    default:
      return <IconTag {...props} />
  }
}

function getAllTags () {
  return Object.values(TagIcon)
}

export { tagIconFromEnum, getAllTags }
