import { paramsToQuery } from '../../../react/util/queries'
import { ApiAbstractIds, ApiMethods, apiSlice, ApiTags } from '../../../react/api'

export const organizationsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizations: builder.query({
      query: (params = { limit: 0 }) => `clients${paramsToQuery(params)}`,
      providesTags: (result) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.Organizations, id: id })),
              { type: ApiTags.Organizations, id: ApiAbstractIds.PartialList }
            ]
          : [{ type: ApiTags.Organizations, id: ApiAbstractIds.PartialList }]
    }),
    getOrganization: builder.query({
      query: id => `clients/${id}`,
      providesTags: (result, error, id) => [{ type: ApiTags.Organizations, id: id }]
    }),
    createOrganization: builder.mutation({
      query: data => ({
        url: 'clients',
        method: ApiMethods.Post,
        body: data
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ApiTags.Organizations, id: id }]
    }),
    editOrganization: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `clients/${id}`,
        method: ApiMethods.Put,
        body: body
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ApiTags.Organizations, id: id }]
    }),
    deleteOrganization: builder.mutation({
      query: ({ id }) => ({
        url: `clients/${id}`,
        method: ApiMethods.Delete
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ApiTags.Organizations, id: id }]
    })
  })
})

export const {
  useGetOrganizationsQuery,
  useGetOrganizationQuery,
  useCreateOrganizationMutation,
  useEditOrganizationMutation,
  useDeleteOrganizationMutation
} = organizationsApi
