import React, { memo, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectHasAnyRowsSelected } from '../../../core/ReactTable/tableSelectedRowsSlice';
import { ActionIcon, Affix, Center, Tooltip } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import BoostBulkInviteModal from './BoostBulkInviteModal';
import { IconMailShare } from '@tabler/icons-react';
import BulkActionsController, {
  DEFAULT_BULK_ACTIONS_AFFIX_TOP
} from '../../../core/ReactTable/BulkActionsController';
import { NamespaceContext } from '../../../core/ReactTable/TableContexts';
import * as styles from '../../../layout/animations/styles.module.css';

const AFFIX_LEFT = 10;

export const BoostSelectedHandler = memo(function BoostSelectedHandler () {
  const { cycleId: cycle } = useParams()
  const namespace = useContext(NamespaceContext)
  const [opened, { open, close }] = useDisclosure(false)
  const [dragging, setDragging] = useState(false)
  const anySelected = useSelector(state => selectHasAnyRowsSelected(state, namespace))

  return (
    <>
      {!!anySelected &&
        <Affix className={styles.bulkAffixContainer} position={{ top: `${DEFAULT_BULK_ACTIONS_AFFIX_TOP}vh`, left: `${AFFIX_LEFT}vw` }}>
          <BulkActionsController affixedLeft={AFFIX_LEFT} setDragging={setDragging}>
            <Center>
              <Tooltip label='Bulk Invite'>
                <ActionIcon
                  variant="outline"
                  aria-label="Bulk Invite"
                  size="xl"
                  disabled={!anySelected || dragging}
                  onClick={open}
                  color='blue'
                >
                  <IconMailShare/>
                </ActionIcon>
              </Tooltip>
            </Center>
          </BulkActionsController>
        </Affix>
      }
      <BoostBulkInviteModal cycle={cycle} opened={opened} close={close} />
    </>
  )
})
