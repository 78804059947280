/* eslint react/prop-types: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useCallback, useContext, useMemo } from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { Center, Checkbox, Group, Select, SimpleGrid, Table } from '@mantine/core'
import { ConfigModal } from '../cycle/invites/sent/ConfigModal';
import { ContactContext, ContactDispatchContext } from './ContactContexts';
import { ContactStateUpdate } from './ContactState';

dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)

export default function Program ({ applicants, showPhase, showBattery }) {
  const state = useContext(ContactContext)
  const dispatch = useContext(ContactDispatchContext)

  console.debug('Program updating', { state, dispatch })

  const batteryId = state.batteryId

  const updateInviteConfig = useCallback((config) => {
    console.debug('Updating invite config', { config })
    dispatch({ type: ContactStateUpdate.UpdatePhaseInviteTemplate, inviteConfigTemplate: config })
  }, [dispatch])

  const updateBattery = useCallback((value) => {
    console.debug('Updating battery', { value })
    dispatch({ type: ContactStateUpdate.UpdateSelectedBattery, batteryId: value ? parseInt(value) : null })
  }, [dispatch])

  const cyclesOptions = useMemo(() => {
    return [...state.batteries.values()].map(cycle => ({ value: cycle.id.toString(), label: cycle.name }))
  }, [state.batteries])

  console.debug(
    'Program component updating. Implement new feature using inviteConfigTemplate rather than form ap_cycle.',
    { cyclesOptions, applicants }
  )

  const batteryRetakeMap = batteryId ? state.retakes.get(batteryId) : null
  const retakes = useMemo(() => {
    if (!batteryRetakeMap?.size) {
      return []
    }
    return [...batteryRetakeMap.values()].filter(retake => state.filteredApplicants.get(retake.submission.id))
  }, [batteryRetakeMap, state.filteredApplicants])

  return (
    <SimpleGrid cols={1}>
      <Group>
        <ConfigModal
          updateInviteConfig={updateInviteConfig}
          inviteConfigTemplate={state.inviteConfigTemplate}
          offerOption={!state.batteryId && showPhase}
          applicants={applicants}
        />
        {!state.inviteConfigTemplate && !!showBattery && (
          <Select
            label='Battery'
            placeholder='Select a battery to invite to...'
            data={cyclesOptions}
            value={batteryId ? batteryId.toString() : null}
            onChange={updateBattery}
            nothingFoundMessage='No batteries available for cycle - cannot use current template'
          />
        )}
      </Group>
      {
        !!retakes.length &&
        <Table verticalSpacing='xs'>
          <caption>The following applicants have already taken the selected battery of assessments</caption>
          <thead>
          <tr>
            <th>Applicant</th>
            <th>Submitted At</th>
            <th>Assessment Status</th>
            <th>Retake</th>
          </tr>
          </thead>
          <tbody>
          {
            retakes.map((retake, index) => (
              <tr key={`last-submission-${retake.submission.id}`}>
                <td>{retake.submission.email}</td>
                <td>{retake.submission.submitted_at ? dayjs(retake.submission.submitted_at).format('MMMM Do YYYY, h:mm a') : 'Never completed'}</td>
                <td>{retake.submission.status}</td>
                <td><Center>
                  <Checkbox
                    checked={retake.retake}
                    onChange={() => dispatch({ type: ContactStateUpdate.UpdateBatteryRetake, batteryId: batteryId, id: retake.submission.id, retake: !retake.retake })}
                  />
                </Center></td>
              </tr>
            ))
          }
          </tbody>
        </Table>
      }
      <PhaseInviteStatuses
        phaseRetakes={state.phaseRetakes}
        phase={parseInt(state.inviteConfigTemplate?.phase ?? '-1')}
        filteredApplicants={state.filteredApplicants}
      />
    </SimpleGrid>
  )
}

function PhaseInviteStatuses ({ phaseRetakes, phase, filteredApplicants }) {
  const dispatch = useContext(ContactDispatchContext)

  const phaseInviteStatuses = phaseRetakes.get(phase)

  const retakes = useMemo(() => {
    if (!phaseInviteStatuses?.size) {
      return []
    }
    return [...phaseInviteStatuses.values()].filter(retake => (retake.started || retake.locked) && filteredApplicants.get(retake.id))
  }, [phaseInviteStatuses, filteredApplicants])

  console.debug(
    'PhaseInviteStatuses component updating',
    { phase, retakes }
  )

  return (
    <>
      {
        !!retakes.length &&
        <Table verticalSpacing='xs'>
          <caption>These applicants have already started at least one of the assessments in this phase, or have previously been manually locked.</caption>
          <thead>
          <tr>
            <th>Applicant</th>
            <th>Last Activity for Phase</th>
            <th>Assessment Status</th>
            <th>Most Recent Phase</th>
            <th>Active Assessment</th>
            <th>Locked</th>
            <th>Unlock</th>
            <th>Retake</th>
          </tr>
          </thead>
          <tbody>
          {
            retakes.map(retake => (
                <tr key={`last-submission-${retake.id}`}>
                  <td>{retake.identifier}</td>
                  <td>{retake.submittedAt ? dayjs(retake.submittedAt).format('MMMM Do YYYY, h:mm a') : 'Never completed'}</td>
                  <td>{retake.started ? (retake.startFirstStageInPhase ? 'Completed' : 'Resume') : 'New'}</td>
                  <td>{retake.lastPhaseId ?? '-'}</td>
                  <td>{(retake.locked && !retake.unlock) ? 'None (not unlocked)' : (retake.retake ? retake.firstAssessment.name : (retake.continueOnAssessment ? retake.continueOnAssessment.name : (retake.started ? 'None (not retaking)' : retake.firstAssessment.name)))}</td>
                  <td>{retake.locked ? 'Yes' : 'No'}</td>
                  <td>
                    {retake.locked
                      ? (
                        <Center>
                          <Checkbox
                            checked={retake.unlock}
                            onChange={() => dispatch({ type: ContactStateUpdate.UpdatePhaseRetake, phaseId: phase, id: retake.id, unlock: !retake.unlock })}
                            aria-label='Unlock checkbox'
                          />
                        </Center>
                        )
                      : <Center>-</Center>}
                  </td>
                  <td>
                    {(retake.retakeRequired !== false)
                      ? (
                        <Center>
                          <Checkbox
                            checked={retake.retake}
                            onChange={() => dispatch({ type: ContactStateUpdate.UpdatePhaseRetake, phaseId: phase, id: retake.id, retake: !retake.retake })}
                            aria-label='Retake checkbox'
                          />
                        </Center>
                        )
                      : <Center>-</Center>}
                  </td>
                </tr>
            ))
          }
          </tbody>
        </Table>
      }
    </>
  )
}
