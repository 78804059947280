import React, { useState } from 'react'
import { ActionIcon, Collapse, NavLink, Stack, Text, Tooltip } from '@mantine/core'
import {
  IconBriefcase,
  IconBuildings,
  IconChevronRight,
  IconMapPin,
  IconPackages,
  IconReportAnalytics,
  IconUsersGroup,
  IconVocabulary
} from '@tabler/icons-react'
import * as classes from './Menu.module.scss'

const ICON_STYLES = { width: '20px', height: '20px' }
const ICON_STROKE = 1.5

const ICON_MAPPING = {
  users: <IconUsersGroup style={ICON_STYLES} stroke={ICON_STROKE} />,
  cubes: <IconPackages style={ICON_STYLES} stroke={ICON_STROKE} />,
  briefcase: <IconBriefcase style={ICON_STYLES} stroke={ICON_STROKE} />,
  book: <IconVocabulary style={ICON_STYLES} stroke={ICON_STROKE} />,
  analytics: <IconReportAnalytics style={ICON_STYLES} stroke={ICON_STROKE} />,
  organization: <IconBuildings style={ICON_STYLES} stroke={ICON_STROKE} />,
  location: <IconMapPin style={ICON_STYLES} stroke={ICON_STROKE} />
}

/**
 * @typedef {object} MenuItemType
 * @property {string} id The unique identifier of the menu item.
 * @property {string} label The label that the menu item should display.
 * @property {?string} link Optional URI to redirect to a page when the menu item is clicked on.
 * @property {?string} icon Optional icon to display next to the menu item.
 * @property {MenuItemType[]} items A list of children of this menu item.
 */

/**
 * @typedef {object} MenuType
 * @property {MenuItemType[]} items The root items of the menu.
 * @property {string} active The menu item id of the menu item that should be highlighted.
 */

/**
 * This component renders the root menu.
 * @typedef {object} MenuProps
 * @property {MenuType} menu The root menu.
 * @param MenuProps props
 * @returns {JSX.Element}
 */
function Menu ({ menu }) {
  const [_currentOpen, setCurrentOpen] = useState(menu.active)

  return (
    <Stack gap='xs' className={classes.root}>
      {
        menu.items.map(menuItem => (
          <MenuItem
            key={menuItem.id}
            highlighted={menu.active}
            parentMenuItem={null}
            menuItem={menuItem}
            setCurrentOpen={setCurrentOpen}
            currentOpen={_currentOpen}
          />
        ))
      }
    </Stack>
  )
}

/**
 * Renders a menu item and its children.
 * @typedef {object} MenuItemProps
 * @property {string} currentOpen The menu item id that is currently opened.
 * @property {function} setCurrentOpen Updates the menu item id that should be currently opened.
 * @property {string} highlighted The menu item id that was selected as highlighted in the backend.
 * @property {?MenuItemType} parentMenuItem The parent of this menu item.
 * @property {MenuItemType} menuItem The menu item to render.
 * @param MenuItemProps props
 * @returns {JSX.Element}
 */
function MenuItem ({
  currentOpen,
  setCurrentOpen,
  highlighted,
  parentMenuItem,
  menuItem
}) {
  const isHighlighted = isItemOnTree(highlighted, menuItem)
  const isOpened = isItemOnTree(currentOpen, menuItem)
  const hasLink = menuItem.link !== undefined
  const hasParent = parentMenuItem !== null
  const withChildren = menuItem.items.length > 0

  let variant = 'subtle'

  if (isHighlighted) {
    variant = parentMenuItem === null ? 'filled' : 'light'
  }

  const handleCollapse = ev => {
    setCurrentOpen(() => {
      if (isOpened) {
        if (parentMenuItem !== null) {
          return parentMenuItem.id
        } else {
          return ''
        }
      }

      return menuItem.id
    })
    ev?.preventDefault()
  }

  return (
    <>
      <NavLink
        classNames={{
          root: classes.item
        }}
        href={hasLink ? menuItem.link : null}
        key={menuItem.id}
        label={
          <Tooltip label={menuItem.label} position='right-start' maw='35vw' offset={20} multiline={true} disabled={menuItem.label.length <= 48}>
            <Text size={hasParent ? 'sm' : 'md'} lineClamp={2}>{menuItem.label}</Text>
          </Tooltip>
        }
        description={menuItem.description}
        leftSection={menuItem.icon !== undefined ? ICON_MAPPING[menuItem.icon] : null}
        variant={variant}
        color={isHighlighted ? 'blue.6' : 'white'}
        {...!hasLink && { onClick: handleCollapse }}
        rightSection={
          withChildren &&
          <ActionIcon
            variant='subtle'
            size='md'
            color='white'
            {...hasLink && { onClick: handleCollapse }}
            aria-label={isOpened ? `Collapse ${menuItem.label}` : `Expand ${menuItem.label}` }
          >
            <IconChevronRight
              size='1rem'
              {...isOpened && { style: { transform: 'rotate(90deg)' } }}
            />
          </ActionIcon>
        }
      />
      {
        withChildren &&
        <Collapse in={isOpened}>
          <Stack gap='xs' ml='10px' pl='5px' style={{ borderLeft: '1px solid #39485b' }}>
            {
              menuItem.items.map(subMenuItem => (
                <MenuItem
                  key={subMenuItem.id}
                  highlighted={highlighted}
                  menuItem={subMenuItem}
                  parentMenuItem={menuItem}
                  setCurrentOpen={setCurrentOpen}
                  currentOpen={currentOpen}
                />
              ))
            }
          </Stack>
        </Collapse>
      }
    </>
  )
}

/**
 * This function checks if a menu item id can be found in a menu item tree. It checks the menu item and its
 * children recursively.
 * @param {string} id The menu item id to look for.
 * @param {MenuItemType} menuItem The menu item to start from.
 * @returns {boolean} Returns true if the id is found on the tree, false otherwise.
 */
const isItemOnTree = (id, menuItem) => {
  if (menuItem.id === id) {
    return true
  }

  for (let i = 0; i < menuItem.items.length; i++) {
    if (isItemOnTree(id, menuItem.items[i])) {
      return true
    }
  }

  return false
}

export default Menu
