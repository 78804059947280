/* eslint react/prop-types: 0 */
import React, { useMemo } from 'react'
import { Text } from '@mantine/core'
import { IconMail } from '@tabler/icons-react'
import { useParams } from 'react-router-dom';
import { Header, NumberCell, RezviewCell } from '../../../core/ReactTable/ReactTable';
import SelectableColumn from '../../../core/ReactTable/SelectableColumn';
import { FormComponentType } from '../../../core/ReactTable/FormFilters';
import { useJobsQuery } from '../../jobs/JobHooks';
import { isGranted } from '../../../../util/account/account.util';
import { useSelector } from 'react-redux';
import { selectAllParamsQueryData } from '../../../core/ReactTable/paramsSlice';
import { useGetBoostInvitableApplicantsQuery } from '../../../../redux/query/hire/boostApi.slice';
import { apiSlice } from '../../../api';

export function useBoostQuery (namespace, id, skip = false) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data, isFetching, isLoading, error, requestId, fulfilledTimeStamp } = useGetBoostInvitableApplicantsQuery({ ...queryParams, id }, { skip })
  return { collection: data ?? null, querying: isFetching, loading: isLoading, error: error ?? null, requestId: fulfilledTimeStamp ? requestId : null }
}

export function useLazyBoostQuery (namespace, id) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data: collection, isFetching: querying, error } = apiSlice.endpoints.getBoostInvitableApplicants.useQueryState({ ...queryParams, id })
  return [collection ?? null, querying, error ?? null]
}

const BOOST_DEFAULT_FILTERS = {}
const BOOST_DEFAULT_HIDDEN_COLUMNS = []

export function useBoostTable (account, job) {
  const formFilters = useBoostFormFilters(job)

  return {
    defaultHiddenColumns: BOOST_DEFAULT_HIDDEN_COLUMNS,
    defaultFilters: BOOST_DEFAULT_FILTERS,
    columns: useBoostColumns(account),
    actions: useBoostActions(),
    formFilters: formFilters,
    searchable: false,
    alwaysShowHeaderGroups: false,
    showHeaderGroupsIfExpanded: false
  }
}
function getBoostSelectionColumn (isAdmin = false) {
  return isAdmin
    ? [SelectableColumn({
        selectAriaLabel: 'Select applicant',
        selectAllAriaLabel: 'Select all applicants',
        id: 'checkbox'
      })]
    : []
}

function getBoostNameColumn (isAdmin = false) {
  return [{
    Header: <Header>Name</Header>,
    id: 'name',
    accessor: '0.name',
    Cell: ({ cell: { value, row } }) => <RezviewCell name={value} applicantId={row.original.id} isAdmin={isAdmin} isRedacted/>,
    sortable: false,
    filterable: false,
    hideable: false
  }]
}

const BOOST_ADMIN_COLUMNS = [
  {
    Header: <Header>Organization</Header>,
    id: 'organization',
    accessor: 'clientName',
    Cell: ({ cell: { value } }) => <Text>{value}</Text>,
    sortable: false,
    filterable: false,
    hideable: false
  },
  {
    Header: <Header>Specific Job</Header>,
    id: 'specific_job',
    accessor: 'specificJob',
    Cell: ({ cell: { value } }) => <Text>{value}</Text>,
    sortable: false,
    filterable: false,
    hideable: false
  }
]

function getBoostAdminColumns (isAdmin = false) {
  return isAdmin
    ? BOOST_ADMIN_COLUMNS
    : []
}

const BOOST_COLUMNS = [
  {
    Header: <Header>General Job</Header>,
    id: 'general_job',
    accessor: 'generalJob',
    Cell: ({ cell: { value } }) => <Text>{value}</Text>,
    sortable: false,
    filterable: false,
    hideable: false
  },
  {
    Header: <Header>Address</Header>,
    id: 'address',
    accessor: '0.contact_information',
    Cell: ({ cell: { value } }) => <Text>{value.city}, {value.state}</Text>,
    sortable: false,
    filterable: false,
    hideable: false
  },
  {
    Header: <Header centered>Baseline</Header>,
    id: 'baseline',
    accessor: 'baseline',
    Cell: ({ cell: { value } }) => <NumberCell centered><Text>{Math.floor(value)}</Text></NumberCell>,
    sortable: false,
    filterable: false,
    hideable: false
  }
]

export function useBoostColumns (account) {
  const isAdmin = account ? isGranted(account, 'ROLE_ADMIN') : false

  return useMemo(() => [
    ...getBoostSelectionColumn(isAdmin),
    ...getBoostNameColumn(isAdmin),
    ...getBoostAdminColumns(isAdmin),
    ...BOOST_COLUMNS
  ], [isAdmin])
}

export function useBoostActions () {
  const { cycleId } = useParams()

  return useMemo(
    () => [
      {
        label: 'Invite',
        leftSection: <IconMail />,
        href: (id) => `/cycles/${cycleId}/applicants/${id}/invite`
      }
    ], [cycleId]
  )
}

export function useBoostFormFilters (job) {
  const [jobs, isQuerying] = useJobsQuery({ archived: 0, limit: 0 })

  const formattedJobs = useMemo(() => {
    if (!isQuerying) {
      return jobs.items
        .map(job => job.name)
    }
  }, [jobs, isQuerying])

  return useMemo(() => [
    {
      type: FormComponentType.MultiSelect,
      data: formattedJobs,
      label: 'Jobs',
      id: 'jobs',
      initialValue: [job.name]
    },
    {
      type: FormComponentType.Text,
      label: 'Address',
      id: 'address',
      initialValue: ''
    }
  ], [formattedJobs, job])
}
