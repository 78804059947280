import React, { useMemo } from 'react'
import { SimpleGrid } from '@mantine/core'
import { BoostTableLoader } from './BoostTable';
import { useParams } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';
import { useGetBoostInvitableLimitQuery } from '../../../../redux/query/hire/boostApi.slice';

const namespace = 'boost-invites'

export default function BoostCycle () {
  const { cycleId } = useParams()
  const limitInformation = useGetBoostInvitableLimitQuery({ id: cycleId })

  useMemo(() => {
    if (limitInformation?.reached) {
      showNotification({
        title: 'Limit Reached',
        message: 'The invite limit for this cycle has been reached',
        color: 'yellow',
        autoClose: 3000
      })
    }
  }, [limitInformation])

  return (
    <SimpleGrid>
      <BoostTableLoader
        cycleId={cycleId}
        namespace={namespace}
      />
    </SimpleGrid>
  )
}
