/**
 * This function allows to compare float values using an error tolerance epsilon value.
 * @param {number} a
 * @param {number} b
 * @param {number} [epsilon] The error tolerance. Default to 0.00001.
 * @returns {boolean} Whether the two floats are consider equal within the error tolerance.
 */
export function equals (a, b, epsilon = 0.00001) {
  return Math.abs(a - b) < epsilon
}
