import { useSelector } from 'react-redux'
import { selectModalValue } from './detailViewSlice'
import { useGetRezviewContentQuery } from '../../../redux/query/hire/applicantsApi.slice'
import React, { useMemo } from 'react'
import * as DOMPurify from 'dompurify'
import DetailViewApplicantModal from './DetailViewApplicantModal'
import RezviewSkeleton from './RezviewSkeleton'

export default function RezviewModal () {
  const applicantId = useSelector(state => selectModalValue(state, 'rezview'))
  const { data: rezviewContent, isFetching } = useGetRezviewContentQuery({ id: applicantId }, { skip: !applicantId })
  const sanitizedContent = useMemo(() => DOMPurify.sanitize(rezviewContent), [rezviewContent])

  return (
    <DetailViewApplicantModal modal='rezview' applicantId={applicantId} transitionProps={{ transition: 'slide-up' }} size='65rem' zIndex={201}>
      {isFetching && <RezviewSkeleton />}
      {!isFetching && sanitizedContent && (
        <div dangerouslySetInnerHTML={{ __html: sanitizedContent }}></div> // Todo [long term/flags] replace with react component
      )}
    </DetailViewApplicantModal>
  )
}
