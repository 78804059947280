/* eslint react/prop-types: 0 */
import {
  Box,
  Burger,
  Button,
  Checkbox,
  CloseButton,
  Container,
  Grid,
  Group,
  LoadingOverlay,
  MultiSelect,
  Pagination,
  Paper,
  Select,
  Space,
  Stack,
  Switch,
  Text,
  TextInput
} from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { IconChevronDown } from '@tabler/icons-react';
import axios from 'axios';
import React, { useCallback, useDeferredValue, useEffect, useMemo, useReducer, useState, useTransition } from 'react';
import { getQuestions } from '../../../../js/api/question_repository';
import { QuestionType } from '../../../../js/generated/enums/QuestionType';
import { useAssessmentCompetencies, useAssessments } from '../AssessmentHooks';
import { BaseModal } from './BaseModal';
import {
  BatchFilteredAction,
  ImportQuestionsUpdate,
  createInitialImportQuestionsState,
  importQuestionsReducer
} from './ImportQuestionsState';
import { QuestionTitleAndCompetencies } from './QuestionsList';
import { QuestionStateUpdate, formatQuestionsFromJson } from './QuestionsState';

export function ImportQuestionsModal ({ showing, setShowing, globalDispatch }) {
  const [state, dispatch] = useReducer(
    importQuestionsReducer,
    null,
    createInitialImportQuestionsState
  )
  const competencies = useAssessmentCompetencies()

  useEffect(() => {
    const queryId = state.lastQueryId
    const cancelToken = axios.CancelToken
    const cancelSource = cancelToken.source()
    const params = makeQuestionsQueryParams(
      state.limit, state.page, state.orderBy, state.orderDirection, state.assessments, state.competencies, state.types, state.search
    )
    console.debug('Import Questions Query ID starting.', queryId, params)
    getQuestions(params, cancelSource.token)
      .then(collection => {
        console.debug('Import Questions Query ID got response.', queryId, collection)
        if (collection) {
          dispatch({ type: ImportQuestionsUpdate.SetQuestions, response: collection, queryId: queryId })
        } else {
          console.debug('Response was undefined - skipping update for Query ID.', queryId, params)
        }
      })
      .catch(error => {
        console.debug('Import Questions Query ID got error.', queryId, error)
        dispatch({ type: ImportQuestionsUpdate.SetQuestions, response: { total: 0, items: [] }, queryId: queryId })
      })
    return () => {
      console.debug('Import Questions Query Effect canceling for Query ID on unmount.', queryId)
      cancelSource.cancel()
    }
  }, [state.lastQueryId, state.limit, state.page, state.orderBy, state.orderDirection, state.assessments, state.competencies, state.types, state.search, dispatch])

  const questionTypeOptions = useMemo(() => {
    return Object.values(QuestionType)
  }, [])

  useEffect(() => {
    if (state.selecting) {
      console.info('Performing batch filtered action.', state.selecting)
      const cancelToken = axios.CancelToken
      const cancelSource = cancelToken.source()

      const batchQuestions = new Map()

      const completeQuery = () => {
        if (state.selecting === BatchFilteredAction.Select) {
          dispatch({ type: ImportQuestionsUpdate.ImportBatchFromFilter, batchImport: batchQuestions })
        } else {
          globalDispatch({ type: QuestionStateUpdate.ImportQuestions, newQuestions: batchQuestions, preservePageBreaks: state.preservePageBreaks })
          dispatch({ type: ImportQuestionsUpdate.ImportBatchFromFilter })
        }
      }

      const doPageQuery = (page, thisCancelSource) => {
        const limit = 0
        const params = makeQuestionsQueryParams(
          limit, page, state.orderBy, state.orderDirection, state.assessments, state.competencies, state.types, state.search
        )
        getQuestions(params, thisCancelSource.token)
          .then(collection => {
            if (collection) {
              console.debug('Got collection from batch query segment.', collection)
              const queryQuestions = formatQuestionsFromJson(collection.items, false)
              for (const question of queryQuestions.values()) {
                batchQuestions.set(question.id, question)
              }
              if (limit && (collection.total > (page * limit))) {
                console.debug('Still results - continuing batch filtered action.', collection.total, page, limit)
                doPageQuery(page + 1, thisCancelSource)
              } else {
                console.info('Finished querying filtered results - finishing batch filtered action.', collection.total, page, limit)
                completeQuery()
              }
            } else {
              console.error('No collection returned for batch filtered query - not finishing action.', params)
            }
          })
          .catch(err => {
            console.error('Batch filtered query error! Finishing action.', err, params)
            completeQuery()
          })
      }

      doPageQuery(1, cancelSource)
      return () => { console.debug('Cancelling remaining batch query promises due to unmount.'); cancelSource.cancel() }
    }
  }, [state.selecting, state.orderBy, state.orderDirection, state.assessments, state.competencies, state.types, state.search, globalDispatch, state.preservePageBreaks])

  const assessmentParams = useMemo(() => {
    return { page: 1, limit: 0, archived: 0 }
  }, [])
  const [assessments] = useAssessments(assessmentParams)

  const assessmentTypeOptions = useMemo(() => {
    return assessments.map(assessmentType => ({ value: assessmentType.id.toString(), label: assessmentType.name }))
  }, [assessments])

  return (
    <BaseModal
      title=''
      showing={showing}
      onClose={() => setShowing(false)}
      withCloseButton={false}
    >
      <FilteredQuestionsList
        state={state}
        dispatch={dispatch}
        globalDispatch={globalDispatch}
        competencies={competencies}
        questionTypes={questionTypeOptions}
        assessmentOptions={assessmentTypeOptions}
        onClose={() => setShowing(false)}
      />
    </BaseModal>
  )
}

function makeQuestionsQueryParams (limit, page, orderBy, orderDirection, assessments, competencies, types, search) {
  const params = {
    limit: limit,
    page: page,
    order_by: orderBy,
    order_direction: orderDirection
  }
  if (assessments.length) {
    params.assessments = assessments
  }
  if (competencies.length) {
    params.competencies = competencies.map(competency => competency.id)
  }
  if (types.length) {
    params.types = types
  }
  if (search) {
    params.search = search
  }
  return params
}

/**
 * @param {ImportQuestionsState} state
 * @param dispatch
 * @param globalDispatch
 * @param competencies
 * @param questionTypes
 * @param assessmentOptions
 * @param onClose
 */
function FilteredQuestionsList ({ state, dispatch, globalDispatch, competencies, questionTypes, assessmentOptions, onClose }) {
  const filteredSelected = useMemo(() => {
    const page = state.selectedViewFilters.page
    const limit = state.selectedViewFilters.limit
    const start = (page - 1) * limit
    const stop = page * limit
    return Array.from(state.selectedViewFilters.searchFiltered.values()).slice(start, stop)
  }, [state.selectedViewFilters.searchFiltered, state.selectedViewFilters.page, state.selectedViewFilters.limit])

  const buttonMaxWidth = '30%'
  const noQuestions = !state.questions.size
  const selectFilteredButton = useMemo(() => {
    return (<Button disabled={(noQuestions) || state.selecting} maw={buttonMaxWidth} onClick={() => dispatch({ type: ImportQuestionsUpdate.BatchFilter, batchAction: BatchFilteredAction.Select })}>Select All</Button>)
  }, [noQuestions, state.selecting, dispatch])

  const importSelectedButton = useMemo(() => {
    return (<Button disabled={(!state.selected.size) || state.selecting} maw={buttonMaxWidth} onClick={() => globalDispatch({ type: QuestionStateUpdate.ImportQuestions, newQuestions: state.selected, preservePageBreaks: state.preservePageBreaks })}>Import Selected</Button>)
  }, [state.selected, state.selecting, state.preservePageBreaks, globalDispatch])

  const noSelected = !state.selected.size
  const clearSelectedButton = useMemo(() => {
    return (<Button disabled={noSelected || state.selecting} maw={buttonMaxWidth} onClick={() => dispatch({ type: ImportQuestionsUpdate.ClearSelected })}>Clear Selected</Button>)
  }, [noSelected, state.selecting, dispatch])

  const competencyOptions = useMemo(() => {
    return competencies.map(competency => { return { value: competency.id.toString(), label: competency.name } })
  }, [competencies])

  const competencyLookup = useMemo(() => {
    const lookupMap = {}
    for (const competency of competencies) {
      lookupMap[competency.id.toString()] = competency.name
    }
    return lookupMap
  }, [competencies])

  const selectedCompetencies = useMemo(() => {
    return state.competencies.map(competency => competency.id.toString())
  }, [state.competencies])

  const questionTypeOptions = useMemo(() => {
    return questionTypes.map(questionType => ({ value: questionType, label: questionType }))
  }, [questionTypes])

  const selectedAssessmentTypes = useMemo(() => {
    return state.assessments.map(elem => elem.toString())
  }, [state.assessments])

  const selectedQuestionTypes = useMemo(() => {
    return state.types
  }, [state.types])

  return (
    <div>
      <Grid columns={24} justify='space-between' align='flex-start' gap='xl' mt={0} mb={0}>
        <Grid.Col span={6}>
          <MultiSelect
            data={assessmentOptions}
            label=""
            placeholder="Filter by Assessment"
            value={selectedAssessmentTypes}
            clearButtonProps={{ 'aria-label': 'Clear selection' }}
            onChange={(value) => dispatch({ type: ImportQuestionsUpdate.UpdateFilter, filterField: 'assessments', filterValue: value.map(elem => parseInt(elem)) })}
            clearable
            searchable
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <MultiSelect
            data={competencyOptions}
            label=""
            placeholder="Filter by Competency"
            value={selectedCompetencies}
            clearButtonProps={{ 'aria-label': 'Clear selection' }}
            onChange={(value) => dispatch({ type: ImportQuestionsUpdate.UpdateFilter, filterField: 'competencies', filterValue: value.map(item => { return { id: parseInt(item), name: (competencyLookup[item] ?? item) } }) })}
            clearable
            searchable
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <MultiSelect
            data={questionTypeOptions}
            label=""
            placeholder="Filter by Type"
            value={selectedQuestionTypes}
            clearButtonProps={{ 'aria-label': 'Clear selection' }}
            onChange={(value) => dispatch({ type: ImportQuestionsUpdate.UpdateFilter, filterField: 'types', filterValue: value })}
            clearable
            searchable
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <Space h='xs' />
          <Group position='left' wrap='nowrap'>
            <Switch
              label='Keep page breaks'
              checked={state.preservePageBreaks}
              onChange={() => dispatch({ type: ImportQuestionsUpdate.TogglePageBreaks })}
            />
          </Group>
        </Grid.Col>
        <Grid.Col offset={1} span={1}>
          <Group position='right' align='flex-end' grow>
            <CloseButton
              aria-label='Stop Importing'
              title='Finish Importing'
              iconSize={20}
              onClick={onClose}
              maw='1rem'
              mr={0}
            />
          </Group>
        </Grid.Col>
      </Grid>
      <Space h='sm' />
      <Group align='flex-start' wrap='nowrap'>
        <QuestionListHeader
          buttonOne={null}
          buttonTwo={selectFilteredButton}
          maxWidth='55%'
          loading={state.querying}
          page={state.page}
          maxPage={state.maxPage}
          limit={state.limit}
          search={state.search}
          handleLimitChange={(value) => dispatch({ type: ImportQuestionsUpdate.SetLimit, newLimit: value })}
          handlePageChange={(page) => dispatch({ type: ImportQuestionsUpdate.SetPage, newPage: page })}
          handleSearchChange={(value) => dispatch({ type: ImportQuestionsUpdate.UpdateFilter, filterField: 'search', filterValue: value })}
        >
          {Array.from(state.questions.values()).map(question => <ImportQuestionListItem key={question.id} question={question} selected={state.selected.has(question.id)} dispatch={dispatch} />)}
        </QuestionListHeader>
        <QuestionListHeader
          buttonOne={importSelectedButton}
          buttonTwo={clearSelectedButton}
          maxWidth='43%'
          loading={false}
          page={state.selectedViewFilters.page}
          maxPage={state.selectedViewFilters.maxPage}
          limit={state.selectedViewFilters.limit}
          search={state.selectedViewFilters.search}
          handleLimitChange={(value) => dispatch({ type: ImportQuestionsUpdate.UpdateSelectedViewFilter, filterField: 'limit', filterValue: value })}
          handlePageChange={(page) => dispatch({ type: ImportQuestionsUpdate.UpdateSelectedViewFilter, filterField: 'page', filterValue: page })}
          handleSearchChange={(value) => dispatch({ type: ImportQuestionsUpdate.UpdateSelectedViewFilter, filterField: 'search', filterValue: value })}
          maxLimit={50}
        >
          {filteredSelected.map(question => <ImportQuestionListItem key={question.id} question={question} selected={true} dispatch={dispatch} />)}
        </QuestionListHeader>
      </Group>
    </div>
  )
}

function QuestionListHeader (
  { buttonOne, buttonTwo, maxWidth, loading, page, maxPage, limit, search, handleLimitChange, handlePageChange, handleSearchChange, maxLimit = 100, children }
) {
  const [searchValue, setSearchValue] = useState(search)
  const deferredSearch = useDeferredValue(searchValue)
  const [isPending, startTransition] = useTransition()
  console.debug('QuestionListHeader rendering - isPending:', isPending);

  useEffect(() => {
    if ((deferredSearch === searchValue) && (deferredSearch !== search)) {
      startTransition(() => {
        handleSearchChange(searchValue)
      })
    }
  }, [searchValue, deferredSearch, search, handleSearchChange])
  const smallerMenu = maxLimit === 50
  const limitData = useMemo(() => {
    return maxLimit === 50 ? ['1', '10', '25', '50'] : ['10', '25', '50', maxLimit.toString()]
  }, [maxLimit])

  return (
    <Container maw={maxWidth} miw={maxWidth} h='70vh'>
      <Group position='apart' gap='xl' wrap='nowrap' grow>
        <div />
        {buttonOne}
        {buttonTwo}
        <div />
      </Group>
      <Space h='sm' />
      <Paper shadow="md" radius="md" p="md" maw='100%' withBorder>
        <Grid columns={24} align='center'>
          <Grid.Col span={smallerMenu ? 4 : 3}>
            <Select
              rightSection={<IconChevronDown size="1rem" />}
              rightSectionWidth={30}
              styles={{ rightSection: { pointerEvents: 'none' } }}
              radius='lg'
              data={limitData}
              value={limit.toString()}
              onChange={(value) => handleLimitChange(value ? parseInt(value) : 25)}
              allowDeselect={false}
              withCheckIcon={false}
            />
          </Grid.Col>
          <Grid.Col span={smallerMenu ? 13 : 14}>
            <Pagination size={smallerMenu ? 'xs' : 'md'} value={page} onChange={(page) => handlePageChange(page)} total={maxPage} />
          </Grid.Col>
          <Grid.Col span={7}>
            <TextInput
              placeholder='Search by content'
              size='md'
              value={searchValue}
              onChange={(event) => setSearchValue(event.currentTarget.value)}
            />
          </Grid.Col>
        </Grid>
      </Paper>
      <Space h='xs' />
      <div>
        <Box pos='relative'>
          <LoadingOverlay visible={loading} overlayProps={{ blur: 4, backgroundOpacity: 0.6 }} />
          <Stack justify='flex-start' gap={0}>
            {children}
          </Stack>
        </Box>
      </div>
    </Container>
  )
}

export function ImportQuestionListItem ({ question, selected, dispatch = null }) {
  const { hovered, ref } = useHover();
  const [expanded, setExpanded] = useState(false)
  const onSelect = useCallback((questionId, currentlySelected, currentlyExpanded) => {
    if (dispatch) {
      if (currentlyExpanded) {
        setExpanded(false)
      }
      dispatch({ type: ImportQuestionsUpdate.SelectQuestion, questionId: questionId, selected: !currentlySelected })
    } else {
      setExpanded(prev => !prev)
    }
  }, [dispatch, setExpanded])

  const selectQuestion = useCallback((e) => {
    onSelect(question.id, selected, expanded)
  }, [question.id, selected, expanded, onSelect])

  const toggleExpanded = useCallback((e) => {
    if (e.button !== 2) {
      e.stopPropagation()
      setExpanded(prev => !prev)
    }
  }, [setExpanded])

  const toggleExpandedRightClick = useCallback((e) => {
    e.preventDefault()
    setExpanded(prev => !prev)
  }, [setExpanded])

  return (
    <div style={{ userSelect: 'none', cursor: 'pointer' }} ref={ref}>
      <Paper
        bg={selected ? (hovered ? 'blue.2' : 'blue.1') : (hovered ? 'gray.2' : 'gray.1')}
        mt='xxs'
        mb={0}
        mx='sm'
        shadow='xs'
        radius='xs'
        p='sm'
        onClick={selectQuestion}
        onAuxClick={toggleExpanded}
        onContextMenu={toggleExpandedRightClick}
        size='100%'
      >
        <Grid columns={24} align='center'>
          <Grid.Col span={2}>
            <Burger opened={expanded} onClick={toggleExpanded} size='md' aria-label='Toggle answers' style={{ cursor: expanded ? 'zoom-out' : 'zoom-in' }} />
          </Grid.Col>
          <Grid.Col span={3}>
            <Text ta='left' fw={500} size='md' truncate='end'>{question.type}</Text>
          </Grid.Col>
          <Grid.Col span={19}>
            <QuestionTitleAndCompetencies
              content={question.content}
              competencies={question.competencies}
              answers={question.answers}
              textSize={'md'}
              media={question.media ?? null}
            />
          </Grid.Col>
        </Grid>
      </Paper>
      { expanded
        ? (
        <div>
          <Paper
            bg='gray.1'
            my='xs'
            mx='md'
            shadow='xs'
            radius='xs'
            p='sm'
            onClick={toggleExpanded}
            onAuxClick={toggleExpanded}
            onContextMenu={toggleExpandedRightClick}
            size='100%'
            style={{ cursor: 'zoom-out' }}
          >
          {question.answers.size
            ? (
                Array.from(question.answers.values()).map(item => <AnswersImportListItem key={item.id} answer={item} />)
              )
            : <NoAnswersImportListItemPlaceholder />}
          </Paper>
        </div>
          )
        : null}
    </div>
  )
}

/**
 * @param {Answer} answer
 */
function AnswersImportListItem ({ answer }) {
  return (
    <Container
      fluid
      bg='gray.1'
      mt='sm'
    >
      <Grid justify='flex-start' align='flex-start'>
        <Grid.Col span={1}>
          <Text ta='left' size='xs'>{(answer.position + 1).toString() + '.'}</Text>
        </Grid.Col>
        <Grid.Col span={9}>
          <Text ta='left' truncate='end'>{answer.content}</Text>
        </Grid.Col>
        <Grid.Col span={1}>
          <Text size='xs'>{Math.trunc(answer.score).toString()}</Text>
        </Grid.Col>
        <Grid.Col span={1}>
          <Checkbox
            size='xs'
            checked={!!answer.correct}
            disabled
          />
        </Grid.Col>
      </Grid>
    </Container>
  )
}

function NoAnswersImportListItemPlaceholder () {
  return (
    <Container
      fluid
      bg='gray.1'
      mt={0}
    >
      <Grid justify='flex-start' align='flex-start'>
        <Grid.Col span={12}>
          <Text ta='left' truncate='end'>{'No answers for selected question.'}</Text>
        </Grid.Col>
      </Grid>
    </Container>
  )
}
