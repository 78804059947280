/* eslint react/prop-types: 0 */
import React, { useContext, useState, useRef, useMemo, useEffect, useCallback, memo } from 'react'
import { useCycleStatuses } from './CycleHooks'
import { useDisclosure } from '@mantine/hooks'
import { CycleContext } from '../../core/ReactTable/TableContexts'
import { useEditStatusMutation } from '../../../redux/query/hire/applicantsApi.slice'
import { Box, Button, Center, Combobox, Flex, Group, Modal, Space, Text, Tooltip, useCombobox } from '@mantine/core'
import NoticeAlert from '../../core/Alert/NoticeAlert'
import { DatePicker } from '@mantine/dates'
import dayjs from 'dayjs'
import { STATUS_COLUMN_WIDTH } from './DetailViewHooks';

export function DetailViewStatusCell ({ cell: { value: row } }) {
  const value = row.status?.name ?? ''
  const cycleId = useContext(CycleContext)
  const [statuses] = useCycleStatuses(cycleId)
  const [dateModalOpened, { open: openDateModal, close: closeDateModal }] = useDisclosure(false)
  const [currentValue, setCurrentValue] = useState(value)
  const ignoreClickRef = useRef(false)
  const initializedRef = useRef(false)
  const [edit] = useEditStatusMutation()

  const statusOptions = useMemo(() => {
    if (!statuses?.items?.length) {
      return noStatusOptions
    }
    return statuses.items.map(status => ({ value: status.name, label: status.name, meta: status }))
  }, [statuses])

  const applicantId = row.id

  const onSelect = useCallback((newValue, selectedOption) => {
    console.debug('Called on status select', { newValue, selectedOption, currentValue, applicantId })
    if (newValue) {
      if (newValue !== currentValue) {
        setCurrentValue(newValue)
        if (selectedOption?.meta?.tier?.id === hiredTierId) {
          openDateModal()
        } else {
          edit({ status: newValue, applicants: [applicantId], hiredDate: '', cycleId: cycleId })
        }
      }
    }
    ignoreClickRef.current = true
  }, [currentValue, applicantId, cycleId, openDateModal, edit])

  const onModalClose = useCallback((fromInsideClick = true) => {
    console.debug('Called status modal close', { value, fromInsideClick })
    closeDateModal()
    setCurrentValue(value)
    if (!fromInsideClick) {
      ignoreClickRef.current = true
    }
  }, [value, closeDateModal])

  const onModalSave = useCallback((hiredDate) => {
    console.debug('Called status modal save', { hiredDate, currentValue, applicantId })
    edit({ status: currentValue, applicants: [applicantId], hiredDate: hiredDate, cycleId: cycleId })
    closeDateModal()
  }, [currentValue, applicantId, cycleId, closeDateModal, edit])

  useEffect(() => {
    if (initializedRef.current) {
      setCurrentValue(value)
      closeDateModal()
      ignoreClickRef.current = false
    } else {
      initializedRef.current = true
    }
  }, [value, closeDateModal])

  console.debug('Detail status cell updating', { currentValue, value, applicantId, row, statusOptions })

  if (!statuses) return null
  return (
    <Flex
      w={STATUS_COLUMN_WIDTH}
      align='center'
      justify='center'
      pos='relative'
      onClick={e => e.stopPropagation()}
    >
      <SearchableSelectDropdown
        data={statusOptions}
        value={currentValue}
        onSelect={onSelect}
      />
    <StatusCellHiredDateModal opened={dateModalOpened} onClose={onModalClose} onSave={onModalSave} />
    </Flex>
  )
}

const noStatusOptions = []
const hiredTierId = 2

function StatusCellHiredDateModal ({ opened, onClose, onSave }) {
  const [value, setValue] = useState(new Date())

  const onClick = () => {
    onSave(dayjs(value).local().format('YYYY-MM-DD'))
  }
  console.debug('StatusCellHiredDateModal updating', { value, opened })
  return (
    <Modal opened={opened} onClose={() => onClose(false)} title='Info Required!'>
      <Box onClick={(event) => { event.stopPropagation() }}>
        <NoticeAlert title='Please select the hire date' />
        <Center><DatePicker value={value} onChange={setValue} /></Center>
        <Space h='md' />
        <Group justify='center' grow>
          <Button onClick={onClick} color='success'>Save</Button>
          <Button onClick={onClose} color='gray'>Cancel</Button>
        </Group>
      </Box>
    </Modal>
  )
}

const inputStyles = { right: 0, left: 0 }

const SearchableSelectDropdown = memo(function SearchableSelectDropdown ({
  data,
  onSelect,
  value = null
}) {
  const [search, setSearch] = useState('');
  const textRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false)
  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      setSearch('');
    },
    onDropdownOpen: () => {
      combobox.focusSearchInput();
    }
  });

  // Used to only show the tooltip if it's been truncated
  useEffect(() => {
    const element = textRef.current;
    if (element) {
      setIsTruncated(element.scrollWidth > element.clientWidth);
    }
  }, []);

  // Selects the first option when searching
  useEffect(() => {
    if (search !== '') {
      combobox.selectFirstOption()
    }
  }, [search, combobox])

  const options = useMemo(() => data.map(option => ({
    label: option.label,
    value: option.value,
    meta: option.meta,
    searchLabel: option.label.toLowerCase().trim()
  })), [data])

  const searchString = search.toLowerCase().trim()
  const searchOptions = options
    .filter((item) => item.searchLabel.includes(searchString))
    .map((item) => (
      <Combobox.Option value={item.value} key={item.value} meta={item.meta}>
        {item.label}
      </Combobox.Option>
    ));

  return (
    <Combobox
      pos='absolute'
      store={combobox}
      withinPortal={true}
      onOptionSubmit={(value, optionProps) => {
        combobox.closeDropdown();
        onSelect(value, optionProps)
      }}
    >
      <Combobox.Target>
        <Button
          style={inputStyles}
          color='dark.3'
          variant='subtle'
          justify='center'
          px={0}
          fz='md'
          fw={500}
          onClick={() => combobox.toggleDropdown()}
        >
          <Tooltip label={value} openDelay={500} events={{ hover: isTruncated }}>
            <Text ref={textRef} truncate='end'>{value}</Text>
          </Tooltip>
        </Button>
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Search
          value={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
          placeholder='Search statuses'
        />
        <Space h={'sm'}/>
        <Combobox.Options mah={250} style={{ overflowY: 'auto' }}>
          {searchOptions.length > 0 ? searchOptions : <Combobox.Empty>Nothing found</Combobox.Empty>}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
})
