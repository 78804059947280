import { apiSlice, ApiTags } from '../../../react/api'

export const inviteConfigTemplateApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getInviteConfigTemplate: builder.query({
      query: id => `invite-config-template/${id}`,
      providesTags: (result, error, id) => {
        if (!result) {
          return [{ type: ApiTags.InviteConfigTemplate, id: id }];
        }

        return [
          { type: ApiTags.InviteConfigTemplate, id: id },
          ...result.cycles.map(cycle => ({ type: ApiTags.Cycles, id: cycle.id }))
        ]
      }
    }),
    getInviteConfigTemplateByCycleAndTrigger: builder.query({
      query: ({ cycleId, triggerId }) => `invite-config-template/cycle/${cycleId}/trigger/${triggerId}`,
      providesTags: (result, error, arg) => {
        if (!result) {
          return [{ type: ApiTags.Triggers, id: arg.triggerId }, { type: ApiTags.Cycles, id: arg.cycleId }];
        }

        return [
          { type: ApiTags.InviteConfigTemplate, id: result.id },
          { type: ApiTags.Triggers, id: arg.triggerId },
          ...result.cycles.map(cycle => ({ type: ApiTags.Cycles, id: cycle.id }))
        ]
      }
    })
  })
})

export const { useGetInviteConfigTemplateQuery, useGetInviteConfigTemplateByCycleAndTriggerQuery } = inviteConfigTemplateApi
