const AnswerTagStateUpdate = Object.freeze({
  SetTag: 'set-tag',
  ClearField: 'clear-field'
})

function answerTagsReducer (state, action) {
  switch (action.type) {
    case AnswerTagStateUpdate.SetTag:
      console.debug('Answer tag state updated', action.payload)
      return { ...state, ...action.payload }
    case AnswerTagStateUpdate.ClearField:
      console.debug('Answer tag field cleared', action.payload)
      return { ...state, [action.payload]: '' }
  }
}

export { AnswerTagStateUpdate, answerTagsReducer }
