import { ApiAbstractIds, apiSlice, ApiTags } from '../../../react/api'
import { paramsToQuery } from '../../../react/util/queries'

export const integrationApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getIntegrations: builder.query({
      query: (params = { limit: 0 }) => `integrations${paramsToQuery(params)}`,
      providesTags: (result) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.Integrations, id: id })),
              { type: ApiTags.Integrations, id: ApiAbstractIds.PartialList }
            ]
          : [{ type: ApiTags.Integrations, id: ApiAbstractIds.PartialList }]
    })
  })
})

export const { useGetIntegrationsQuery } = integrationApi
