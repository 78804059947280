import React, { useState } from 'react'
import FileHome from './FileHome'
import { useParams } from 'react-router-dom'
import { Button } from '@mantine/core'
import { IconFileZip } from '@tabler/icons-react'
import Loading from '../../core/Loading'
import ErrorLoading from '../../core/ErrorLoading'
import { useGetApplicantFilesQuery, useGetApplicantQuery } from '../../../redux/query/hire/applicantsApi.slice'
import { downloadFile } from '../../../services/public.service'
import _ from 'lodash'
import { showNotification } from '@mantine/notifications'

export default function ApplicantFileHome () {
  const [loading, setLoading] = useState(false)
  const { id: cycleId, applicant_id: applicantId } = useParams()
  const { data: applicant, isError: applicantError, isLoading: applicantLoading } = useGetApplicantQuery(applicantId)
  const { data: files, isError: filesError, isLoading: filesLoading } = useGetApplicantFilesQuery(applicantId)

  if (applicantError || filesError) {
    return <ErrorLoading />
  }

  if (applicantLoading || filesLoading) {
    return <Loading />
  }

  const downloadAll = () => {
    setLoading(true)

    downloadFile(
      `/api/v1/applicants/${applicantId}/files/download`,
      `${applicant.last_name.replace(/[^a-zA-Z0-9]/, '_').toLowerCase()}_${applicant.first_name.replace(/[^a-zA-Z0-9]/, '_').toLowerCase()}_files.zip`,
      'post'
    )
      .catch(err => {
        if (err.response.status === 400) {
          const data = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(err.response.data)))

          if (data?.type === 'validation_error' || data?.type === 'general_error') {
            _.forEach(data.errors, error => {
              showNotification({
                title: 'Something went wrong',
                message: error,
                color: 'red',
                autoClose: 3000
              })
            })
          } else {
            showNotification({
              message: 'Something went wrong',
              color: 'red',
              autoClose: 3000
            })
          }
        } else {
          showNotification({
            message: 'Something went wrong',
            color: 'red',
            autoClose: 3000
          })
        }
      })
      .finally(() => setLoading(false))
  }

  const downloadBtn = <Button color='blue.6' onClick={downloadAll} loading={loading} leftSection={<IconFileZip size={18}/>}>Download All</Button>

  return (
    <FileHome
      files={files.items}
      downloadBtn={downloadBtn}
      cycleId={cycleId}
    />
  )
}
