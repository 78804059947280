import { SimpleGrid } from '@mantine/core'
import React from 'react'
import CreateButtons from './CreateButtons'
import UserTable from './UserTable'

export default function UserHome () {
  return (
    <SimpleGrid>
      <CreateButtons/>
      <UserTable />
    </SimpleGrid>
  )
}
