/* eslint react-hooks/exhaustive-deps: 0 */
import { Document, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
import React, { useEffect, useMemo, useState } from 'react'
import { getAssessment } from '../../../js/api/assessment_repository'
import { getOrganization } from '../../../js/api/organization_repository'
import { QuestionType } from '../../../js/generated/enums/QuestionType'
import PdfPage from '../PdfPage'
import CoverPage from './CoverPage'
import DirectionsPage from './DirectionsPage'
import EndOfInterviewPage from './EndOfInterviewPage'
import InterviewSummary from './InterviewSummary'
import QuestionPage from './QuestionPage'

const defaultQuestions = [
  {
    id: -1,
    answers:
    [
      {
        id: -1,
        position: 0,
        question_id: -1,
        content: 'Communicated in a clear, concise manner throughout the interview'
      },
      {
        id: -2,
        position: 1,
        question_id: -1,
        content: ''
      },
      {
        id: -3,
        position: 2,
        question_id: -1,
        content: 'Displayed adequate verbal communication skills throughout the interview.'
      },
      {
        id: -4,
        position: 3,
        question_id: -1,
        content: ''
      },
      {
        id: -5,
        position: 4,
        question_id: -1,
        content: 'Displayed poor verbal communication skills throughout the interview (Spoke too quietly to be heard, difficult to understand, Did not answer questions directly)'
      }
    ],
    content: '<p>Rate the applicant\'s overall ability to speak clearly during the interview, using the following scale</p>',
    type: QuestionType.Interview,
    competencies: [{ name: 'Verbal Reasoning', description: 'Listens carefully and makes sure others\' messages are understood.' }]
  },
  {
    id: -2,
    answers:
    [
      {
        id: -6,
        position: 0,
        question_id: -2,
        content: 'Appeared to understand all of the questions asked based on his/her responses.'
      },
      {
        id: -7,
        position: 1,
        question_id: -2,
        content: ''
      },
      {
        id: -8,
        position: 2,
        question_id: -2,
        content: 'Understood most of the questions answered based on his/her responses.'
      },
      {
        id: -9,
        position: 3,
        question_id: -2,
        content: ''
      },
      {
        id: -10,
        position: 4,
        question_id: -2,
        content: 'Had difficulty understanding most of the questions asked based on his/her responses'
      }
    ],
    content: '<p>How do you rate the applicant\'s overall ability to listen effectively during the interview?</p>',
    type: QuestionType.Interview,
    competencies: [{ name: 'Listening & Understanding Others', description: 'Being able to express oneself in a manner that others understand. Sends clear and convincing messages.' }]
  }
]

export default function Interview () {
  const [assessment, setAssessment] = useState(null)
  const [organization, setOrganization] = useState({})
  const [questions, setQuestions] = useState([])

  useEffect(() => {
    if (assessment === null) {
      getAssessment(document.getElementById('interview-container').getAttribute('data-assessment')).then(assessment => {
        setAssessment(assessment)
        setQuestions(Object.values(assessment.questions).sort((a, b) => a.position - b.position))
      })
    }
  }, [])

  useEffect(() => {
    if (assessment !== null && assessment.organization !== undefined) {
      getOrganization(assessment.organization.id).then((o) => {
        setOrganization(o)
      })
    }
  }, [assessment])

  return (
    <>
      <PDFDownloadLink document={<InterviewPDF assessment={assessment} organization={organization} questions={questions} />} fileName={getFileName(assessment, organization)} style={{ padding: 10, backgroundColor: '#12b886', color: 'white', marginBottom: 10, marginHorizontal: 'auto', borderRadius: 5 }}>
      {({ loading }) => (loading ? 'Loading document...' : 'Download as PDF')}
      </PDFDownloadLink>
      <PDFViewer width="100%" height="900px">
        <InterviewPDF assessment={assessment} organization={organization} questions={questions} />
      </PDFViewer>
    </>
  )
}

function InterviewPDF (props) {
  const interviewQuestions = useMemo(() => {
    return props.questions.filter(q => q.type === QuestionType.Interview).toSorted((a, b) => a.position - b.position)
  }, [props.questions])

  return (
    <Document title={props.assessment?.name ? getFileName(props.assessment, props.organization) : 'Interview Guide'}>
      <PdfPage cover={true}>
        <CoverPage assessment={props.assessment} organization={props.organization} />
      </PdfPage>
      <PdfPage>
        <InterviewSummary assessment={props.assessment} organization={props.organization} questions={props.questions} />
      </PdfPage>
      <PdfPage>
        <DirectionsPage />
      </PdfPage>
      {props.questions.map((question) => {
        const number = (interviewQuestions.findIndex(q => q.id.toString() === question.id.toString()) + 1)
        return (
          <PdfPage key={question.id}>
            <QuestionPage question={question} number={number > 0 ? number : ''} />
          </PdfPage>
        )
      })}
      <PdfPage>
        <EndOfInterviewPage />
      </PdfPage>
      {defaultQuestions.map((question) => {
        return (
          <PdfPage key={question.id}>
            <QuestionPage question={question} />
          </PdfPage>
        )
      })}
    </Document>
  )
}

function getFileName (assessment, organization) {
  if (assessment !== null && organization !== null) {
    return `${assessment?.name?.toLowerCase().replace(/\s/g, '_')}_for_${organization?.name?.toLowerCase().replace(/\s/g, '_')}.pdf`
  } else {
    return 'interview_guide.pdf'
  }
}
