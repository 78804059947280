/* eslint react/prop-types: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
import React, { memo, useContext, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { Button, Group, SimpleGrid } from '@mantine/core'
import { IconMail } from '@tabler/icons-react'
import { isNotEmpty, useForm } from '@mantine/form'
import Messages from './Messages'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import Program from './Program'
import { contact } from '../../../js/api/email_repository'
import { showNotification } from '@mantine/notifications'
import FormError from '../../forms/FormError'
import NoticeAlert from '../../core/Alert/NoticeAlert'
import {
  ContactContext,
  ContactDispatchContext,
  ContactQueryErrorContext,
  ContactQueryingContext
} from './ContactContexts';
import { calculateShowPhaseOrBattery, MessageType } from './ContactState';

dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)

const Form = memo(function Form ({ onSuccessRedirect }) {
  const state = useContext(ContactContext)
  const dispatch = useContext(ContactDispatchContext)
  const submissionsQuerying = useContext(ContactQueryingContext)
  const submissionsError = useContext(ContactQueryErrorContext)

  const selectedIds = useMemo(() => {
    return [...state.filteredApplicants.entries()]
      .filter(([, selected]) => !!selected)
      .map(([applicantId]) => applicantId)
  }, [state.filteredApplicants])

  const previewId = selectedIds[0] ?? null

  console.debug('Form updating', { state, onSuccessRedirect, dispatch })
  const emailTemplates = state.emailTemplates
  const textTemplates = state.textTemplates
  const [loading, setLoading] = useState(false)

  const form = useForm({
    initialValues: {
      applicants: selectedIds,
      messages: [],
      retakes: [],
      ap_cycle: null,
      passportRetakes: [],
      inviteConfigTemplate: null
    },
    transformValues: values => {
      const transformedValues = {
        applicants: selectedIds,
        emails: [],
        texts: [],
        retakes: [],
        ap_cycle: values.ap_cycle,
        passportRetakes: [],
        inviteConfigTemplate: values.inviteConfigTemplate
      }

      values.messages.forEach(message => {
        if (message.type === MessageType.Email) {
          transformedValues.emails.push({
            send_at: message.send_at !== null ? dayjs(message.send_at).format() : null,
            template: message.template,
            subject: message.subject,
            body: message.body
          })
        } else {
          transformedValues.texts.push({
            send_at: dayjs(message.send_at).format(),
            template: message.template,
            body: message.body
          })
        }
      })

      values.retakes.forEach(retake => {
        transformedValues.retakes.push({ applicant: retake.submission.id, retake: retake.retake ? '1' : '0', retakeRequired: '1', unlock: '0' })
      })

      values.passportRetakes.forEach(retake => {
        transformedValues.passportRetakes.push({ applicant: retake.id, retake: retake.retake ? '1' : '0', retakeRequired: (retake.retakeRequired || retake.retake) ? '1' : '0', unlock: retake.unlock ? '1' : '0' })
      })

      return transformedValues
    },
    validate: {
      applicants: isNotEmpty('At least one applicant needs to be selected'),
      messages: {
        subject: (value, values, path) => {
          const index = _.split(path, '.', 2)[1]

          if (values.messages[index].type === MessageType.Email && value === '') {
            return 'Subject cannot be empty'
          }

          return null
        },
        body: value => value !== '' && value !== '<p></p>' ? null : 'Body cannot be empty',
        template: (value, values, path) => {
          const index = _.split(path, '.', 2)[1]

          if (values.messages[index].type === MessageType.Text && value === null) {
            return 'Please choose a template'
          }

          return null
        }
      },
      messagesNotEmpty: (value, values) => _.size(values.messages) < 1 ? 'At least one message needs to be added' : null,
      ap_cycle: (value, values) => {
        let hasInvitation = false
        let hasInvalidInvitation = false

        values.messages.forEach(message => {
          if (messageIsAssessmentInvite(message, emailTemplates, textTemplates, true, false)) {
            hasInvitation = true
          }
          if (messageIsAssessmentInvite(message, emailTemplates, textTemplates, false, true)) {
            hasInvalidInvitation = true
          }
        })

        if (hasInvitation && hasInvalidInvitation) {
          return 'Cannot use both a battery and phase invite template'
        }

        if (!hasInvitation && value) {
          return 'Must select at least one message template with the corresponding tag for a battery invite'
        }
        return (hasInvitation && ((value === null) || !!values.inviteConfigTemplate)) ? 'Must select a battery for message templates with the battery invite tag' : null
      },
      inviteConfigTemplate: (value, values) => {
        let hasInvitation = false

        values.messages.forEach(message => {
          if (messageIsAssessmentInvite(message, emailTemplates, textTemplates, false, true)) {
            hasInvitation = true
          }
        })

        if (!hasInvitation && value) {
          return 'Must select at least one message template with the corresponding tag for a phase invite'
        }
        return (hasInvitation && (!value?.phase || !!values.ap_cycle)) ? 'Must select a phase for message templates with the phase invite tag' : null
      }
    }
  })

  useEffect(() => {
    form.setValues({
      applicants: selectedIds,
      messages: [...state.messages.values()],
      retakes: state.batteryId ? [...(state.retakes.get(state.batteryId) ?? new Map()).values()].filter(retake => state.filteredApplicants.get(retake.submission.id)) : [],
      ap_cycle: state.batteryId,
      passportRetakes: state.inviteConfigTemplate?.phase ? [...(state.phaseRetakes.get(parseInt(state.inviteConfigTemplate.phase)) ?? new Map()).values()].filter(retake => (retake.started || retake.locked) && state.filteredApplicants.get(retake.id)) : [],
      inviteConfigTemplate: state.inviteConfigTemplate
    })
  }, [selectedIds, state.messages, state.batteryId, state.retakes, state.phaseRetakes, state.inviteConfigTemplate])

  const [showBattery, showPhase] = useMemo(() => {
    return calculateShowPhaseOrBattery(state.messages, state.emailTemplates, state.textTemplates)
  }, [state.messages, state.emailTemplates, state.textTemplates])

  const show = showPhase || showBattery

  const onSubmit = async values => {
    console.debug('Called form onSubmit', { values })
    setLoading(true)

    const success = await contact(values)

    if (success) {
      window.location.replace(onSuccessRedirect)
    } else {
      showNotification({
        title: 'Something went wrong',
        message: 'There was an error contacting the applicants',
        color: 'red'
      })
      setLoading(false)
    }
  }

  console.debug('Form updating', { form, loading, selectedIds })

  const [filteredEmailTemplates, filteredTextTemplates] = useMemo(() => {
    const newFilteredEmails = [...emailTemplates.values()]
      .map(template => ({
        ...template,
        disabled: !((!showPhase || !template.category?.isBatteryInvite) && (!showBattery || !template.category?.isPhaseInvite))
      }))

    const newFilteredTexts = [...textTemplates.values()]
      .map(template => ({
        ...template,
        disabled: !((!showPhase || !template.category?.isBatteryInvite) && (!showBattery || !template.category?.isPhaseInvite))
      }))
    return [newFilteredEmails, newFilteredTexts]
  }, [showPhase, showBattery, emailTemplates, textTemplates])

  const messages = useMemo(() => {
    return [...state.messages.values()]
  }, [state.messages])

  const numEmails = messages.filter(message => message.type === MessageType.Email).length
  const numTexts = messages.length - numEmails

  const excludedCount = form.values.passportRetakes.filter(retake => (!retake.retake && retake.retakeRequired) || (!retake.unlock && retake.locked)).length
  return (
    <form onSubmit={form.onSubmit(values => onSubmit(values))}>
      <SimpleGrid cols={1}>
        <Messages
          messages={messages}
          form={form}
          templates={filteredEmailTemplates}
          textTemplates={filteredTextTemplates}
          previewApplicant={previewId}
        />
        {show && (
          <Program applicants={selectedIds} showPhase={showPhase} showBattery={showBattery} />
        )}
        <NoticeAlert>
          <SimpleGrid cols={1}>
            <div>
              {!!messages.length && !!selectedIds.length && (
                <>
                  You are about to send {numEmails > 0 && ' ' + numEmails + ' email' + (numEmails === 1 ? '' : 's')} {numTexts > 0 && (numEmails > 0 ? ' and ' : ' ') + numTexts + ' text message' + (numTexts === 1 ? '' : 's')} to {selectedIds.length - excludedCount} applicant{(selectedIds.length - excludedCount) === 1 ? '' : 's'}.
                  {excludedCount ? ` ${excludedCount} of your selected applicants will not be contacted due to the retake choices above, check the active assessment column to confirm which selected applicants will not receive any messages.` : ''}
                </>
              )}
            </div>
            <div>Be sure to include your email address in your email if you need a reply from an applicant(s). A work email address is recommended.</div>
          </SimpleGrid>
        </NoticeAlert>
        <FormError form={form} name='applicants' />
        <FormError form={form} name='messagesNotEmpty' />
        <FormError form={form} name='inviteConfigTemplate' />
        <FormError form={form} name='ap_cycle' />
        <Group>
          <Button
            color='blue'
            leftSection={<IconMail size={18} />}
            type='submit'
            disabled={submissionsQuerying || submissionsError}
            loading={loading}
          >
            {submissionsError ? 'Submission data error - refresh page' : (loading ? 'Loading...' : 'Send')}
          </Button>
          <Button color='gray' onClick={() => window.history.back()}>Cancel</Button>
        </Group>
      </SimpleGrid>
    </form>
  )
})

export default Form

function messageIsAssessmentInvite (message, emailTemplates, textTemplates, allowBattery = true, allowPhase = true) {
  return messageTemplateIsAssessmentInvite(
    message,
    message.type === MessageType.Email ? emailTemplates : textTemplates,
    allowBattery,
    allowPhase
  )
}

function messageTemplateIsAssessmentInvite (message, emailOrTextTemplates, allowBattery, allowPhase) {
  for (const template of emailOrTextTemplates.values()) {
    if (message.template === template.id.toString()) {
      return (tagIsAssessmentInvite(template.category?.tag, allowBattery, allowPhase))
    }
  }
  return false
}

function tagIsAssessmentInvite (tag, allowBattery, allowPhase) {
  return (allowBattery && assessmentTemplateCategoryTags.includes(tag)) || (allowPhase && tagIsPhaseInvite(tag))
}

function tagIsPhaseInvite (tag) {
  return tag && tag.toLowerCase().trim().startsWith(sharedPhaseInvitePrefix)
}

const assessmentTemplateCategoryTags = [
  'proctorfree-invitation',
  'proctorfree-reminder'
]

// const createPhaseInvitePrefix = 'passport-invite-phase-'
// const phaseInviteReminderPrefix = 'passport-invite-reminder-phase-'
const sharedPhaseInvitePrefix = 'passport-invite'
