/* eslint react/prop-types: 0 */
import { useCycle } from '../../CycleHooks';
import { Group, rem, Skeleton, Title, Tooltip } from '@mantine/core';
import React, { memo } from 'react';
import { DiagnosticsNavButton } from './DiagnosticsNavButton';
import { IconCircleCheck, IconCircleOff } from '@tabler/icons-react';
import { useIsAdmin } from '../CyclesHooks';
import { CycleSummaryCardContent } from '../summaries/CycleSummaryCard';

export function CyclePageTitle ({ cycleId, children, pageName = '', active = null }) {
  return (
    <Group justify='space-between'>
      {children
        ? (
        <Group mx='2rem'>
          <CyclePageName cycleId={cycleId} pageName={pageName} />
          {children}
        </Group>
          )
        : (
        <CyclePageName cycleId={cycleId} pageName={pageName} />
          )}
      <DiagnosticsNavButton cycleId={cycleId} active={active} />
    </Group>
  )
}

const iconStyle = { width: rem(24), height: rem(24) }

const CyclePageName = memo(function CyclePageName ({ cycleId, pageName = '' }) {
  const [description, querying] = useCycle(cycleId)
  const [isAdmin] = useIsAdmin()
  return (
    <Group>
      {(!description && querying)
        ? <Skeleton height={40} radius="xl" miw='24rem' w='20%' />
        : (
          <>
            <Tooltip withArrow label={<CycleSummaryCardContent cycle={description} isAdmin={isAdmin} withCard={false} />}>
              <Title>
                {description?.name || (description ? `Cycle #${cycleId ?? 'Id Missing'} Name Missing` : `(Error Fetching Cycle #${cycleId ?? 'Id Missing'} Description)`)}{pageName ? ' - ' : ''}{pageName}
              </Title>
            </Tooltip>
            <TitleActiveIcon cycle={description} />
          </>
          )}

    </Group>
  )
})

const TitleActiveIcon = memo(function TitleActiveIcon ({ cycle }) {
  const cycleIsActive = (cycle?.is_active || (cycle?.active === 0))
  const description = `This cycle is currently${cycleIsActive ? '' : ' not'} active.${cycleIsActive ? '' : ' All related passports and invites are also inactive while the cycle has this status.'}`
  return (
    <Tooltip withArrow label={description}>
      {cycleIsActive
        ? (
        <IconCircleCheck style={iconStyle} aria-label='Cycle is active icon' aria-description={description} />
          )
        : (
        <IconCircleOff style={iconStyle} aria-label='Cycle is inactive icon' aria-description={description} />
          )}
    </Tooltip>
  )
})
