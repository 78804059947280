/* eslint react/prop-types: 0 */
import React, { memo, useEffect, useRef, useState } from 'react';
import { getCurrentSeconds } from './util';
import { notifications } from '@mantine/notifications';
import { Affix, Badge, Box, rem, Tooltip, Transition } from '@mantine/core';
import { IconClockRecord } from '@tabler/icons-react';

const warnTimeRemaining = 120

export const AssessmentTimerFrame = memo(function AssessmentTimerFrame ({ timeLimit, submitAssessment }) {
  return (
    <Affix position={{ top: 60, left: 40 }}>
      <Transition transition='slide-up' duration={1000} exitDuration={50} mounted={!!timeLimit}>
        {(transitionStyles) => (
          <Box
            style={transitionStyles}
            aria-label='Assessment time remaining'
          >
            <AssessmentTimer initialTimeLimit={timeLimit} submitAssessment={submitAssessment} />
          </Box>
        )}
      </Transition>
    </Affix>
  )
})

export const AssessmentTimer = memo(function AssessmentTimer ({ initialTimeLimit, submitAssessment }) {
  const [hideText, setHideText] = useState(false)
  const [time, setTime] = useState(initialTimeLimit)
  const [startTime] = useState(getCurrentSeconds())
  const expiredRef = useRef(false)
  const warnedRef = useRef(false)

  useEffect(() => {
    const intervalId = window.setInterval(() => {
      const timeDifference = getCurrentSeconds() - startTime
      const timeRemaining = Math.max(Math.floor(initialTimeLimit - timeDifference), 0)
      setTime(timeRemaining)
    }, 1000)

    return () => window.clearInterval(intervalId)
  }, [setTime, startTime, initialTimeLimit])

  useEffect(() => {
    if (time <= 0 && !expiredRef.current) {
      notifications.show({
        id: 'time-limit',
        title: 'Time Limit Reached',
        message: 'No time remaining for this assessment, your progress is being submitted.',
        color: 'red',
        withBorder: true
      })
      submitAssessment()
      expiredRef.current = true
    } else if (time <= warnTimeRemaining && !warnedRef.current && !expiredRef.current) {
      const warnTime = Math.max(Math.floor(warnTimeRemaining / 60), 1)
      const warnMessage = warnTime > 1 ? 'There are fewer than ' + warnTime.toFixed(0) + ' minutes remaining.' : 'There is less than one minute remaining.'
      warnedRef.current = true
      notifications.show({
        id: 'time-warning',
        title: 'Time Remaining',
        message: warnMessage,
        color: 'yellow',
        withBorder: true
      })
    }
  }, [time, submitAssessment])

  const hours = Math.floor((time % (60 * 60 * 24)) / (60 * 60))
  const minutes = Math.floor((time % (60 * 60)) / 60)
  const seconds = Math.floor(time % 60)
  let timeText = ''

  if (hours) {
    timeText += pad(hours, 2) + ':'
  }

  if (minutes) {
    timeText += pad(minutes, 2) + ':'
  } else {
    timeText += '00:'
  }

  if (seconds) {
    timeText += pad(seconds, 2)
  } else {
    timeText += '00'
  }

  return (
    <Tooltip label='Click to toggle visibility' withArrow>
      <Badge
        leftSection={<IconClockRecord style={{ width: rem(12), height: rem(12) }} strokeWidth={2} />}
        color={time < warnTimeRemaining ? 'red' : 'blue.6'}
        onClick={() => setHideText(prev => !prev)}
        aria-label='Time remaining'
      >
        {hideText ? '' : timeText}
      </Badge>
    </Tooltip>
  )
})

function pad (str, max) {
  str = str.toString()
  return str.length < max ? pad('0' + str, max) : str
}
