import React, { useMemo } from 'react'
import Content from '../../layout/Content'
import { useAssessments } from './AssessmentHooks'
import { Anchor, List, Space, Title } from '@mantine/core'
import _ from 'lodash'
import { useGetPublishedAssessmentsQuery } from '../../../redux/query/build/publishedAssessmentsApi.slice'
import ErrorLoading from '../../core/ErrorLoading'
import Loading from '../../core/Loading'

/**
 * Reports in which unpublished and published assessments a media file is used.
 * @typedef {object} MediaAssessmentUsageProps
 * @property {object} media The media file.
 * @property {number} media.id The media file id.
 * @param {MediaAssessmentUsageProps} props
 * @returns {JSX.Element}
 */
export default function MediaAssessmentUsage ({ media }) {
  const assessmentsParams = useMemo(() => {
    return { limit: 0, media: media.id }
  }, [media.id])

  const [unpublishedAssessments] = useAssessments(assessmentsParams)
  const { data: publishedAssessments, isError, isLoading } = useGetPublishedAssessmentsQuery(assessmentsParams)

  if (isError) {
    return <ErrorLoading />
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <Content>
      {
        unpublishedAssessments.length > 0 &&
        (
          <>
            <Title order={5} color='dimmed'>Used in the following unpublished assessments</Title>
            <List
              listStyleType='none'
              withPadding
            >
              {
                _.map(unpublishedAssessments, unpublishedAssessment => (
                  <List.Item key={unpublishedAssessment.id}>
                    <UnpublishedAssessmentAnchor unpublishedAssessment={unpublishedAssessment} />
                  </List.Item>
                ))
              }
            </List>
          </>
        )
      }
      <Space h='lg' />
      {
        publishedAssessments.items.length > 0 &&
        (
          <>
            <Title order={5} color='dimmed'>Used in the following published assessments</Title>
            <List
              listStyleType='none'
              withPadding
            >
              {
                _.map(publishedAssessments.items, publishedAssessment => (
                  <List.Item key={publishedAssessment.id}>
                    <PublishedAssessmentAnchor publishedAssessment={publishedAssessment} />
                  </List.Item>
                ))
              }
            </List>
          </>
        )
      }
    </Content>
  )
}

/**
 * Creates an anchor component to go to an unpublished assessment.
 * @typedef {object} UnpublishedAssessmentAnchorProps
 * @property {object} unpublishedAssessment The unpublished assessment.
 * @property {number} unpublishedAssessment.id The unpublished assessment id.
 * @property {string} unpublishedAssessment.internal_name The unpublished assessment internal name.
 * @param {UnpublishedAssessmentAnchorProps} props
 * @returns {JSX.Element}
 */
function UnpublishedAssessmentAnchor ({ unpublishedAssessment }) {
  return <Anchor href={`/build/assessments/${unpublishedAssessment.id}/preview`}>{unpublishedAssessment.internal_name}</Anchor>
}

/**
 * Creates an anchor component to go to a published assessment.
 * @typedef {object} PublishedAssessmentAnchorProps
 * @property {object} publishedAssessment The published assessment.
 * @property {number} publishedAssessment.id The published assessment id.
 * @property {string} publishedAssessment.name The published assessment name.
 * @param {PublishedAssessmentAnchorProps} props
 * @returns {JSX.Element}
 */
function PublishedAssessmentAnchor ({ publishedAssessment }) {
  return <Anchor href={`/testenv/assessments/${publishedAssessment.id}/view`}>{publishedAssessment.name}</Anchor>
}
