/* eslint react/prop-types: 0 */
import React, { memo } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { Menu, Button, rem } from '@mantine/core';
import { CyclePassRoute } from '../../../../../js/generated/enums/CyclePassRoute';
import { IconPlaystationCircle } from '@tabler/icons-react';

export const DiagnosticsNavButton = memo(function DiagnosticsNavButton ({ cycleId, active = null }) {
  return (
    <Menu shadow="md" width={200} trigger='click-hover' openDelay={100} closeDelay={400} position='left-start' withArrow>
      <Menu.Target>
        <Button>Diagnostics</Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label ta='center'>Cycle</Menu.Label>
        <Menu.Item
          {...makeItemProps(cycleId, DiagnosticsNavButtonTabs.Links, active)}
        >
          Links
        </Menu.Item>
        <Menu.Item
          {...makeItemProps(cycleId, DiagnosticsNavButtonTabs.Stages, active)}
        >
          Stages
        </Menu.Item>
        <Menu.Item
          {...makeItemProps(cycleId, DiagnosticsNavButtonTabs.Analytics, active)}
        >
          Analytics
        </Menu.Item>
        <Menu.Divider />
        <Menu.Label ta='center'>Applicant</Menu.Label>
        <Menu.Item
          {...makeItemProps(cycleId, DiagnosticsNavButtonTabs.Passports, active)}
        >
          Passports
        </Menu.Item>
        <Menu.Item
          {...makeItemProps(cycleId, DiagnosticsNavButtonTabs.Progress, active)}
        >
          Progress
        </Menu.Item>
        <Menu.Item
          {...makeItemProps(cycleId, DiagnosticsNavButtonTabs.ProctorFree, active)}
        >
          ProctorFree
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
})

const iconStyle = { width: rem(14), height: rem(14) }
const activeStyle = { item: { backgroundColor: 'var(--mantine-color-blue-6' } }

function makeItemProps (cycleId, tab, active) {
  const isActive = !!active && active === tab
  if (tab === DiagnosticsNavButtonTabs.ProctorFree) {
    return { component: 'a', href: `/invites/manage/cycles/${cycleId}/review-phases`, ...(isActive ? { styles: activeStyle, rightSection: <IconPlaystationCircle style={iconStyle} />, 'aria-label': 'Current page' } : {}) }
  }
  return { component: Link, to: generatePath(tab, { cycleId }), ...(isActive ? { styles: activeStyle, rightSection: <IconPlaystationCircle style={iconStyle} />, 'aria-label': 'Current page' } : {}) }
}

export const DiagnosticsNavButtonTabs = {
  Passports: CyclePassRoute.CyclePassesList,
  Stages: CyclePassRoute.CycleStages,
  Progress: CyclePassRoute.CycleStageProgressesList,
  ProctorFree: '/invites/manage/cycles/:cycleId/review-phases',
  Analytics: CyclePassRoute.CycleInvitesAnalytics,
  Links: CyclePassRoute.CycleInvitesHome
}
