import React from 'react'
import { Button, Group, Stack, Text, TextInput, Tooltip } from '@mantine/core'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { Link } from 'react-router-dom'
import { IconBriefcase, IconFileUpload, IconFlask } from '@tabler/icons-react'
import _ from 'lodash'
import { useGetOrganizationsQuery } from '../../../../redux/query/account/organizationsApi.slice'
import Loading from '../../../core/Loading'
import ErrorLoading from '../../../core/ErrorLoading'
import { useImportAssessmentsMutation } from '../redux/AssessmentsApi'
import SpreadsheetInput from '../components/SpreadsheetInput'
import OrganizationsSelect from '../components/OrganizationsSelect'
import OnOffSwitch from '../../../../components/OnOffSwitch/OnOffSwitch'

/**
 * Page that allows to import assessments from an XLSX file. It allows to run in two modes: test and import. On test
 * mode, the file is fully validated without importing any data. On import mode, the assessments are created. When
 * importing, the user is able to append a prefix to the assessments name, and optionally select a specific organization
 * for the assessments and automatically rescale them.
 * @returns {React.ReactElement}
 */
export default function ImportAssessment () {
  const { data: organizations, isError, isLoading } = useGetOrganizationsQuery({ limit: 0 })
  const [importAssessments, { isLoading: isSubmitting }] = useImportAssessmentsMutation()

  const form = useForm({
    mode: 'controlled',
    initialValues: {
      name: '',
      organization: null,
      file: null,
      test: true,
      rescale: false
    },
    validate: {
      file: value => value !== null ? null : 'Please choose a file'
    },
    transformValues: values => ({
      ...values,
      test: values.test ? 1 : 0,
      rescale: values.rescale ? 1 : 0
    })
  })

  if (isError) {
    return <ErrorLoading />
  }

  if (isLoading) {
    return <Loading />
  }

  const submit = values => {
    importAssessments(values)
      .unwrap()
      .then(() => {
        window.location.assign('/build/assessments/import')
      })
      .catch(response => {
        if (response.status === 400 && response.data?.type === 'validation_error') {
          _.forEach(response.data.errors, error => {
            showNotification({
              title: 'Something went wrong',
              message: error,
              color: 'red',
              autoClose: 3000
            })
          })
        } else {
          showNotification({
            message: 'Something went wrong',
            color: 'red',
            autoClose: 3000
          })
        }
      })
  }

  const isTestRun = form.getValues().test

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Stack w={{ sm: '100%', md: '75%', lg: '50%' }}>
        <Text size='md'>This form allows you to create assessments from an import file directly.</Text>
        <SpreadsheetInput {...form.getInputProps('file')} />
        <TextInput
          leftSection={<IconBriefcase size='1.5rem' />}
          placeholder='Job title (Optional)'
          {...form.getInputProps('name')}
        />
        <OrganizationsSelect
          placeholder='Organization (Optional)'
          data={organizations.items.map(organization => ({ value: organization.id.toString(), label: organization.name }))}
          {...form.getInputProps('organization')}
        />
        <Stack align='flex-start'>
          <Tooltip label='Validate file without importing anything' position='right'>
            <OnOffSwitch label='Run test' color='yellow.6' defaultChecked={true} {...form.getInputProps('test')} />
          </Tooltip>
          <Tooltip label='Adjust answer scores to be between 0 and 1' position='right'>
            <OnOffSwitch label='Rescale questions' {...form.getInputProps('rescale')} />
          </Tooltip>
        </Stack>
        <Group spacing='xs'>
          <Button
            color={isTestRun ? 'yellow.6' : 'blue.6'}
            leftSection={isTestRun ? <IconFlask size={18} /> : <IconFileUpload size={18} />}
            type='submit'
            loading={isSubmitting}
          >
            {isTestRun ? 'Test' : 'Import'}
          </Button>
          <Button color='gray.6' component={Link} to='..'>Cancel</Button>
        </Group>
      </Stack>
    </form>
  )
}
