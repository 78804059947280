import { notifications } from '@mantine/notifications';
import { QuestionType } from '../../../../../js/generated/enums/QuestionType';

export const ValidationState = Object.freeze({
  Unvalidated: 'unvalidated',
  Validating: 'validating',
  Valid: 'valid',
  MissingRequiredLogicFields: 'required logic field or question missing',
  MatchAnswerContentError: 'logic answer value not possible',
  QuestionOrderError: 'logic data question must come first',
  QuestionRandomizationError: 'logic data question cannot be in a random pool with a question that goes after'
})

export function isErrorStatus (validationState) {
  switch (validationState) {
    case ValidationState.Unvalidated:
    case ValidationState.Validating:
    case ValidationState.Valid:
      return false
    default:
      return true
  }
}

export function shouldValidateLogicDataQuestionAnswersForQuestionType (questionType) {
  return (questionType !== QuestionType.FillInTheBlank) && (questionType !== QuestionType.Multiline) && (questionType !== QuestionType.Interview) && (questionType !== QuestionType.ShortAnswer)
}

export const NodeTypes = Object.freeze({
  logic: 'logic',
  feature: 'feature',
  target: 'target'
})

export const EdgeTypes = Object.freeze({
  and: 'and',
  or: 'or',
  default: 'default'
})

export function convertQuestionMapData (questionMap) {
  return [...questionMap.values()].map(elem => [elem.id, { ...elem, answers: [...elem.answers.values()] }])
}

/**
 * @param {string} content
 * @returns {string}
 */
export function stripContentTags (content) {
  return content.replace(/<[^>]*>?/gm, '').replace(/&[^;]{0,9};/gm, '')
}

let nextFakeNodeId = -1

/**
 * @returns {number}
 */
export function getNextFakeNodeId () {
  nextFakeNodeId = nextFakeNodeId - 1
  return nextFakeNodeId
}

/**
 * @param {Map.<int: Question>} questions
 * @returns {QuestionLogic[]}
 */
export function createLogicArrayFromQuestionsMap (questions) {
  const parsedLogic = []
  for (const question of questions.values()) {
    let toParseQueue = [...question.logic]
    while (toParseQueue.length) {
      const activeLogic = toParseQueue
      toParseQueue = []
      for (const logic of activeLogic) {
        parsedLogic.push({ ...logic, parentQuestionId: question.id })
        for (const child of logic.children) {
          toParseQueue.push({ ...child, parentId: logic.id, feature: logic.feature })
        }
      }
    }
  }
  return parsedLogic
}

/**
 * @param {Map.<int: Question>} questions
 * @param {Question} question
 * @param {[]} nodes
 * @param {[]} edges
 * @returns {QuestionLogic[]}
 */
export function createLogicArrayForQuestionFromNodes (questions, question, nodes, edges) {
  const nodesMap = new Map()
  const featureNodes = new Map()
  const connectionsPerNodeMap = new Map()
  let rootNode = null
  for (const node of nodes) {
    nodesMap.set(node.id, node)
    if (node.type === NodeTypes.target) {
      rootNode = node
    } else if (node.type === NodeTypes.feature) {
      if (node.data.feature) {
        featureNodes.set(node.data.feature, node)
      } else {
        notifications.show({
          id: 'No-feature-' + node.id,
          color: 'red',
          title: 'Feature Not Selected',
          message: 'No feature was selected for a feature node! Some data may have been lost.'
        })
        console.warn('No feature selected for feature node - ignoring.', node)
      }
    } else {
      const allFilled = node.data.logic.operator && node.data.logic.questionId
      if (!allFilled) {
        notifications.show({
          id: 'No-operator-or-question-' + node.id,
          color: 'red',
          title: 'Logic Option Not Selected',
          message: 'Either question or operator not selected for a logic node! ' +
            '(Question: ' + (node.data.logic.questionId ?? 'Not Selected') +
            ' | Operator: ' + (node.data.logic.operator ?? 'Not Selected') +
            ') Data is preserved but will result in issues if not resolved.'
        })
      }
    }
  }
  for (const edge of edges) {
    if (edge.type !== EdgeTypes.or) {
      if (connectionsPerNodeMap.has(edge.source)) {
        connectionsPerNodeMap.set(edge.source, [...connectionsPerNodeMap.get(edge.source), edge.target])
      } else {
        connectionsPerNodeMap.set(edge.source, [edge.target])
      }
    } else {
      console.debug('Skipping visual OR edge when parsing.', edge)
    }
  }
  const exportedLogicMap = new Map()
  const visitedNodesMap = new Map()
  const visitedConnections = new Set()
  let toParseNodes = rootNode ? [rootNode] : []
  while (toParseNodes.length) {
    const currentNodes = toParseNodes
    toParseNodes = []
    for (const node of currentNodes) {
      console.debug('Visiting node', node.id, node, visitedNodesMap.has(node.id), visitedNodesMap)
      const connectionKey = (node.parentGroupId ?? node.parentId ?? 'root') + '.to.' + node.id
      if (visitedConnections.has(connectionKey)) {
        console.debug('Would have skipped due to infinite loop.', connectionKey, visitedConnections, node)
      } else {
        visitedConnections.add(connectionKey)
      }

      let convertedNode = node
      if (visitedNodesMap.has(node.id)) {
        convertedNode = { ...node, id: getNextFakeNodeId().toString(), nodeGroupId: node.type === NodeTypes.logic ? parseInt(node.id) : node.id }
      } else {
        convertedNode = { ...node, nodeGroupId: null }
        visitedNodesMap.set(node.id, convertedNode)
      }
      const connections = connectionsPerNodeMap.get(convertedNode.nodeGroupId?.toString?.() ?? convertedNode.nodeGroupId ?? convertedNode.id) ?? []
      if (!connections?.length) {
        console.debug('Connections not found in connectionsPerNodeMap - expected if end node.', convertedNode.nodeGroupId, convertedNode.id, connections, connectionsPerNodeMap, nodesMap, convertedNode)
      }
      for (const connectionId of connections) {
        if (!nodesMap.has(connectionId)) {
          console.error('Connection id not found in node map', connectionId, nodesMap, convertedNode)
        }
        toParseNodes.push({
          ...nodesMap.get(connectionId),
          parentId: convertedNode.id,
          parentGroupId: convertedNode.nodeGroupId,
          parentType: convertedNode.type,
          feature: (convertedNode.type === NodeTypes.feature ? convertedNode.data.feature : convertedNode.feature) ?? null // Feature passed to children here.
        })
      }
      if (convertedNode.type === NodeTypes.logic) {
        const exportedLogic = {
          id: parseInt(convertedNode.id),
          parentId: convertedNode.parentType === NodeTypes.logic ? parseInt(convertedNode.parentId) : null,
          parentQuestionId: question.id,
          questionId: convertedNode.data.logic.questionId,
          operator: convertedNode.data.logic.operator,
          value: convertedNode.data.logic.value,
          feature: convertedNode.feature,
          children: [],
          nodeGroupId: convertedNode.nodeGroupId ?? null
        }
        if (exportedLogicMap.has(exportedLogic.id)) {
          console.error(
            'Converted node id already in exported logic map - should be impossible.',
            convertedNode.id, exportedLogicMap, exportedLogic, convertedNode, visitedNodesMap, nodes
          )
        }
        exportedLogicMap.set(exportedLogic.id, exportedLogic)
      }
    }
  }
  console.info('Returning redux formatted logic converted from node format.', exportedLogicMap, nodes, edges)
  return [...exportedLogicMap.values()]
}

export function deNormalizeLogicArrayForReducer (logicArray) {
  const rootNodeIds = []
  const logicMap = new Map()
  for (const logic of logicArray) {
    logicMap.set(logic.id, { ...logic, children: [] })
    if (!logic.parentId) {
      rootNodeIds.push(logic.id)
    }
  }
  for (const logic of logicArray) {
    if (logic.parentId) {
      logicMap.get(logic.parentId).children.push(logic.id)
    }
  }
  const denormalizeFromMap = (logicId) => {
    const logic = logicMap.get(logicId)
    logic.children = logic.children.map(childLogicId => denormalizeFromMap(childLogicId))
    return logic
  }
  const deNormalizedLogic = rootNodeIds.map(nodeId => denormalizeFromMap(nodeId))
  console.debug('Denormalized logic array for question.', deNormalizedLogic, logicMap, rootNodeIds, logicArray)
  return deNormalizedLogic
}
