/* eslint-disable react/prop-types */
import { Group, Select, Tooltip } from '@mantine/core'
import React, { useContext, useMemo } from 'react'
import {
  IconCheck
} from '@tabler/icons-react';
import { ContactDispatchContext } from './ContactContexts';
import { ContactStateUpdate } from './ContactState';

const iconProps = {
  stroke: 3,
  color: 'currentColor',
  opacity: 0.6,
  size: 18,
  style: { marginInlineStart: 'auto' }
}

function renderSelectOption ({ option, checked }) {
  return (
    <Group gap='xs' justify='flex-start'>
      {!!checked && <IconCheck {...iconProps} />}
      <Tooltip label='Disabled due to conflicting battery or phase invite template selection' disabled={!option.disabled} withArrow>
        <div>{option.label}</div>
      </Tooltip>
    </Group>
  )
}

export default function TemplateSelection ({ messageId, messageTemplate, form, index, templates }) {
  const dispatch = useContext(ContactDispatchContext)
  const templateOptions = useMemo(() => {
    console.debug('TemplateSelection options updating', { templates })
    return templates.map(template => ({ value: template.id.toString(), label: template.title, disabled: !!template.disabled }))
  }, [templates])

  console.debug('TemplateSelection component updating', { templateOptions })
  return (
    <Select
      label='Template'
      description='Choose a template to start with'
      data={templateOptions}
      placeholder='Blank'
      clearable
      searchable
      {...form.getInputProps(`messages.${index}.template`)}
      value={messageTemplate || null}
      onChange={value => {
        dispatch({ type: ContactStateUpdate.SetMessageTemplate, messageId: messageId, value: value })
      }}
      renderOption={renderSelectOption}
      nothingFoundMessage='No templates available'
      aria-label='Click to select message template'
    />
  )
}
