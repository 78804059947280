import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getAssessment } from '../../../js/api/assessment_repository';
import { getOrganization } from '../../../js/api/organization_repository';
import { Loader, Center, Space } from '@mantine/core';
import AssessmentEditor from './AssessmentEditor';

export function loadAssessmentData (id, setSourceData, setReload) {
  if (id) {
    const cancelToken = axios.CancelToken
    const cancelSource = cancelToken.source()
    getAssessment(id, null, cancelSource.token)
      .then(assessment => {
        if (assessment) {
          const formattedAssessment = { ...assessment, questions: Object.values((assessment.questions ?? {})) }
          if (assessment.organization) {
            getOrganization(assessment.organization.id, cancelSource.token).then((o) => {
              if (o) {
                formattedAssessment.organization = o ?? assessment.organization
                setSourceData(formattedAssessment)
                console.debug('Load assessment data effect completed loading with org', id)
                setReload(false)
              } else {
                setSourceData(formattedAssessment)
              }
            }).catch(err => {
              console.debug('Load assessment data effect completed loading without org due to err.', err, id)
              setSourceData(formattedAssessment)
              setReload(false)
            })
          } else {
            console.debug('Load assessment data effect completed loading without org.', id)
            setSourceData(formattedAssessment)
            setReload(false)
          }
        }
      })
    return () => {
      console.debug('Load assessment data effect unmounted.', id, cancelSource)
      cancelSource.cancel()
    }
  }
  return () => { console.debug('Unmounted load assessment data effect with no id.') }
}

export default function AssessmentLoader () {
  const { id } = useParams()
  const [reload, setReload] = useState(true)
  const [sourceData, setSourceData] = useState({})

  useEffect(() => {
    if (!reload) {
      console.debug('Reload false - not mounting assessment source data effect.')
      return () => { console.debug('No-op assessment source data effect unmounted.') }
    }
    console.debug('Load assessment data effect mounted.', id, reload)
    const callback = loadAssessmentData(id, setSourceData, setReload)

    return () => callback()
  }, [id, reload, setSourceData, setReload])

  return (
    <div>
      <div key={0}>
        {(reload && id)
          ? null
          : (
          <AssessmentEditor
            key={id}
            id={id}
            sourceData={sourceData}
            sourceQuestions={sourceData.questions ?? []}
            sourceOrganization={sourceData.organization ?? {}}
          />
            ) }
      </div>
      <div key={1}>
        {(reload && id)
          ? (
          <Center>
            <Space h='xl' />
            <Loader variant="dots" />
          </Center>
            )
          : null }
      </div>
    </div>
  )
}
