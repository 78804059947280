import { Button, Group, Modal } from '@mantine/core'
import React, { useContext, useMemo, useState } from 'react'
import ReactTable, { ReactTableContextsProvider } from '../../core/ReactTable/ReactTable'
import { useUsersQuery, useUserTable } from './UserHooks.js'
import { showNotification } from '@mantine/notifications';
import { useDeleteUserMutation } from './UsersApi';
import { NamespaceContext, TableDataContext } from '../../core/ReactTable/TableContexts';
import { useSelector } from 'react-redux';
import { selectParamsLoaded } from '../../core/ReactTable/paramsSlice';
import { useAccount } from '../../util/Hooks'

const namespace = 'users'

export default function UserTable () {
  const account = useAccount()
  const [deleteUser] = useDeleteUserMutation()
  const [userToDelete, setUserToDelete] = useState(false)

  const tableProps = useUserTable(setUserToDelete, account.access)

  const remove = () => {
    deleteUser({ id: userToDelete })
      .unwrap()
      .then(() => {
        showNotification({
          message: 'Successfully deleted user',
          color: 'success'
        })
        setUserToDelete(false)
      })
      .catch(() =>
        showNotification({
          title: 'Something went wrong',
          message: 'There was an error deleting user',
          color: 'red'
        })
      )
  }

  const { columns, defaultFilters, defaultHiddenColumns, ...otherTableProps } = tableProps

  return (
    <>
      <Modal opened={!!userToDelete} onClose={() => setUserToDelete(false)} title='Are you sure you want to delete this user?'>
        <Group spacing='xs'>
          <Button color='red.6' onClick={remove}>Delete</Button>
          <Button color='gray.6' onClick={() => setUserToDelete(false)}>Cancel</Button>
        </Group>
      </Modal>
      <ReactTableContextsProvider
        columns={columns}
        namespace={namespace}
        defaultFilters={defaultFilters}
        defaultHiddenColumns={defaultHiddenColumns}
      >
        <UserTableDataProvider>
          <ReactTable {...otherTableProps} />
        </UserTableDataProvider>
      </ReactTableContextsProvider>
    </>
  )
}

/**
 * @param {JSX.Element} children
 * @returns {JSX.Element}
 */
export function UserTableDataProvider ({ children }) {
  const namespace = useContext(NamespaceContext)
  const loaded = useSelector(state => selectParamsLoaded(state, namespace))
  const [collection, queryRunning] = useUsersQuery(namespace, !loaded)
  const querying = queryRunning || !loaded
  const currentTableDataContext = useMemo(() => {
    console.info('Updating UserTableDataProvider context memo.', { collection, querying })
    return {
      collection,
      querying
    }
  }, [collection, querying])
  console.debug('User table data provider updated.', { collection, querying, namespace })
  return (
    <TableDataContext.Provider value={currentTableDataContext}>
      {children}
    </TableDataContext.Provider>
  )
}
