import { ApiMethods, apiSlice, ApiTags } from '../../../react/api'

export const commentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getComments: builder.query({
      query: id => `applicants/${id}/comments`,
      providesTags: (result, error, id) => [
        { type: ApiTags.Applicants, id: id },
        { type: ApiTags.ApplicantComments, id: id }
      ]
    }),
    postComment: builder.mutation({
      query: ({ applicantId, content, pinned, tags }) => {
        const data = { content: content, pinned: !!pinned, tags: tags ?? [] }
        console.debug('Dispatching addComment action.', { data, applicantId, content, pinned, tags })
        return {
          url: `comments/applicants/${applicantId}/new`,
          method: ApiMethods.Post,
          body: data
        }
      },
      invalidatesTags: (result, error, { applicantId, pinned }) => [
        { type: ApiTags.ApplicantComments, id: applicantId },
        pinned && { type: ApiTags.Applicants, id: applicantId }
      ]
    }),
    updateComment: builder.mutation({
      query: ({ id, applicantId, ...body }) => {
        console.debug('Dispatching updateComment action.', id, body)
        return {
          url: `comments/${id}`,
          method: ApiMethods.Post,
          body: body
        }
      },
      invalidatesTags: (result, error, { id, applicantId }) => [
        { type: ApiTags.ApplicantComments, id: applicantId },
        { type: ApiTags.Applicants, id: applicantId }
      ]
    }),
    deleteComment: builder.mutation({
      query: ({ id, applicantId }) => {
        console.debug('Dispatching deleteComment action.', id)
        return {
          url: `comments/${id}`,
          method: ApiMethods.Delete
        }
      },
      invalidatesTags: (result, error, { id, applicantId }) => [
        { type: ApiTags.ApplicantComments, id: applicantId },
        { type: ApiTags.Applicants, id: applicantId }
      ]
    }),
    setCommentTags: builder.mutation({
      query: ({ id, applicantId, tags }) => {
        console.debug('Dispatching set comment tags action.', { id, applicantId, tags })
        return {
          url: `comments/${id}/tags/set`,
          method: ApiMethods.Post,
          body: { tags }
        }
      },
      invalidatesTags: (result, error, { applicantId }) => [
        { type: ApiTags.ApplicantComments, id: applicantId }
      ]
    }),
    updateCommentTags: builder.mutation({
      query: ({ id, tag }) => {
        console.debug('Dispatching addTag action.', id, tag)
        return {
          url: `comments/${id}/add/tag`,
          method: ApiMethods.Post,
          body: { label: tag }
        }
      },
      invalidatesTags: (result, error, { id, applicantId }) => [
        { type: ApiTags.ApplicantComments, id: applicantId }
      ]
    }),
    removeTag: builder.mutation({
      query: ({ id, tag }) => {
        console.debug('Dispatching removeTag action.', id, tag)
        return {
          url: `comments/${id}/remove/tag`,
          method: ApiMethods.Post,
          body: { label: tag }
        }
      },
      invalidatesTags: (result, error, { id, applicantId }) => [
        { type: ApiTags.ApplicantComments, id: applicantId }
      ]
    })
  })
})

export const {
  useGetCommentsQuery,
  usePostCommentMutation,
  useUpdateCommentMutation,
  useDeleteCommentMutation,
  useSetCommentTagsMutation,
  useUpdateCommentTagsMutation,
  useRemoveTagMutation
} = commentsApiSlice
