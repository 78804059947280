import React from 'react'
import { useParams } from 'react-router-dom'
import FileUpload from './FileUpload'
import {
  useGetApplicantFilesQuery,
  useUploadApplicantFileMutation
} from '../../../redux/query/hire/applicantsApi.slice'
import ErrorLoading from '../../core/ErrorLoading'
import Loading from '../../core/Loading'
import _ from 'lodash'

export default function ApplicantFileUpload () {
  const { applicant_id: applicantId } = useParams()
  const { data: files, isError, isLoading } = useGetApplicantFilesQuery(applicantId)
  const [uploadApplicantFile, { isLoading: processing }] = useUploadApplicantFileMutation()

  if (isError) {
    return <ErrorLoading />
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <FileUpload
      tags={_.union(...files.items.map(file => file.tags))}
      uploadFn={data => uploadApplicantFile({ id: applicantId, ...data }).unwrap()}
      isUploadProcessing={processing}
    />
  )
}
