/* eslint react/prop-types: 0 */
import React, { memo } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { Menu, Button, rem } from '@mantine/core';
import { CyclePassRoute } from '../../../../../js/generated/enums/CyclePassRoute';
import { IconWorld, IconUserCircle } from '@tabler/icons-react';

const iconStyle = { width: rem(14), height: rem(14) }

export const LinksNavButton = memo(function LinksNavButton ({ cycleId }) {
  const createPublicLinkRoute = generatePath(CyclePassRoute.CycleOpenInviteCreate, { cycleId })
  const contactApplicantsRoute = `/cycles/${cycleId}/contact`
  return (
    <Menu shadow="md" width={200} trigger='click-hover' openDelay={100} closeDelay={400} position='right-start' withArrow>
      <Menu.Target>
        <Button>Create</Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          leftSection={<IconWorld style={iconStyle} />}
          component={Link}
          to={createPublicLinkRoute}
        >
          Public Link
        </Menu.Item>
        <Menu.Item
          leftSection={<IconUserCircle style={iconStyle} />}
          component='a'
          href={contactApplicantsRoute}
        >
          Private Invite Link
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
})
