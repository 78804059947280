import React from 'react'
import FileUpload from './FileUpload'
import { useParams } from 'react-router-dom'
import { useGetCycleFilesQuery, useUploadCycleFileMutation } from '../../../redux/query/hire/cyclesApi.slice'
import ErrorLoading from '../../core/ErrorLoading'
import Loading from '../../core/Loading'
import _ from 'lodash'

export default function CycleFileUpload () {
  const { id: cycleId } = useParams()
  const { data: files, isError, isLoading } = useGetCycleFilesQuery(cycleId)
  const [uploadCycleFile, { isLoading: processing }] = useUploadCycleFileMutation()

  if (isError) {
    return <ErrorLoading />
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <FileUpload
      tags={_.union(...files.items.map(file => file.tags))}
      uploadFn={data => uploadCycleFile({ id: cycleId, ...data }).unwrap()}
      isUploadProcessing={processing}
    />
  )
}
