import { ApiAbstractIds, apiSlice, ApiTags } from '../../../react/api'
import { paramsToQuery } from '../../../react/util/queries'

export const accountsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getLoggedInAccount: builder.query({
      query: () => 'accounts/my-account',
      providesTags: (result, error, id) => [{ type: ApiTags.Accounts, id: result.id }, { type: ApiTags.Accounts, id: 'my-account' }]
    }),
    getMyAccountList: builder.query({
      query: () => 'accounts/my-account-list',
      providesTags: (result) => [{ type: ApiTags.Accounts, id: result.id }, { type: ApiTags.Accounts, id: 'my-account' }]
    }),
    getAccounts: builder.query({
      query: (params = { limit: 0 }) => `accounts${paramsToQuery(params)}`,
      providesTags: (result) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.Accounts, id: id })),
              { type: ApiTags.Accounts, id: ApiAbstractIds.PartialList }
            ]
          : [{ type: ApiTags.Accounts, id: ApiAbstractIds.PartialList }]
    })
  })
})

export const { useGetLoggedInAccountQuery, useGetMyAccountListQuery, useGetAccountsQuery } = accountsApi
