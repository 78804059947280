/* eslint react/prop-types: 0 */
import { useController } from 'react-hook-form'
import { Select, Stack } from '@mantine/core'
import React, { useContext, useMemo } from 'react'
import Error from '../../../../forms/Error'
import { formatToOptions } from '../../../../../js/util/DataUtil'
import LinkedBatteriesContext from '../../../../../contexts/LinkedBatteriesContext'
import _ from 'lodash'

export default function BatterySelect ({ index }) {
  const linkedBatteries = useContext(LinkedBatteriesContext)
  const { field } = useController({
    name: `metadata.emails.${index}.email_program`,
    defaultValue: null,
    rules: { required: true, setValueAs: value => value?.toString() }
  })

  const batteryOptions = useMemo(() => {
    return formatToOptions(_.map(_.filter(linkedBatteries, { active: true }), linkedBattery => ({ id: linkedBattery.cycle.id, name: linkedBattery.cycle.name })), { label: 'name', value: 'id' })
  }, [linkedBatteries])

  return (
    <Stack>
      <Select
        name={name}
        placeholder='Email Programs'
        data={batteryOptions}
        searchable
        {...field}
      />
      <Error name={`metadata.emails.${index}.email_program`} customMessage={'An email program must be selected'}/>
    </Stack>
  )
}
