import React from 'react'
import hirescoreLogo from '../../images/hirescore_grey_notagline.svg'
import { ActionIcon, Anchor, Center, Container, Group, Image } from '@mantine/core'
import { IconChevronsLeft } from '@tabler/icons-react'
import * as classes from './Logo.module.scss'

/**
 * This component renders the HireScore logo and the collapse side menu button.
 * @returns {JSX.Element}
 */
function Logo () {
  return (
    <Container className={classes.root}>
      <Group justify='center' gap='xs'>
        <Center w={175} h={50}>
          <Anchor href='/'>
            <Image src={hirescoreLogo} h='auto' w={160} alt='HireScore logo'/>
          </Anchor>
        </Center>
        <Center w={30} h={50}>
          <ActionIcon variant='subtle' aria-label='Collapse sidebar' mt={5} onClick={() => window.menuAPI.close()}>
            <IconChevronsLeft color='white' />
          </ActionIcon>
        </Center>
      </Group>
    </Container>
  )
}

export default Logo
