/* eslint react/prop-types: 0 */
import React, { memo, useContext, useEffect, useRef } from 'react'
import { useBoostQuery, useBoostTable, useLazyBoostQuery } from './BoostHooks';
import { showNotification } from '@mantine/notifications';
import { createFormActions } from '@mantine/form';
import {
  defaultTableRowContext,
  NamespaceContext,
  TableQueryingContext,
  TableRowContext,
  TableRowIdsContext
} from '../../../core/ReactTable/TableContexts';
import { useDispatch, useSelector } from 'react-redux';
import { selectParamsLoaded } from '../../../core/ReactTable/paramsSlice';
import { useGetLoggedInAccountQuery } from '../../../../redux/query/account/accountsApi.slice';
import { CleanTable, CleanTableContextsProvider } from '../../../core/ReactTable/CleanTable';
import { rowsLoaded, selectTableRowIdsByActiveRequestId } from '../../../core/ReactTable/tableRowsSlice';
import { useGetCycleQuery } from '../../../../redux/query/hire/cyclesApi.slice';
import Loading from '../../../core/Loading';
import ErrorLoading from '../../../core/ErrorLoading';
import { BoostSelectedHandler } from './BoostSelectedHandler';
import { useSpringGestures } from '../../../core/useSpringGestures';

export const BoostTableLoader = memo(function BoostTableLoader ({ cycleId, namespace = 'boost-invites' }) {
  const { data: account, error: accountError, isFetching: queryingAccount } = useGetLoggedInAccountQuery()
  const { data: cycle, error: cycleError, isFetching: queryingCycle } = useGetCycleQuery(cycleId)

  const anyLoading = queryingAccount || queryingCycle
  const anyError = accountError || cycleError

  if (anyLoading) {
    return <Loading />
  }

  if (anyError) {
    return <ErrorLoading />
  }

  return <BoostTable account={account} cycle={cycle} namespace={namespace} />
})

/**
 *
 * @param {number|string} job
 * @param {string} [namespace]
 * @returns {JSX.Element}
 */
const BoostTable = memo(function BoostTable ({ account, cycle, namespace = 'boost-invites' }) {
  /** @member {BoostCollection} collection */
  const [collection,, error] = useLazyBoostQuery(namespace, cycle.id)
  const formFiltersAction = createFormActions('react-table-form-filters')
  const formFiltersActionRef = useRef(formFiltersAction)

  useEffect(() => {
    if (collection?.reformattedAddress) {
      formFiltersActionRef.current.setFieldValue('address', collection.reformattedAddress)

      showNotification({
        title: 'Address Formatting',
        message: `The address was reformatted to: ${collection.reformattedAddress}`,
        color: 'blue',
        autoClose: 3000
      })
    }
  }, [collection])

  useEffect(() => {
    if (error !== null) {
      switch (error.status) {
        case 400:
          showNotification({
            title: 'Address Lookup Error',
            message: error.data.errors.join(),
            color: 'red',
            autoClose: 7000
          })
          break
        default:
          showNotification({
            title: 'Something went wrong',
            message: 'There was an error searching for applicants',
            color: 'red',
            autoClose: 7000
          })
      }
    }
  }, [error])

  const { columns, defaultFilters, defaultHiddenColumns, ...otherParams } = useBoostTable(account, cycle.job, () => {})

  useSpringGestures()

  return (
    <>
      <CleanTableContextsProvider
        columns={columns}
        namespace={namespace}
        defaultFilters={defaultFilters}
        defaultHiddenColumns={defaultHiddenColumns}
      >
        <BoostTableDataProvider id={cycle.id}>
          <CleanTable
            { ...otherParams }
            columns={columns}
          />
          <BoostSelectedHandler/>
        </BoostTableDataProvider>
      </CleanTableContextsProvider>
    </>
  )
})

/**
 * @typedef {object} BoostCollection
 * @property {array} items
 * @property {number} limit
 * @property {number} page
 * @property {number} total
 * @property {string} reformattedAddress
 */

export function BoostTableDataProvider ({ id, children }) {
  const namespace = useContext(NamespaceContext)
  const dispatch = useDispatch()

  const loaded = useSelector(state => selectParamsLoaded(state, namespace))
  const rowIds = useSelector(state => selectTableRowIdsByActiveRequestId(state, namespace))

  const { collection, querying, requestId } = useBoostQuery(namespace, id, !loaded)
  const isQuerying = querying || !loaded

  useEffect(() => {
    if (requestId && collection && !isQuerying) {
      dispatch(rowsLoaded({ namespace: namespace, data: collection, requestId: requestId }))
    }
  }, [requestId, collection, isQuerying, dispatch, namespace]);

  console.debug('Boost table data provider updated.', { rowIds, collection, isQuerying, namespace, requestId })
  return (
    <TableRowIdsContext.Provider value={rowIds}>
      <TableRowContext.Provider value={defaultTableRowContext}>
        <TableQueryingContext.Provider value={isQuerying}>
          {children}
        </TableQueryingContext.Provider>
      </TableRowContext.Provider>
    </TableRowIdsContext.Provider>
  )
}
