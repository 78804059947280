import axios from 'axios'

/**
 * This function allows to download files.
 * @param {string} url the url of the endpoint to get the file from
 * @param {string} filename the name of the file to be exported
 * @param {string} [method] http method. Defaults to "get".
 * @returns {Promise<void>} the promise returned from axios
 */
export const downloadFile = (url, filename, method = 'get') => {
  const controller = new AbortController();
  const signal = controller.signal

  return axios({
    method: method,
    url: url,
    responseType: 'arraybuffer',
    signal: signal
  })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
      URL.revokeObjectURL(url)
    })
}
