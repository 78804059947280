/* eslint react/prop-types: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
import { Box, Button, Center, Group, Loader, Paper, Radio, SimpleGrid, TextInput, Title } from '@mantine/core'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import * as DOMPurify from 'dompurify'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import EmailEditor from '../../core/email/EmailEditor'
import DateSelection from './DateSelection'
import TemplateSelection from './TemplateSelection'
import { usePreviewEmailTemplateQuery } from '../../../redux/query/hire/applicantsApi.slice';
import { ContactStateUpdate } from './ContactState';
import { ContactDispatchContext } from './ContactContexts';
import { useUpdateOnDebounceOrSubmitField } from '../../forms/UpdateOnSubmitField';
import Loading from '../../core/Loading';
import QueryErrorAlert from '../../core/Alert/QueryErrorAlert';

dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)

export default function Email ({ message, form, index, templates, previewApplicant }) {
  const [preview, setPreview] = useState(false)
  const { data: previewData, isError: previewError, isFetching: previewLoading, refetch: retryPreview } = usePreviewEmailTemplateQuery(
    { applicantId: previewApplicant, subject: message.subject, body: message.body },
    { skip: !previewApplicant || !preview }
  )

  return (
    <Paper shadow='sm' p='lg' radius='md' withBorder aria-label={`Message #${index + 1}`}>
      <SimpleGrid cols={1}>
        <Title order={6} c='dimmed'>Email</Title>
        <Box display={!preview ? 'block' : 'none'}>
          <Editor
            message={message}
            previewApplicant={previewApplicant}
            setPreview={setPreview}
            preview={preview}
            form={form}
            index={index}
            templates={templates}
          />
        </Box>
        <Box display={preview ? 'block' : 'none'} pos='relative'>
          {!!previewLoading && (
            <Loader>
              <Box pos='relative'>
                <Loading />
              </Box>
              <Center>
                <Button onClick={() => setPreview(false)}>
                  Cancel Preview
                </Button>
              </Center>
            </Loader>
          )}
          {!!previewError && !previewLoading && (
            <QueryErrorAlert
              title='Preview Error!'
              onClick={retryPreview}
              loading={previewLoading}
              onClose={() => setPreview(false)}
            >
              Error loading preview for applicant.
            </QueryErrorAlert>)
          }
          {!previewError && !previewLoading && (
            <Preview
              messageId={message.id}
              setPreview={setPreview}
              previewSendAt={message.send_at}
              previewSubject={previewData?.data?.subject}
              previewBody={previewData?.data?.body}
            />
          )}
        </Box>
      </SimpleGrid>
    </Paper>
  )
}

function Editor ({ message, previewApplicant, preview, setPreview, form, index, templates }) {
  const dispatch = useContext(ContactDispatchContext)
  const formTemplateId = message.template
  const template = useMemo(() => {
    if (!formTemplateId) {
      return null
    }
    const intTemplateId = parseInt(formTemplateId)
    return templates.filter(template => template.id === intTemplateId).pop() ?? null
  }, [formTemplateId, templates])

  const updateSubject = useCallback((value) => {
    dispatch({ type: ContactStateUpdate.EditMessage, messageId: message.id, field: 'subject', value: value })
  }, [message.id, dispatch])

  const subjectProps = useUpdateOnDebounceOrSubmitField(message.subject, updateSubject, !!preview)

  const updateBody = useCallback((value) => {
    dispatch({ type: ContactStateUpdate.EditMessage, messageId: message.id, field: 'body', value: value })
  }, [message.id, dispatch])

  const bodyProps = useUpdateOnDebounceOrSubmitField(message.body, updateBody, !!preview)

  return (
    <SimpleGrid cols={1}>
      <Group spacig='xs'>
        <Button size='xs' variant='light' color='blue' disabled={!previewApplicant} onClick={() => setPreview(true)}>Preview</Button>
        <Button size='xs' variant='light' color='red' onClick={() => dispatch({ type: ContactStateUpdate.RemoveMessage, messageId: message.id })}>Remove</Button>
      </Group>
      <Date messageId={message.id} messageSendAt={message.send_at} form={form} index={index} />
      <TemplateSelection messageId={message.id} messageTemplate={message.template} form={form} index={index} templates={templates} />
      <TextInput label='Subject' withAsterisk {...form.getInputProps(`messages.${index}.subject`)} {...subjectProps} />
      <EmailEditor
        onUpdate={bodyProps.onChange}
        onBlur={bodyProps.onBlur}
        content={bodyProps.value}
        errors={form.errors[`messages.${index}.body`]}
        category={template?.category?.id}
        platform={0}
      />
    </SimpleGrid>
  )
}

function Date ({ messageId, messageSendAt, form, index }) {
  const dispatch = useContext(ContactDispatchContext)

  const toggleSendNow = (value) => {
    dispatch({
      type: ContactStateUpdate.EditMessageSendDate,
      messageId: messageId,
      value: value === 'immediately' ? null : dayjs().add(1, 'day').toDate()
    })
  }

  return (
    <>
      <SimpleGrid cols={1}>
        <Radio.Group
          name={`send-now.${index}`}
          label='When do you want to send this message?'
          value={messageSendAt === null ? 'immediately' : 'later'}
          onChange={(value) => toggleSendNow(value)}
        >
          <Radio value='immediately' label='Send immediately' style={{ marginBottom: '5px' }}/>
          <Radio value='later' label='Send later'/>
        </Radio.Group>
        {
          (messageSendAt !== null) &&
          <DateSelection messageId={messageId} messageSendAt={messageSendAt} form={form} index={index} />
        }
      </SimpleGrid>
    </>
  )
}

function Preview ({ messageId, setPreview, previewSendAt, previewSubject, previewBody }) {
  const dispatch = useContext(ContactDispatchContext)

  const subject = useMemo(() => {
    return DOMPurify.sanitize(previewSubject ?? '')
  }, [previewSubject])

  const body = useMemo(() => {
    return DOMPurify.sanitize(previewBody ?? '')
  }, [previewBody])

  return (
    <SimpleGrid cols={1}>
      <Group spacig='xs'>
        <Button size='xs' variant='light' color='blue' onClick={() => setPreview(false)}>Edit</Button>
        <Button size='xs' variant='light' color='red' onClick={() => dispatch({ type: ContactStateUpdate.RemoveMessage, messageId: messageId })}>Remove</Button>
      </Group>
      <h4>Send At</h4>
      <div style={{ marginBottom: '20px' }}>{previewSendAt ? dayjs(previewSendAt).format('MM/DD/YYYY') : 'Immediately'}</div>
      <h4>Subject</h4>
      <div style={{ marginBottom: '20px' }} dangerouslySetInnerHTML={{ __html: subject }}></div>
      <h4>Body</h4>
      <div dangerouslySetInnerHTML={{ __html: body }}></div>
    </SimpleGrid>
  )
}
