import React from 'react'
import Menu from './Menu'

/**
 * This component unserializes the menu from the react div.
 * @returns {JSX.Element}
 */
function MenuWrapper () {
  const menu = JSON.parse(document.getElementById('react-sidebar-container').getAttribute('data-menu'))
  return <Menu menu={menu} />
}

export default MenuWrapper
