import React from 'react'
import FileHome from './FileHome'
import { useParams } from 'react-router-dom'
import ErrorLoading from '../../core/ErrorLoading'
import Loading from '../../core/Loading'
import { useGetCycleFilesQuery } from '../../../redux/query/hire/cyclesApi.slice'

export default function CycleFileHome () {
  const { id: cycleId } = useParams()
  const { data: files, isError, isLoading } = useGetCycleFilesQuery(cycleId)

  if (isError) {
    return <ErrorLoading />
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <FileHome
      files={files.items}
      cycleId={cycleId}
    />
  )
}
