import { Box, Checkbox, Stack, Text } from '@mantine/core'
import React, { memo, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAllSelectedKeys,
  selectBulk, selectOne,
  unselectBulk, unselectOne
} from './tableSelectedRowsSlice';
import { NamespaceContext } from './TableContexts';
import { selectTableRowIdsByActiveRequestId, selectTableRowsByActiveRequestId } from './tableRowsSlice';

/**
 * Renders a selectable column for the ReactTable. State is managed with redux. Currently, the accessor for the
 * ReactTable column will be overwritten to `id`.
 *
 * ```
 * return useMemo(
 *   () => [
 *     SelectableColumn({
 *       selectAriaLabel: 'Select applicant',
 *       selectAllAriaLabel: 'Select all applicants',
 *       id: 'checkbox',
 *       accessor: 'id',
 *       metadata: {
 *         isGranted: account.access.ADMIN
 *       }
 *     }),
 * ...
 * ], [])
 * ```
 * @param {string} [selectAriaLabel] - Accessibility label for each rows' checkbox
 * @param {string} [selectAllAriaLabel] - Accessibility label for the select all checkbox
 * @param props Additional props used by the ReactTable columns (accessor, id, metadata, etc.)
 */
export default function SelectableColumn ({
  selectAriaLabel = 'Select this row',
  selectAllAriaLabel = 'Select all rows',
  ...props
}) {
  return {
    Header: <SelectAllHeader selectAllAriaLabel={selectAllAriaLabel} />,
    Cell: ({ cell: { value, row } }) => <SelectOneCell value={value.toString()} row={row} selectAriaLabel={selectAriaLabel} />,
    width: '1rem',
    ...props,
    accessor: 'id'
  }
}

const SelectAllHeader = memo(function SelectAllHeader ({ selectAllAriaLabel }) {
  const namespace = useContext(NamespaceContext)
  const rowIds = useSelector(state => selectTableRowIdsByActiveRequestId(state, namespace))
  const selected = useSelector((state) => selectAllSelectedKeys(state, namespace))
  const keys = rowIds.map(rowId => rowId.toString())
  const checked = selected.length > 0 && keys.every(key => selected.includes(key))
  const indeterminate = selected.length > 0 && keys.some(key => selected.includes(key))

  console.debug('New select all header component update', { namespace, rowIds, selected, checked, indeterminate })

  return (
    <SelectAllColumn
      count={selected?.length}
      ariaLabel={selectAllAriaLabel}
      checked={checked}
      indeterminate={indeterminate && !checked}
    />
  )
})

const SelectAllColumn = memo(function NewSelectAllColumn ({ count, checked, indeterminate, ariaLabel }) {
  const dispatch = useDispatch()
  const namespace = useContext(NamespaceContext)
  const rows = useSelector(state => selectTableRowsByActiveRequestId(state, namespace))
  console.debug('NewSelectAllColumn updated', { rows, namespace })

  return (
    <Stack>
      {!!count && (
        <Box pos='relative' w='100%' />
      )}
      <Checkbox
        checked={checked}
        indeterminate={indeterminate}
        aria-label={ariaLabel}
        onClick={(event) => { event.stopPropagation() }}
        onChange={(event) =>
          event.target.checked
            ? dispatch(selectBulk({
              rows,
              namespace
            }))
            : dispatch(unselectBulk({
              namespace: namespace,
              keys: rows.map(row => row.id.toString())
            }))
        }
      />
      {!!count && (
        <Box pos='relative' w='100%'>
          {!!count && <Text ta='center' span lh={0} top={0} left={0} right={0} w='auto' my={0} py={0} pos='absolute'>({count})</Text>}
        </Box>
      )}
    </Stack>
  )
})

const SelectOneCell = memo(function SelectOneCell ({ value, row, selectAriaLabel }) {
  const namespace = useContext(NamespaceContext)
  const selected = useSelector((state) => selectAllSelectedKeys(state, namespace))

  console.debug('NewSelectOneCell updated', { value, row, namespace })

  return (
    <SelectOneColumn
      id={value}
      row={row.original}
      checked={selected.includes(value)}
      ariaLabel={selectAriaLabel}
    />
  )
})

const SelectOneColumn = memo(function SelectOneColumn ({ id, row, checked, ariaLabel }) {
  const dispatch = useDispatch();
  const namespace = useContext(NamespaceContext);

  console.debug('NewSelectOneColumn updated', { row, checked, namespace })

  return (
    <Checkbox
      checked={checked}
      aria-label={ariaLabel}
      onClick={(event) => { event.stopPropagation() }}
      onChange={(event) => event.target.checked
        ? dispatch(selectOne({
          id,
          row,
          namespace
        }))
        : dispatch(unselectOne({
          id,
          namespace
        }))
      }
    />
  )
})
