import { ConfigStars } from './configs/Stars';
import { ConfigBubbles } from './configs/Bubbles';
import { ConfigSunrise } from './configs/Sunrise';
import { ConfigPolygons } from './configs/Polygons';
import { ConfigDefaultLinks } from './configs/Default';
import { InviteStyle } from '../../../../../js/generated/enums/InviteStyle';
import { ThankYouStyle } from '../../../../../js/generated/enums/ThankYouStyle';
import { FlagDisplay, isDisplayable } from '../../../../../js/generated/enums/FlagDisplay';
import { IconBrandGoogle, IconBrandLinkedin, IconHeart, IconLocation } from '@tabler/icons-react';
import React from 'react';

export const inviteStyleOptions = Object.freeze(
  Object.entries(InviteStyle)
    .map(([key, value]) => ({ label: key, value: value.toString() }))
)

export function getAnimationConfigFromInviteStyle (style) {
  console.debug('Getting animation config from style.', style)
  switch (style) {
    case InviteStyle.Stars:
      return ConfigStars
    case InviteStyle.Bubbles:
      return ConfigBubbles
    case InviteStyle.Sunrise:
      return ConfigSunrise
    case InviteStyle.Polygons:
      return ConfigPolygons
    case InviteStyle.Skip:
      return null;
    default:
      return ConfigDefaultLinks
  }
}

export const thankYouStyleOptions = Object.freeze(
  Object.entries(ThankYouStyle)
    .map(([key, value]) => ({ label: key.split(/(?=[A-Z])/).join(' '), value: value.toString() }))
)

export const applicantFlagIconOptions = Object.freeze(
  Object.entries(FlagDisplay)
    .filter(([key, value]) => isDisplayable(value))
    .map(([key, value]) => ({ label: key, value: value }))
)

export function getReactIconForFlagDisplayClass (flagDisplayClass) {
  switch (flagDisplayClass) {
    case FlagDisplay.Heart:
      return <IconHeart />
    case FlagDisplay.LinkedIn:
      return <IconBrandLinkedin />
    case FlagDisplay.Google:
      return <IconBrandGoogle />
    default:
      return <IconLocation />
  }
}
