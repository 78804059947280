/* eslint react/prop-types: 0 */
import React, { useMemo, useState } from 'react';
import { generatePath, Link, useParams } from 'react-router-dom';
import {
  Alert, Badge,
  Box,
  Button,
  Center,
  Collapse,
  Group,
  Loader,
  Skeleton,
  Space,
  Stack,
  Text,
  Timeline
} from '@mantine/core';
import { CyclePassRoute } from '../../../../../js/generated/enums/CyclePassRoute';
import { IconBan, IconCheck, IconHourglassHigh, IconInfoCircle, IconX } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { CycleStagesTable } from './CycleStagesTable';
import { renderDateFromSource } from '../formatUtil';
import { CyclePageTitle } from '../nav/CyclePageTitle';
import { useCycle } from '../../CycleHooks';
import { useCycleActiveStages } from './CycleStagesHooks';
import { LockableColor } from '../summaries/LockableIcon';
import { useParsedPassActivity } from '../CycleInviteHooks';
import { CyclePassActivityType, isAssessmentStart } from '../../../../../js/generated/enums/CyclePassActivityType';
import { isClosed } from '../../../../../js/generated/enums/StageProgressStatus';
import { stageProgressStatusIdToName } from '../progresses/util';
import { PassActivityTabs } from '../activity/PassActivityTabs';
import { useIsAdmin } from '../CyclesHooks';
import { DiagnosticsNavButtonTabs } from '../nav/DiagnosticsNavButton';

export function CycleStages () {
  const { cycleId } = useParams()
  const [cycle, cycleQuerying] = useCycle(cycleId)
  const [stages, stagesQuerying] = useCycleActiveStages(cycleId)
  const [isAdmin, adminLoading] = useIsAdmin()
  const querying = !!(cycleQuerying || stagesQuerying || adminLoading)
  console.debug('Cycle stages updating', { cycleId, cycle, stages, isAdmin, querying })
  return (
    <>
      <CyclePageTitle cycleId={cycleId} pageName='Stages' active={DiagnosticsNavButtonTabs.Stages} />
      {!!querying && <CycleStagesCardPlaceholder />}
      {!querying && !(cycle || stages) && <CycleStagesCardError />}
      {!querying && !!(cycle && stages) && <CycleStagesCard cycleId={cycleId} cycle={cycle} stages={stages} isAdmin={isAdmin} />}
      {!querying && !!(cycle && stages) && <CycleStagesTableToggle cycleId={cycleId} />}
    </>
  )
}

function CycleStagesCardPlaceholder () {
  return (
    <Box>
      <Space h='md' />
      <Stack justify='flex-start'>
        <Skeleton height={12} radius="xl" miw='40rem' w='100%' />
        <Skeleton height={12} mt={10} radius="xl" miw='40rem' w='100%' />
        <Skeleton height={12} mt={10} miw='24rem' w="60%" radius="xl" />
        <Skeleton height={12} mt={10} radius="xl" miw='40rem' w='100%' />
        <Skeleton height={12} mt={10} radius="xl" miw='40rem' w='100%' />
        <Skeleton height={12} mt={10} miw='24rem' w="60%" radius="xl" />
      </Stack>
      <Space h='xl' />
      <Center>
        <Loader type="bars" />
      </Center>
    </Box>
  )
}

function CycleStagesCard ({ cycleId, cycle, stages, isAdmin = false }) {
  console.debug('Cycle stages card updating', { cycleId, cycle, stages })
  const numberActiveStages = stages.items?.length ?? 0

  return (
    <>
      <Space h='lg' />
      <Box>
        {numberActiveStages ? <CycleStagesSummary cycle={cycle} stages={stages.items} isAdmin={isAdmin} /> : <CycleNoStagesSummary cycle={cycle} />}
      </Box>
    </>
  )
}

function CycleStagesSummary ({ cycle, stages, isAdmin = false }) {
  return (
    <Timeline active={stages.length - 1} bulletSize={24} lineWidth={stages.length - 1 ? 2 : 0}>
      {stages.map((stage) => (
        <Timeline.Item
          key={stage.id}
          bullet={stage.locked ? <IconBan size={12} strokeWidth={4} /> : <IconHourglassHigh size={12} />}
          title={`#${(stage.index + 1)} ${(stage.assessment?.name ?? 'Assessment Missing')}`}
        >
          <CycleStageSummaryTimelineItem stage={stage} cycle={cycle} isAdmin={isAdmin} />
        </Timeline.Item>
      ))}
    </Timeline>
  )
}

export function passMetadataCallback (parsedMetadata, activity) {
  if (activity.type === CyclePassActivityType.SubmitAssessment) {
    const submissions = parsedMetadata.submissions ?? new Map()
    const passSubmissions = submissions.get(activity.cycle_pass.id) ?? []
    passSubmissions.push(activity)
    submissions.set(activity.cycle_pass.id, passSubmissions)
    parsedMetadata.submissions = submissions
  } else if (isAssessmentStart(activity.type)) {
    const starts = parsedMetadata.starts ?? new Map()
    const passStarts = starts.get(activity.cycle_pass.id) ?? []
    passStarts.push(activity)
    starts.set(activity.cycle_pass.id, passStarts)
    parsedMetadata.starts = starts
  } else {
    const extra = parsedMetadata.extra ?? new Map()
    const passExtra = extra.get(activity.cycle_pass.id) ?? []
    passExtra.push(activity)
    extra.set(activity.cycle_pass.id, passExtra)
    parsedMetadata.extra = extra
  }
}

function CycleStageSummaryTimelineItem ({ stage, cycle, isAdmin }) {
  const [open, setOpen] = useState(false)
  const [firstStart, lastActivity, lastSubmit, metadata] = useParsedPassActivity(stage, passMetadataCallback)
  const stageDetailLink = generatePath(CyclePassRoute.CycleStageDetail, { cycleId: cycle.id, stageId: stage.id })
  return (
    <>
      <Button variant='subtle' size='sm' onClick={() => setOpen((prev) => !prev)}>{open ? 'Hide Stage Details' : 'Stage Details'}</Button>
      <Collapse in={open}>
        <Space h='xs' />
        {!!lastActivity && (
          <Text c="dimmed" size="sm">
            {firstStart ? `First Start: ${renderDateFromSource(firstStart.created_at, null, true)} ` : ''}{lastSubmit ? `Last Submitted: ${renderDateFromSource(lastSubmit.created_at, null, true)} ` : ''} Last Activity Seen: {renderDateFromSource(lastActivity.created_at, null, true)}
          </Text>
        )}
        {!lastActivity && (
          <Text c="dimmed" size="sm">
            No activity for stage
          </Text>
        )}
        <Text c="dimmed" size="sm">
          <br /><br />
          <Link to={stageDetailLink}>Visit Stage Details Page</Link>
        </Text>
        {!!metadata?.starts?.size && <StageActivitySummary stage={stage} cycle={cycle} metadata={metadata} isAdmin={isAdmin} />}
        <Space h='xs' />
        <PassActivityTabs activityTarget={stage} activityTargetType='Stage' isAdmin={isAdmin} />
        <Space h='xl' />
      </Collapse>
      <Space h='xs' />
      <Text size="xs" mt={4}>Last Updated - {dayjs(stage.updated_at).format('MM-DD-YYYY')}</Text>
    </>
  )
}

export function StageActivitySummary ({ stage, cycle, metadata, isAdmin }) {
  const [open, setOpen] = useState(false)
  const cyclePassSubmissions = metadata?.submissions ?? null
  const cyclePassStarts = metadata?.starts ?? null
  const cyclePassExtra = metadata?.extra ?? null
  const cyclePassIds = useMemo(() => {
    return [...(cyclePassStarts?.keys() ?? [])]
  }, [cyclePassStarts])
  const stageProgresses = stage.stage_progresses
  const cyclePassProgresses = useMemo(() => {
    const parsedProgresses = new Map()
    for (const progress of stageProgresses) {
      parsedProgresses.set(progress.cycle_pass.id, progress)
    }
    return parsedProgresses
  }, [stageProgresses])
  return (
    <>
      <Button variant='subtle' size='sm' onClick={() => setOpen((prev) => !prev)}>{open ? 'Hide Events' : 'Events'}</Button>
      <Collapse in={open}>
        <Space h='xs' />
        <Timeline active={cyclePassIds.length - 1} bulletSize={24} lineWidth={cyclePassIds.length - 1 ? 2 : 0}>
          {cyclePassIds.map((passId) => {
            const progress = cyclePassProgresses.get(passId) ?? null
            const starts = cyclePassStarts?.get(passId) ?? []
            const submits = cyclePassSubmissions?.get(passId) ?? []
            const extras = cyclePassExtra?.get(passId) ?? []
            return (
                <Timeline.Item
                  key={passId}
                  bullet={(progress && isClosed(progress.status)) ? <IconCheck size={12} strokeWidth={4} /> : (progress?.active ? <IconHourglassHigh size={12} /> : <IconX size={12} />)}
                  title={(stage.assessment?.name ?? 'Assessment Missing') + ' Submission Attempt'}
                >
                  <StageActivitySummaryTimelineItem
                    passId={passId}
                    progress={progress}
                    starts={starts}
                    submits={submits}
                    extras={extras}
                    cycleId={cycle.id}
                    isAdmin={isAdmin}
                  />
                </Timeline.Item>
            )
          })}
        </Timeline>
        <Space h='xs' />
      </Collapse>
    </>
  )
}

function StageActivitySummaryTimelineItem ({ passId, progress, starts, submits, extras, cycleId, isAdmin }) {
  const [open, setOpen] = useState(false)
  const cyclePassDetailLink = generatePath(CyclePassRoute.CyclePassDetail, { cycleId, passId })
  console.debug('Generated path for submission\'s associated cycle pass details.', { cyclePassDetailLink, starts, submits })
  const firstStart = starts?.[0] ?? null
  const lastSubmit = submits?.[Math.max(submits.length - 1, 0)] ?? null
  return (
    <>
      <Button variant='subtle' size='sm' onClick={() => setOpen((prev) => !prev)}>{open ? 'Hide Event Details' : 'Event Details'}</Button>
      <Collapse in={open}>
        <Space h='xs' />
        {!!(firstStart || lastSubmit) && (
          <Text c="dimmed" size="sm">
            {firstStart ? `Started: ${renderDateFromSource(firstStart.created_at, null, true)} ` : ''}{lastSubmit ? `Submitted: ${renderDateFromSource(lastSubmit.created_at, null, true)} ` : ''}
          </Text>
        )}
        {!(firstStart || lastSubmit) && (
          <Text c="dimmed" size="sm">
            No valid starts or submits for stage
          </Text>
        )}
        <Text c="dimmed" size="sm">
          <br /><br />
          <Link to={cyclePassDetailLink}>Visit Passport Details Page</Link>
        </Text>
        <Space h='xs' />
      </Collapse>
      <Group>
        <Badge color={isClosed(progress.status) ? LockableColor.Locked : LockableColor.Active} variant='light'>{stageProgressStatusIdToName[progress.status]}</Badge>
        <Text size="xs" mt={4}>Updated {renderDateFromSource(progress.updated_at, null, true)}</Text>
      </Group>
    </>
  )
}

function CycleNoStagesSummary ({ cycle }) {
  return (
    <Alert variant='light' title='No Stage Data' icon={<IconInfoCircle />}>
      {`This cycle was created on ${dayjs(cycle.created_at).format('MM-DD-YYYY')}, and was last edited ${dayjs(cycle.updated_at).format('MM-DD-YYYY')}. It has no stages!`}
    </Alert>
  )
}

function CycleStagesCardError () {
  return (
    <Alert variant='light' title='Query Error' icon={<IconInfoCircle />}>
      Oh no! There was a query error. Try refreshing the page while we work on a way to fix it!
    </Alert>
  )
}

function CycleStagesTableToggle ({ cycleId }) {
  const [open, setOpen] = useState(false)
  const [showButton, setShowButton] = useState(!open)
  return (
    <>
      <Space h='xl' />
      <Collapse in={open} onTransitionEnd={() => setShowButton(!open)}>
        <CycleStagesTable cycleId={cycleId} />
      </Collapse>
      {!!showButton && <Button onClick={() => setOpen(true)}>View Stages Table</Button>}
    </>
  )
}
