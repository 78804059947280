/* eslint react/prop-types: 0 */
import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { QuestionStateUpdate } from './QuestionsState';
import { getQuestionContentIsNewPlaceholder } from './util';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import { Highlight as HighlightExtension } from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';
import Color from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import { useEditor } from '@tiptap/react';
import { RichTextEditor } from '@mantine/tiptap';
import { IconColorPicker } from '@tabler/icons-react';
import { PlaceholdersControl } from '../../../core/PlaceholdersControl';

export function useQuestionEditor ({ questionId, content, dispatch }) {
  const timeoutId = useRef(null)
  const questionIdRef = useRef(questionId)
  const contentRef = useRef(content)
  const dispatchRef = useRef(dispatch)
  const defaultContentRef = useRef(null)

  console.debug('Question editor updating', questionId, content)

  const onBlur = useCallback(({ editor, event }) => {
    const currentContent = editor?.getHTML()
    console.debug('Edit onBlur updating question content.', currentContent)
    if (currentContent !== undefined) {
      if (!defaultContentRef.current || currentContent !== '<p></p>') {
        window.clearTimeout(timeoutId.current)
        defaultContentRef.current = null
        dispatchRef.current({
          type: QuestionStateUpdate.UpdateQuestionContent,
          questionId: questionIdRef.current,
          newContent: currentContent
        })
      } else {
        editor?.commands.setContent(defaultContentRef.current ?? '<p></p>', false)
      }
    }
  }, [])
  const onUpdate = useCallback(({ editor }) => {
    const currentContent = editor?.getHTML()
    window.clearTimeout(timeoutId.current)
    timeoutId.current = window.setTimeout(() => {
      const newCurrentContent = editor?.getHTML()
      console.debug('Edit onUpdate timeout callback checking whether to update question content.', currentContent, newCurrentContent)
      if ((currentContent !== undefined) && (currentContent === newCurrentContent)) {
        console.debug('Edit onUpdate timeout callback triggering update.', currentContent)
        dispatchRef.current({
          type: QuestionStateUpdate.UpdateQuestionContent,
          questionId: questionIdRef.current,
          newContent: currentContent
        })
      }
    }, 3000);
  }, [])

  const onCreate = useCallback(({ editor }) => {
    console.debug('Editor on create called.', questionId, questionIdRef.current, content, editor.getHTML())
    if (questionId) {
      editor?.commands.setContent(content, false)
    }
  }, [questionId, content])

  const onFocus = useCallback(({ editor }) => {
    console.warn('On editor focus called for question.', questionId)
    const existingContent = editor?.getHTML()
    if (getQuestionContentIsNewPlaceholder(existingContent)) {
      console.warn('Was default question content - clearing.', existingContent)
      defaultContentRef.current = existingContent
      editor.commands.clearContent()
    } else {
      defaultContentRef.current = null
    }
  }, [questionId])

  const extensions = useMemo(() => {
    return [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      HighlightExtension,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      Color,
      TextStyle
    ]
  }, [])

  const editorOptions = useMemo(() => {
    return {
      extensions: extensions,
      content: '',
      onBlur: onBlur,
      onUpdate: onUpdate,
      onCreate: onCreate,
      onFocus: onFocus
    }
  }, [extensions, onBlur, onUpdate, onCreate, onFocus])

  const editor = useEditor(editorOptions, [])

  useEffect(() => {
    dispatchRef.current = dispatch
  }, [dispatch])

  const html = editor?.getHTML() ?? null

  useEffect(() => {
    if (questionId !== questionIdRef.current) {
      editor?.commands.setContent(content, false)
    } else if (contentRef.current !== content) {
      window.clearTimeout(timeoutId.current)
      if (content !== html) {
        console.debug('Setting editor content from undo/redo.', content)
        editor?.commands.setContent(content, false)
      }
    }
  }, [questionId, editor?.commands, html, content])

  useEffect(() => {
    questionIdRef.current = questionId
  }, [questionId])

  useEffect(() => {
    contentRef.current = content
  }, [content])

  return editor
}

export const QuestionContentEditor = memo(function QuestionContentEditor ({ editor }) {
  return (
    <RichTextEditor editor={editor}>
      <RichTextEditor.Toolbar sticky stickyOffset={60}>
        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Bold />
          <RichTextEditor.Italic />
          <RichTextEditor.Underline />
          <RichTextEditor.Strikethrough />
          <RichTextEditor.ClearFormatting />
          <RichTextEditor.Highlight />
          <RichTextEditor.Code />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.H1 />
          <RichTextEditor.H2 />
          <RichTextEditor.H3 />
          <RichTextEditor.H4 />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Blockquote />
          <RichTextEditor.Hr />
          <RichTextEditor.BulletList />
          <RichTextEditor.OrderedList />
          <RichTextEditor.Subscript />
          <RichTextEditor.Superscript />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Link />
          <RichTextEditor.Unlink />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.AlignLeft />
          <RichTextEditor.AlignCenter />
          <RichTextEditor.AlignJustify />
          <RichTextEditor.AlignRight />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ColorPicker
          colors={[
            '#25262b',
            '#868e96',
            '#fa5252',
            '#e64980',
            '#be4bdb',
            '#7950f2',
            '#4c6ef5',
            '#228be6',
            '#15aabf',
            '#12b886',
            '#40c057',
            '#82c91e',
            '#fab005',
            '#fd7e14'
          ]}
        />
        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Control interactive={false}>
            <IconColorPicker stroke={1.5} />
          </RichTextEditor.Control>
          <RichTextEditor.Color color="#F03E3E" />
          <RichTextEditor.Color color="#7048E8" />
          <RichTextEditor.Color color="#1098AD" />
          <RichTextEditor.Color color="#37B24D" />
          <RichTextEditor.Color color="#F59F00" />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.UnsetColor />

        <RichTextEditor.ControlsGroup>
          <PlaceholdersControl editor={editor} platform={2} category={0} />
        </RichTextEditor.ControlsGroup>
      </RichTextEditor.Toolbar>

      <RichTextEditor.Content />
    </RichTextEditor>
  )
})
