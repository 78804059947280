import React from 'react'
import { FileInput } from '@mantine/core'
import { IconFileSpreadsheet } from '@tabler/icons-react'

/**
 * This component renders a FileInput that accepts xlsx files.
 * @typedef {object} SpredsheetInputProps The props of the component.
 * @property {string} [placeholder] The placeholder for the input. Defaults to "Pick file".
 * @property {string} name The name to reference the select on a form.
 * @property {boolean} value The value of the input.
 * @property {function} onChange The onChange callback.
 * @property {function} onFocus The onFocus callback.
 * @property {function} onBlur The onBlur callback.
 * @property {React.Node} error The error component.
 * @param {SpredsheetInputProps} props
 * @returns {React.ReactElement}
 */
function SpreadsheetInput ({
  placeholder = 'Pick file',
  name,
  value,
  onChange,
  onFocus,
  onBlur,
  error
}) {
  return (
    <FileInput
      placeholder={placeholder}
      required
      accept='application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      leftSection={<IconFileSpreadsheet size='1.5rem' />}
      name={name}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      error={error}
      fileInputProps={{
        'aria-label': 'Upload file'
      }}
    />
  )
}

export default SpreadsheetInput
