import dayjs from 'dayjs';

export function formatDateFromSource (nullableDate) {
  return nullableDate ? new Date(dayjs(nullableDate).local().format()) : null
}

export function formatDateToSource (nullableDate) {
  return nullableDate ? dayjs(nullableDate).unix().toString() : null
}

export function formatDateToIntSource (nullableDate) {
  return nullableDate ? dayjs(nullableDate).unix() : null
}

export function renderDateFromSource (nullableDate, format = null, defaultIncludeTime = false) {
  return nullableDate ? dayjs(nullableDate).local().format(format ?? `MM-DD-YYYY${defaultIncludeTime ? ' hh:mm:ss A' : ''}`) : ''
}

export function formatNullableBooleanChoiceToSource (nullableBoolean) {
  return (nullableBoolean === null) ? '0' : (nullableBoolean ? '1' : '-1')
}

export function combineApplicantName (applicant) {
  const lastName = (applicant?.first_name && applicant?.last_name) ? ' ' + applicant.last_name : (applicant?.last_name ?? '')
  return (applicant?.first_name ?? '') + lastName
}

export function formatOpenInviteLink (inviteUrlSuffix, configLinkPrefix) {
  return inviteUrlSuffix ? window.location.origin + `/your/invites/open/${configLinkPrefix || 'welcome'}/${inviteUrlSuffix}` : null
}

export function formatPhaseInviteLink (inviteUrlSuffix, configLinkPrefix) {
  return inviteUrlSuffix ? window.location.origin + `/your/invites/${configLinkPrefix || 'welcome'}/${inviteUrlSuffix}` : null
}

export const MAX_LINK_PREFIX_LENGTH = 50

/**
 * @param {?string} prefix
 * @returns {boolean}
 */
export function validateInviteLinkPrefix (prefix) {
  return /^[a-zA-Z0-9_-]*$/.test(prefix ?? '') && ((prefix?.length ?? 0) <= MAX_LINK_PREFIX_LENGTH)
}
