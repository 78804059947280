import { formatQuestionsFromJson } from './QuestionsState';
import { calculateMaxPage } from './util';

/**
 * @typedef {object} ImportQuestionsState
 * @property {Map.<int: Question>} questions <intId: Question>
 * @property {Map.<int: Question>} selected <intId: Question>
 * @property {object} selectedViewFilters
 * @property {int[]} assessments IDs
 * @property {int[]} competencies IDs
 * @property {string[]} types Question Type IDs
 * @property {string} search
 * @property {int} total
 * @property {int} limit
 * @property {int} page
 * @property {int} maxPage
 * @property {string} orderBy
 * @property {string} orderDirection
 * @property {int} lastQueryId
 * @property {int} lastProcessedQueryId
 * @property {boolean} querying
 * @property {?BatchFilteredAction} selecting
 * @property {boolean} preservePageBreaks
 */

/**
 * @param {object|null} questionsResponse
 * @param {int|undefined} questionsResponse.total
 * @param {SourceQuestionJson[]|undefined} questionsResponse.items
 * @returns {ImportQuestionsState}
 */
function createInitialImportQuestionsState (questionsResponse = null) {
  const blankState = {
    questions: new Map(),
    selected: new Map(),
    selectedViewFilters: { search: '', limit: 25, page: 1, maxPage: 1, searchFiltered: new Map() },
    assessments: [],
    competencies: [],
    types: [],
    search: '',
    total: 0,
    limit: 25,
    page: 1,
    maxPage: 1,
    orderBy: 'survey',
    orderDirection: 'asc',
    lastQueryId: 1,
    lastProcessedQueryId: 0,
    querying: true,
    selecting: null,
    preservePageBreaks: false
  }
  if (!questionsResponse?.items?.length) {
    console.debug('Initial import questions state loading skipped.', questionsResponse, blankState)
    return blankState
  }
  const stateQuestions = formatQuestionsFromJson(questionsResponse.items, false)
  console.debug('Initial import questions state loaded - inserting.', stateQuestions, blankState)
  return { ...blankState, questions: stateQuestions, total: questionsResponse.total, maxPage: calculateMaxPage(questionsResponse.total, questionsResponse.items.length), lastProcessedQueryId: blankState.lastQueryId, querying: false }
}

const BatchFilteredAction = Object.freeze({
  Import: 'import-filtered',
  Select: 'select-filtered'
})

const ImportQuestionsUpdate = Object.freeze({
  UpdateFilter: 'set-filter',
  SetPage: 'set-page',
  SetLimit: 'set-limit',
  SetQuestions: 'set-questions',
  SelectQuestion: 'select-question',
  ClearSelected: 'clear-selected',
  UpdateSelectedViewFilter: 'set-selected-filter',
  BatchFilter: 'batch-filter',
  ImportBatchFromFilter: 'batch-completed',
  TogglePageBreaks: 'toggle-page-breaks'
})

/**
 * @param {*&ImportQuestionsState} state
 * @param {object} action
 * @param {ImportQuestionsUpdate} action.type
 * @param {string|undefined} action.filterField? UpdateFilter
 * @param {[]|string|int|undefined} action.filterValue? UpdateFilter
 * @param {int|undefined} action.newPage? SetPage
 * @param {int|undefined} action.newLimit? SetLimit
 * @param {int|undefined} action.questionId? SelectQuestion
 * @param {boolean|undefined} action.selected? SelectQuestion
 * @param {int|undefined} action.queryId? SetQuestions
 * @param {object|undefined} action.response? SetQuestions
 * @param {int|undefined} action.response.total SetQuestions
 * @param {SourceQuestionJson[]|undefined} action.response.items SetQuestions
 * @param {BatchFilteredAction|undefined} action.batchAction? BatchFilter, ImportBatchFromFilter
 * @param {Map.<int: Question>|undefined} action.batchImport? ImportBatchFromFilter
 * @returns {*&ImportQuestionsState}
 */
function importQuestionsReducer (state, action) {
  console.debug('Import Questions State Updating.', action.type, action, state)
  switch (action.type) {
    case ImportQuestionsUpdate.UpdateFilter: {
      const filterField = action.filterField
      const filterValue = action.filterValue
      const newState = {
        ...state,
        page: 1,
        maxPage: 1,
        total: 0,
        querying: true,
        lastQueryId: state.lastQueryId + 1
      }
      console.debug('Setting import questions state filter field to new value.', filterField, filterValue, newState[filterField], action, state)
      newState[filterField] = filterValue
      return newState
    }
    case ImportQuestionsUpdate.UpdateSelectedViewFilter: {
      const filterField = action.filterField
      const filterValue = action.filterValue
      console.debug('Updating import questions state selected filter.', filterField, filterValue, action, state)
      const newViewFilter = { ...state.selectedViewFilters }
      if (filterField === 'page') {
        if (filterValue === newViewFilter.page) {
          console.debug(
            'Setting selectedViewFilters page to current page - skipping state update.',
            filterValue,
            newViewFilter.page,
            action,
            state
          )
          return state
        }
        if ((filterValue > 1) && (((filterValue - 1) * newViewFilter.limit) >= newViewFilter.searchFiltered.size)) {
          console.error(
            'Cannot set selectedViewFilters state page to new value due to exceeding limit.',
            filterValue,
            newViewFilter.limit,
            newViewFilter.searchFiltered.size,
            action,
            state
          )
          return state
        }
        newViewFilter.page = filterValue
      } else if (filterField === 'limit') {
        if (filterValue === newViewFilter.limit) {
          console.debug(
            'Setting selectedViewFilters to current limit - skipping state update.',
            filterValue,
            newViewFilter.limit,
            action,
            state
          )
          return state
        }
        const currentStart = newViewFilter.limit * (newViewFilter.page - 1)
        const newMaxPage = calculateMaxPage(newViewFilter.searchFiltered.size, filterValue)
        let newCurrentPage = Math.floor(currentStart / filterValue) + 1
        if (newCurrentPage > newMaxPage) {
          newCurrentPage = newMaxPage
        }
        newViewFilter.page = newCurrentPage
        newViewFilter.maxPage = newMaxPage
        newViewFilter.limit = filterValue
      } else {
        const newSearch = filterValue.toLowerCase()
        const oldSearch = newViewFilter.search.toLowerCase()
        const searchedEntities = (oldSearch && newSearch.includes(oldSearch)) ? state.selectedViewFilters.searchFiltered : state.selected
        newViewFilter.page = 1
        if (newSearch) {
          newViewFilter.searchFiltered = new Map()
          for (const selected of searchedEntities.values()) {
            if (selected.content.toLowerCase().includes(newSearch)) {
              newViewFilter.searchFiltered.set(selected.id, selected)
            }
          }
        } else {
          newViewFilter.searchFiltered = new Map(state.selected)
        }
        newViewFilter.maxPage = calculateMaxPage(newViewFilter.searchFiltered.size, newViewFilter.limit)
        newViewFilter.search = filterValue
      }
      return {
        ...state,
        selectedViewFilters: newViewFilter
      }
    }
    case ImportQuestionsUpdate.SetPage: {
      const newPage = action.newPage
      if (newPage === state.page) {
        console.debug(
          'Setting page to current page - skipping state update.',
          newPage,
          state.page,
          action,
          state
        )
        return state
      }
      if ((newPage > 1) && (((newPage - 1) * state.limit) >= state.total)) {
        console.error(
          'Cannot set import questions state page to new value due to exceeding limit.',
          newPage,
          state.limit,
          state.total,
          action,
          state
        )
        return state
      }
      return {
        ...state,
        page: newPage,
        querying: true,
        lastQueryId: state.lastQueryId + 1
      }
    }
    case ImportQuestionsUpdate.SetLimit: {
      const newLimit = action.newLimit
      if (newLimit === state.limit) {
        console.debug(
          'Setting limit to current limit - skipping state update.',
          newLimit,
          state.limit,
          action,
          state
        )
        return state
      }
      const currentStart = state.limit * (state.page - 1)
      const newMaxPage = calculateMaxPage(state.total, newLimit)
      let newCurrentPage = Math.floor(currentStart / newLimit) + 1
      if (newCurrentPage > newMaxPage) {
        newCurrentPage = newMaxPage
      }
      return {
        ...state,
        page: newCurrentPage,
        maxPage: newMaxPage,
        limit: newLimit,
        querying: true,
        lastQueryId: state.lastQueryId + 1
      }
    }
    case ImportQuestionsUpdate.SetQuestions: {
      const queryResponse = action.response
      const queryId = action.queryId
      if (queryId <= state.lastProcessedQueryId) {
        console.debug(
          'Skipping update from query response due to more recent queries having already been processed.',
          queryId,
          state.lastProcessedQueryId,
          state.lastQueryId,
          action,
          state
        )
        return state
      }
      return {
        ...state,
        questions: formatQuestionsFromJson(queryResponse.items, false),
        total: queryResponse.total,
        maxPage: calculateMaxPage(queryResponse.total, state.limit),
        querying: (queryId === state.lastQueryId) ? false : state.querying,
        lastProcessedQueryId: queryId
      }
    }
    case ImportQuestionsUpdate.ImportBatchFromFilter: {
      console.debug('Got import questions batch filter complete state update.', action, state)
      const batchAction = state.selecting
      const newState = { ...state, selecting: null }
      if (batchAction === BatchFilteredAction.Select) {
        const importQuestions = action.batchImport
        const newSelected = new Map(state.selected)
        const newSearchFiltered = new Map(state.selectedViewFilters.searchFiltered)
        for (const question of importQuestions.values()) {
          newSelected.set(question.id, question)
          if ((!state.selectedViewFilters.search) || (question.content.toLowerCase().includes(state.selectedViewFilters.search.toLowerCase()))) {
            newSearchFiltered.set(question.id, question)
          }
        }
        const newMaxPage = calculateMaxPage(newSearchFiltered.size, state.selectedViewFilters.limit)
        newState.selectedViewFilters = {
          ...state.selectedViewFilters,
          searchFiltered: newSearchFiltered,
          maxPage: newMaxPage
        }
        newState.selected = newSelected
      }
      return newState
    }
    case ImportQuestionsUpdate.SelectQuestion: {
      const targetQuestionId = action.questionId
      const selected = action.selected
      const targetQuestion = state.selected.get(targetQuestionId) ?? state.questions.get(targetQuestionId)
      if ((!selected) && (!state.selected.has(targetQuestionId))) {
        console.error(
          'Attempted to unselect a question which was not already selected.',
          targetQuestionId,
          action,
          state
        )
        return state
      }
      if (selected) {
        if (state.selected.has(targetQuestionId)) {
          console.error(
            'Attempted to select a question which was already selected.',
            targetQuestionId,
            action,
            state
          )
          return state
        }
        if (!targetQuestion) {
          console.error(
            'Attempted to select a question which is no longer found in the current state.',
            targetQuestionId,
            action,
            state
          )
          return state
        }
      }
      const newState = { ...state }
      const newSelected = new Map(state.selected)
      if (selected) {
        newSelected.set(targetQuestionId, targetQuestion)
        if ((!state.selectedViewFilters.search) || (targetQuestion.content.toLowerCase().includes(state.selectedViewFilters.search.toLowerCase()))) {
          const newSearchFiltered = new Map(state.selectedViewFilters.searchFiltered)
          newSearchFiltered.set(targetQuestionId, targetQuestion)
          const newMaxPage = calculateMaxPage(newSearchFiltered.size, state.selectedViewFilters.limit)
          newState.selectedViewFilters = {
            ...state.selectedViewFilters,
            searchFiltered: newSearchFiltered,
            maxPage: newMaxPage
          }
        }
      } else {
        newSelected.delete(targetQuestionId)
        if (state.selectedViewFilters.searchFiltered.has(targetQuestionId)) {
          const newSearchFiltered = new Map(state.selectedViewFilters.searchFiltered)
          newSearchFiltered.delete(targetQuestionId)
          const newMaxPage = calculateMaxPage(newSearchFiltered.size, state.selectedViewFilters.limit)
          newState.selectedViewFilters = {
            ...state.selectedViewFilters,
            searchFiltered: newSearchFiltered,
            maxPage: newMaxPage,
            page: Math.min(newMaxPage, state.selectedViewFilters.page)
          }
        }
      }
      newState.selected = newSelected
      return newState
    }
    case ImportQuestionsUpdate.ClearSelected: {
      return {
        ...state,
        selected: new Map(),
        selectedViewFilters: { ...state.selectedViewFilters, searchFiltered: new Map(), page: 1, maxPage: 1 }
      }
    }
    case ImportQuestionsUpdate.BatchFilter: {
      if (state.selecting) {
        console.error('Attempted batch filter when currently selecting - skipping.', action, state)
        return state
      }
      return {
        ...state,
        selecting: action.batchAction ?? null
      }
    }
    case ImportQuestionsUpdate.TogglePageBreaks: {
      return { ...state, preservePageBreaks: !state.preservePageBreaks }
    }
    default: {
      console.error('Unknown Import Questions State Update.', action.type, action, state)
      return state
    }
  }
}

export { createInitialImportQuestionsState, ImportQuestionsUpdate, BatchFilteredAction, importQuestionsReducer }
