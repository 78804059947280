import questionsSlice from './assessment/questionSlice'
import pagesSlice from './assessment/pagesSlice'
import respondentAnswersSlice from './assessment/respondentAnswersSlice'
import assessmentsSlice from './assessment/assessmentsSlice'
import blocksSlice from './assessment/blocksSlice'
import logicSlice from './assessment/logicSlice'
import exportsSlice from './assessment/exportsSlice'
import unpublishedLogicSlice from './build/assessment/EditorComponents/UnpublishedQuestionLogic/unpublishedLogicSlice'
import paramsSlice from './core/ReactTable/paramsSlice'
import tableSelectedRowsSlice from './core/ReactTable/tableSelectedRowsSlice';
import tableRowsSlice from './core/ReactTable/tableRowsSlice'
import tableColumnsSlice from './core/ReactTable/tableColumnsSlice'
import { apiSlice } from './api'
import detailViewSlice from './hire/cycle/detailViewSlice'
import distributionReportPagesSlice from './hire/cycle/invites/stages/distributionReportPagesSlice';
import distributionProgressesSlice from './hire/cycle/invites/stages/distributionProgressesSlice';
import distributionResponsesSlice from './hire/cycle/invites/stages/distributionResponsesSlice';

const RootReducer = {
  unpublishedLogic: unpublishedLogicSlice,
  logic: logicSlice,
  questions: questionsSlice,
  pages: pagesSlice,
  blocks: blocksSlice,
  respondentAnswers: respondentAnswersSlice,
  assessments: assessmentsSlice,
  exports: exportsSlice,
  params: paramsSlice,
  detailView: detailViewSlice,
  tableRows: tableRowsSlice,
  tableColumns: tableColumnsSlice,
  tableSelectedRows: tableSelectedRowsSlice,
  distributionReportPages: distributionReportPagesSlice,
  distributionProgresses: distributionProgressesSlice,
  distributionResponses: distributionResponsesSlice,
  [apiSlice.reducerPath]: apiSlice.reducer
}

export default RootReducer
