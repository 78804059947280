import React, { forwardRef } from 'react'
import { Switch } from '@mantine/core'

/**
 * This component renders a switch with "on" and "off" labels.
 * @typedef {object} OnOffSwitchProps The props of the component.
 * @property {string} label The label to display next to the switch.
 * @property {boolean} [defaultChecked] If the switch should default to on or off. Default is false.
 * @property {string} [color] The color of the switch. Defaults to "blue.6".
 * @property {string} name The name to reference the switch on a form.
 * @property {boolean} value The value of the switch.
 * @property {function} onChange The onChange callback.
 * @property {function} onFocus The onFocus callback.
 * @property {function} onBlur The onBlur callback.
 * @property {React.Node} error The error component.
 * @type React.FC<OnOffSwitchProps>
 * @returns {React.ReactElement}
 */
const OnOffSwitch = forwardRef(function OnOffSwitch ({
  label,
  defaultChecked = false,
  color = 'blue.6',
  name,
  value,
  onChange,
  onFocus,
  onBlur,
  error
},
ref
) {
  return (
    <Switch
      rootRef={ref}
      label={label}
      aria-label={label}
      color={color}
      defaultChecked={defaultChecked}
      onLabel='ON'
      offLabel='OFF'
      name={name}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      error={error}
    />
  )
})

export default OnOffSwitch
