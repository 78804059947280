import React from 'react'
import { SimpleGrid } from '@mantine/core'
import CreateButtons from './CreateButtons'
import SkillbuilderCyclesTable from './SkillbuilderCyclesTable'
import { useGetLoggedInAccountQuery } from '../../../redux/query/account/accountsApi.slice'
import ErrorLoading from '../../core/ErrorLoading'
import Loading from '../../core/Loading'
import { isGranted } from '../../../util/account/account.util'

export default function SkillbuilderCyclesHome () {
  const { data: account, isLoading, isError } = useGetLoggedInAccountQuery()

  if (isError) {
    return <ErrorLoading />
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <SimpleGrid>
      { isGranted(account, 'ROLE_ADMIN') && <CreateButtons/> }
      <SkillbuilderCyclesTable account={account} />
    </SimpleGrid>
  )
}
