/* eslint react/prop-types: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
import { Box, Button, Center, Group, Loader, Paper, SimpleGrid, Text, Title } from '@mantine/core'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import * as DOMPurify from 'dompurify'
import React, { useContext, useMemo, useState } from 'react'
import DateSelection from './DateSelection'
import TemplateSelection from './TemplateSelection'
import { ContactStateUpdate } from './ContactState';
import { ContactDispatchContext } from './ContactContexts';
import { usePreviewTextTemplateQuery } from '../../../redux/query/hire/applicantsApi.slice';
import Loading from '../../core/Loading';
import QueryErrorAlert from '../../core/Alert/QueryErrorAlert';

dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)

export default function TextMessage ({ message, form, index, previewApplicant, templates }) {
  const [preview, setPreview] = useState(false)
  const { data: previewData, error: previewError, isFetching: previewLoading, refetch: retryPreview } = usePreviewTextTemplateQuery(
    { applicantId: previewApplicant, body: message.body },
    { skip: !previewApplicant || !preview }
  )

  return (
    <Paper shadow='sm' p='lg' radius='md' withBorder aria-label={`Message #${index + 1}`}>
      <SimpleGrid cols={1}>
        <Title order={6} c='dimmed'>Text Message</Title>
        <Box display={!preview ? 'block' : 'none'}>
          <Editor
            message={message}
            form={form}
            index={index}
            previewApplicant={previewApplicant}
            templates={templates}
            setPreview={setPreview}
          />
        </Box>
        <Box display={preview ? 'block' : 'none'} pos='relative'>
          {!!previewLoading && (
            <Loader>
              <Box pos='relative'>
                <Loading />
              </Box>
              <Center>
                <Button onClick={() => setPreview(false)}>
                  Cancel Preview
                </Button>
              </Center>
            </Loader>
          )}
          {!!previewError && !previewLoading && (
            <QueryErrorAlert
              title='Preview Error!'
              onClick={retryPreview}
              loading={previewLoading}
              onClose={() => setPreview(false)}
            >
              Error loading preview for applicant.
            </QueryErrorAlert>)
          }
          {!previewError && !previewLoading && (
            <Preview
              messageId={message.id}
              previewBody={previewData?.data?.body}
              previewSendAt={message.send_at}
              setPreview={setPreview}
            />
          )}
        </Box>
      </SimpleGrid>
    </Paper>
  )
}

function Editor ({ message, form, index, previewApplicant, templates, setPreview }) {
  const dispatch = useContext(ContactDispatchContext)
  return (
    <SimpleGrid cols={1}>
      <Group spacig='xs'>
        <Button size='xs' variant='light' color='blue' disabled={!previewApplicant} onClick={() => setPreview(true)}>Preview</Button>
        <Button size='xs' variant='light' color='red' onClick={() => dispatch({ type: ContactStateUpdate.RemoveMessage, messageId: message.id })}>
          Remove
        </Button>
      </Group>
      <DateSelection messageId={message.id} messageSendAt={message.send_at} form={form} index={index} />
      <TemplateSelection messageId={message.id} messageTemplate={message.template} form={form} index={index} templates={templates} />
      <div>{message.body}</div>
    </SimpleGrid>
  )
}

function Preview ({ messageId, previewBody, previewSendAt, setPreview }) {
  const dispatch = useContext(ContactDispatchContext)
  const body = useMemo(() => {
    return DOMPurify.sanitize(previewBody ?? '')
  }, [previewBody])

  return (
    <SimpleGrid cols={1}>
      <Group spacig='xs'>
        <Button size='xs' variant='light' color='blue' onClick={() => setPreview(false)}>Edit</Button>
        <Button size='xs' variant='light' color='red' onClick={() => dispatch({ type: ContactStateUpdate.RemoveMessage, messageId: messageId })}>Remove</Button>
      </Group>
      <Title order={6}>Send At</Title>
      <Text>{dayjs(previewSendAt).format('MM/DD/YYYY')}</Text>
      <Title order={6}>Content</Title>
      <div dangerouslySetInnerHTML={{ __html: body }}></div>
    </SimpleGrid>
  )
}
