/* eslint react/prop-types: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
import { Controller, useController, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import React, { memo, useCallback, useContext, useEffect, useMemo } from 'react'
import Error from '../../../../forms/Error'
import * as styles from './Consequents.module.scss'
import { ActionIcon, Button, Divider, Flex, Grid, Modal, NumberInput, Radio, Select, Stack, Tooltip } from '@mantine/core'
import BatterySelect from './BatterySelect'
import { IconMailQuestion, IconMinus, IconPlus } from '@tabler/icons-react'
import { useDisclosure } from '@mantine/hooks'
import _ from 'lodash'
import WarningAlert from '../../../../core/Alert/WarningAlert'
import EmailTemplatesContext from '../../../../../contexts/EmailTemplatesContext';
import { ConfigEdit } from '../../../cycle/invites/sent/ConfigEdit';
import { useParams } from 'react-router-dom';
import { formatToOptions } from '../../../../../js/util/DataUtil';
import InviteConfigTemplatesContext from '../../../../../contexts/InviteConfigTemplatesContext';
import { InviteStyle } from '../../../../../js/generated/enums/InviteStyle';
import { ThankYouStyle } from '../../../../../js/generated/enums/ThankYouStyle';
import { createFormActions } from '@mantine/form';
import { MessageType } from '../../../contact/ContactState';

const defaultEmailData = { template: null, schedule: null, after_execution: null, email_program: null }

const ContactConsequent = memo(function ContactConsequent () {
  const {
    fields: contactApplicantFields,
    remove: contactApplicantRemove,
    insert: contactApplicantInsert
  } = useFieldArray({
    name: 'metadata.emails',
    shouldUnregister: true
  })

  return (
    <>
      {contactApplicantFields.map((item, index) => (
          <Stack key={index}>
            <TemplateSelection index={index}/>
            <DateSelection index={index}/>
            <div className={styles.consequentEmailAction}>
              <Tooltip label='Add email' position='bottom-start'>
                <ActionIcon onClick={() => {
                  contactApplicantInsert(index + 1, defaultEmailData)
                }}>
                  <IconPlus/>
                </ActionIcon>
              </Tooltip>
              <ActionIcon onClick={() => contactApplicantRemove(index)}>
                <IconMinus/>
              </ActionIcon>
            </div>
            {(index !== (contactApplicantFields.length - 1)) && <Divider size="sm" variant="dotted"/>}
          </Stack>
      ))}
      <InviteConfiguration/>
    </>
  )
})

const InviteConfiguration = memo(function InviteConfiguration () {
  const { id } = useParams()
  const emailTemplates = useContext(EmailTemplatesContext)
  const inviteConfigTemplate = useContext(InviteConfigTemplatesContext)
  const inviteConfigTemplateFormActions = createFormActions('invite-config-template-form')
  const { setValue, unregister } = useFormContext()
  const [opened, handlers] = useDisclosure(false)

  const emails = useWatch({ name: 'metadata.emails' })

  const hasBatteryInvite = useMemo(() => {
    return _.some(emails, email =>
      messageIsAssessmentInvite({ type: 'email', template: email.template?.toString() }, emailTemplates, null, true, false)
    )
  }, [emails, emailTemplates])

  const hasPhaseInvite = useMemo(() => {
    return _.some(emails, email =>
      messageIsAssessmentInvite({ type: 'email', template: email.template?.toString() }, emailTemplates, null, false, true)
    )
  }, [emails, emailTemplates])

  // Auto open the modal if there isn't an invitation config template linked to the trigger and a phase invite template was selected
  useEffect(() => {
    if (hasPhaseInvite && inviteConfigTemplate === null) {
      handlers.open()
    } else {
      // Since the values are retrieved from a Mantine form we have to manually unregister them
      unregister('metadata.invite_config_template')
    }
  }, [hasPhaseInvite, inviteConfigTemplate])

  const onInviteTemplateSubmit = useCallback((values) => {
    setValue('metadata.invite_config_template', values)
    handlers.close()
  }, [setValue, handlers]);

  const onInviteTemplateCancel = useCallback(() => {
    // Make sure pressing discard actually discards any modified field changes
    inviteConfigTemplateFormActions.reset()
    handlers.close()
  }, [handlers, inviteConfigTemplateFormActions])

  return (
    <>
      {(hasBatteryInvite || hasPhaseInvite) &&
        <Flex
          gap='xs'
          justify='flex-end'
          align='center'
          direction='row'
          wrap='wrap'
        >
          <Button variant='subtle' color='blue.6' size='xs' onClick={handlers.open}>Invite Configuration</Button>
        </Flex>
      }

      <Modal trapFocus={false} opened={opened} onClose={handlers.close} keepMounted={true} size='auto' title={hasBatteryInvite ? 'Invite Configuration' : null}>
        {hasPhaseInvite &&
          <div>
            <ConfigEdit
              cycles={[id]}
              template={transformInviteConfigTemplate(inviteConfigTemplate)}
              onSubmit={onInviteTemplateSubmit}
              onCancel={onInviteTemplateCancel}
              validating={false}
            />
          </div>
        }

        {hasBatteryInvite &&
          <Controller
            name='metadata.days_until_display_deadline'
            defaultValue={10}
            render={
              ({ field }) =>
                <NumberInput
                  label='Amount of days until display deadline'
                  allowNegative={false}
                  allowDecimal={false}
                  min={1}
                  {...field}
                />
            }
          />
        }
        <Error name={'metadata.days_until_display_deadline'}/>
      </Modal>
    </>
  )
})

const TemplateSelection = memo(function TemplateSelection ({ index }) {
  const emailTemplates = useContext(EmailTemplatesContext)
  const { field } = useController({
    name: `metadata.emails.${index}.template`,
    defaultValue: null,
    rules: { required: 'You must select a template' }
  })

  const isAssessmentInvitation = messageIsAssessmentInvite({ type: 'email', template: field.value }, emailTemplates, null, true, false)

  const templateOptions = useMemo(() => {
    return formatToOptions(emailTemplates, { label: 'title', value: 'id' })
  }, [emailTemplates])

  return (
    <>
      <Select
        leftSection={<IconMailQuestion/>}
        placeholder='Pick template'
        nothingFoundMessage='Nothing found...'
        data={templateOptions}
        searchable
        clearable
        {...field}
      />
      <Error name={`metadata.emails.${index}.template`}/>
      {isAssessmentInvitation && <BatterySelect index={index}/>}
    </>
  )
})

const DateSelection = memo(function DateSelection ({ index }) {
  const emailTemplates = useContext(EmailTemplatesContext)
  const inviteConfigTemplate = useContext(InviteConfigTemplatesContext)

  const email = useWatch({ name: `metadata.emails.${index}` })
  const isInvite = messageIsAssessmentInvite({ type: 'email', template: email.template?.toString() }, emailTemplates, null, true, true)

  // assessment deadline -> invite template deadline -> invite template deadline from context (In case a phase template was configured but not edited yet) -> The default 10 days
  const assessmentDaysUntilDeadline = useWatch({ name: 'metadata.days_until_display_deadline', defaultValue: null })
  const phaseDaysUntilDeadline = useWatch({ name: 'metadata.invite_config_template.daysUntilDisplayDeadline', defaultValue: null })
  const displayDeadline = assessmentDaysUntilDeadline ?? phaseDaysUntilDeadline ?? inviteConfigTemplate?.days_until_display_deadline ?? 10

  const { field } = useController({
    name: `metadata.emails.${index}.schedule`,
    defaultValue: null,
    rules: { required: 'An email schedule must be selected' }
  })

  return (
    <Grid>
      <Grid.Col sm={12}>
        <Radio.Group
          label='When do you want to send this email?'
          {...field}
        >
          <Stack>
            <Radio value='0' label='Immediately' />
            <Radio value='1' label='X Days After Execution' />
          </Stack>
        </Radio.Group>
        <Error name={`metadata.emails.${index}.schedule`}/>
      </Grid.Col>
      {parseInt(field.value) === 1 &&
        <Grid.Col span={12}>
          <Controller
            name={`metadata.emails.${index}.after_execution`}
            defaultValue={null}
            // Only make after_execution required if 'X Days After Execution' is selected
            rules={{ required: parseInt(field.value) === 1 ? 'The amount of days after execution is required' : false }}
            render={
              ({ field }) =>
                <NumberInput
                  label='Days after execution'
                  allowNegative={false}
                  allowDecimal={false}
                  min={1}
                  {...field}
                />
            }
          />
          {(email.after_execution > displayDeadline && isInvite) &&
            <WarningAlert>This email is scheduled to be sent after the defined display deadline of {displayDeadline} days.</WarningAlert>
          }
          <Error name={`metadata.emails.${index}.after_execution`}/>
        </Grid.Col>
      }
    </Grid>
  )
})

/**
 * Transforms the data returned by the API to work with the ConfigEdit modal form.
 * @param inviteConfigTemplate
 * @returns {{phase: string, daysUntilDisplayDeadline: (number|string|number|*), secondsUntilExpirationDate: (number|null), inviteConfig: {internalNote: (string|string), linkPrefix: (string|string), header: (*|string), message: (*|string), expirationDate: (string|null), style: string, thankYouStyle: string, collectDemographics: (boolean|null), emailOnSubmit: (boolean|boolean), proctored: (string|?bool|*|null), applicantFlagTemplate: (boolean|boolean), autoComment: (string|string)}}|null}
 */
function transformInviteConfigTemplate (inviteConfigTemplate) {
  return inviteConfigTemplate
    ? {
        phase: inviteConfigTemplate?.phase?.id.toString() ?? null,
        daysUntilDisplayDeadline: inviteConfigTemplate?.days_until_display_deadline ?? 10,
        secondsUntilExpirationDate: inviteConfigTemplate?.seconds_until_expiration_date ?? null,
        inviteConfig: {
          internalNote: inviteConfigTemplate?.invite_config?.internal_note ?? '',
          linkPrefix: inviteConfigTemplate?.invite_config?.link_prefix ?? '',
          header: inviteConfigTemplate?.invite_config?.header ?? '',
          message: inviteConfigTemplate?.invite_config?.message ?? '',
          expirationDate: inviteConfigTemplate?.invite_config?.expiration_date ?? null,
          style: (inviteConfigTemplate?.invite_config?.style ?? InviteStyle.Default).toString(),
          thankYouStyle: (inviteConfigTemplate?.invite_config?.thank_you_style ?? ThankYouStyle.ThankYouForYourTime).toString(),
          collectDemographics: inviteConfigTemplate?.invite_config?.collect_demographics ?? null,
          emailOnSubmit: inviteConfigTemplate?.invite_config?.email_on_submit ?? false,
          proctored: inviteConfigTemplate?.invite_config?.proctored ?? null,
          applicantFlagTemplate: inviteConfigTemplate?.invite_config?.applicantFlagTemplate ? (inviteConfigTemplate?.invite_config?.applicantFlagTemplate.type === 0) : true,
          autoComment: inviteConfigTemplate?.invite_config?.auto_comment ?? ''
        }
      }
    : null
}

function messageIsAssessmentInvite (message, emailTemplates, textTemplates, allowBattery = true, allowPhase = true) {
  const templates = message.type === MessageType.Email ? emailTemplates : textTemplates
  const assessmentTemplateCategoryTags = [
    'proctorfree-invitation',
    'proctorfree-reminder'
  ]

  for (const template of templates.values()) {
    if (message.template === template.id.toString()) {
      const tag = template.category?.tag

      if (tag) {
        return (allowBattery && assessmentTemplateCategoryTags.includes(tag)) || (allowPhase && tag && tag.toLowerCase().trim().startsWith('passport-invite'))
      }
    }
  }

  return false
}

export default ContactConsequent
