import React, { useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import DisplayTrigger from '../display/DisplayTrigger'
import PropTypes from 'prop-types'

export default function TriggerEditForm ({ trigger, statusSet, onSubmit, integrations = {} }) {
  const values = useMemo(() => ({
    ...trigger,
    strategy: trigger?.strategy.id.toString(),
    metadata: {
      ...trigger?.metadata,
      emails: trigger?.metadata?.emails?.map((email) => ({ ...email, template: email?.template?.toString(), schedule: email?.schedule?.toString(), email_program: email?.email_program?.toString() }))
    }
  }), [trigger])

  const methods = useForm({
    values: values,
    shouldUnregister: true
  })

  return (
    <FormProvider {...methods} >
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <DisplayTrigger
          statusSet={statusSet}
          integrations={integrations}
        />
      </form>
    </FormProvider>
  )
}

TriggerEditForm.propTypes = {
  trigger: PropTypes.object.isRequired,
  statusSet: PropTypes.object.isRequired,
  integrations: PropTypes.object,
  onSubmit: PropTypes.func.isRequired
}
