import dayjs from 'dayjs'
import React, { useCallback, useState, useMemo, useRef, useEffect, memo } from 'react'
import { Box, Button, Flex, Grid, Group, LoadingOverlay, SimpleGrid, Stack, Switch } from '@mantine/core'
import { IconCaretDown, IconSend } from '@tabler/icons-react'
import StarterKit from '@tiptap/starter-kit'
import { BubbleMenu, useEditor } from '@tiptap/react'
import { RichTextEditor } from '@mantine/tiptap'
import { NewDropdown } from '../../../core/NewDropdown'
import { useGetCommentsQuery, usePostCommentMutation } from '../../../../redux/query/hire/commentsApi.slice'
import { Comment, CommentTags } from './DiscussionComment'
import { StatusContext } from './DiscussionContexts'
import { useWindowEvent } from '@mantine/hooks'
import _ from 'lodash'
import Loading from '../../../core/Loading'
import { showNotification } from '@mantine/notifications';
import { useGetLoggedInAccountQuery } from '../../../../redux/query/account/accountsApi.slice'

const flippedProps = { style: { display: 'flex', flexDirection: 'column-reverse', overflowY: 'auto' } }
const emptyComments = []
function commentsHaveSameRelativeTimestamp (currentCommentDate, previousComment) {
  return dayjs(previousComment.created_at).fromNow() === currentCommentDate.fromNow() || currentCommentDate.diff(previousComment.created_at, 'minute') < 5
}

export function NewDiscussion ({ applicantId }) {
  const { data: comments, isFetching: isFetchingComments, isLoading } = useGetCommentsQuery(applicantId)
  const [postComment] = usePostCommentMutation()
  const [hideUnpinnedFromView, setHideUnpinnedFromView] = useState(false)
  const [startScrolling, setStartScrolling] = useState(false)
  const scrollableRef = useRef(null)
  const { data: account } = useGetLoggedInAccountQuery()

  const sortedComments = useMemo(() => {
    if (!comments?.length) return emptyComments

    return comments
      .map((comment) => [comment, dayjs(comment.created_at)])
      .toSorted(([, aCreatedAt], [, bCreatedAt]) => bCreatedAt.diff(aCreatedAt))
      .map(([comment], originalIndex) => [comment, originalIndex])
  }, [comments])

  const formattedComments = useMemo(() => {
    return hideUnpinnedFromView ? sortedComments.filter(([comment]) => !!comment.pinned) : sortedComments
  }, [sortedComments, hideUnpinnedFromView])

  useEffect(() => {
    if (startScrolling && !isFetchingComments) {
      scrollableRef.current.scrollTo({
        top: scrollableRef.current.scrollHeight,
        behavior: 'smooth'
      })
      setStartScrolling(false)
    }
  }, [startScrolling, isFetchingComments])

  const [disableSend, setDisableSend] = useState(false)

  const post = useCallback((content, pinned, tags = null) => {
    if (content.trim().length === 0) return Promise.reject(new Error('No content'))
    setDisableSend(true)
    return postComment({ applicantId: applicantId, content: content, pinned: pinned, tags: tags ?? [] })
      .unwrap()
      .then((response) => {
        console.debug('Post comment success response', { applicantId, content, pinned, tags, response })
        setStartScrolling(true)
        const event = new CustomEvent('discussion:comment-posted') // is there a better way to do this?
        window.dispatchEvent(event)
      })
      .finally(() => {
        setDisableSend(false)
      })
  }, [applicantId, postComment, setStartScrolling])

  const tags = useMemo(() => {
    return [...(new Set(_.flatMap(comments, 'tags'))).values()].toSorted()
  }, [comments])

  console.debug('NewDiscussion updating', { applicantId, comments, tags })

  if (isLoading) {
    return <Loading />
  }

  return (
    <Box>
      <SimpleGrid columns={1}>
        <StatusContext.Provider value={isFetchingComments}>
          {(formattedComments && account) &&
            <Box mah='25rem' ref={scrollableRef} {...flippedProps}>
              {formattedComments.map(([comment, originalIndex], index) => {
                const date = dayjs(comment.created_at)
                const isBotComment = !comment.account
                const [prevComment] = isBotComment ? [null, null] : formattedComments[index + 1] ?? [null, null]
                const [truePreviousComment] = isBotComment ? [null, null] : sortedComments[originalIndex + 1] ?? [null, null]
                const isSubComment = !isBotComment &&
                  prevComment?.account &&
                  truePreviousComment?.account &&
                  commentsHaveSameRelativeTimestamp(date, prevComment) &&
                  (prevComment === truePreviousComment || commentsHaveSameRelativeTimestamp(date, truePreviousComment))
                return (
                  <Comment
                    key={comment.id}
                    comment={comment}
                    isSubComment={isSubComment}
                    tagList={tags}
                    userAccount={account}
                  />
                )
              })}
            </Box>
          }
          <ControlBar
            onSend={post}
            disableSend={disableSend}
            hideUnpinnedFromView={hideUnpinnedFromView}
            setHideUnpinnedFromView={setHideUnpinnedFromView}
            tagList={tags}
          />
        </StatusContext.Provider>
      </SimpleGrid>
    </Box>
  )
}

const typographyStyles = {
  typographyStylesProvider: { paddingLeft: 0, marginBottom: 0 }
}
const tipTapOptions = { duration: 0 }
const noSelectStyle = { userSelect: 'none' }

export function CommentEditor ({ content, onSubmit, disabled = false, onUpdate = null }) {
  return (
    <Flex direction='column' justify='flex-end'>
      <CommentTextEditor content={content} onSubmit={onSubmit} disabled={disabled} onUpdate={onUpdate} />
      <CommentEditorInstructions />
    </Flex>
  )
}

function CommentTextEditor ({ content, onSubmit, disabled = false, onUpdate = null }) {
  const submit = useCallback(() => {
    if (disabled) return
    onSubmit()
  }, [onSubmit, disabled])

  const submitRef = useRef(submit)

  useEffect(() => {
    submitRef.current = submit
  }, [submit])

  const editor = useEditor({
    content: content,
    autofocus: 'end',
    editable: !disabled,
    onUpdate: ({ editor }) => {
      onUpdate?.(editor.getHTML())
    },
    extensions: [
      StarterKit
    ],
    editorProps: {
      handleKeyDown: (editor, ev) => {
        console.debug('Handling key down', { editor, ev })
        if ((ev.key === 'Enter' && !ev.shiftKey)) {
          ev.preventDefault()
          submitRef.current()
          return true
        }
      }
    }
  }, [disabled])

  useWindowEvent('discussion:comment-posted', () => {
    onUpdate?.('')
    editor.commands.clearContent()
  })

  return (
    <RichTextEditor
      styles={typographyStyles}
      editor={editor}
    >
      {editor && (
        <BubbleMenu editor={editor} tippyOptions={tipTapOptions}>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Link />
          </RichTextEditor.ControlsGroup>
        </BubbleMenu>
      )}
      <RichTextEditor.Content />
    </RichTextEditor>
  )
}

function CommentEditorInstructions ({ children = null }) {
  return (
    <Group justify='space-between' style={noSelectStyle} wrap='nowrap'>
      <Box fz='xs' c='dimmed'>Press Enter to submit</Box>
      <Box fz='xs' c='dimmed'>Press Shift + Enter to create a new line</Box>
      {!!children && (children)}
    </Group>
  )
}

const flexGrowProps = { flexGrow: 1 }
const loaderProps = { type: 'bars', mb: 'sm' }
const optionStyles = {
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0
}
const noOptionStyles = {}
const dropdownStyle = { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }

const ControlBar = memo(function ControlBar ({ tagList, content = '', disableSend, onSend: post, withOptionsDropdown = true, hideUnpinnedFromView, setHideUnpinnedFromView }) {
  const [defaultPinned, setDefaultPinned] = useState(true)
  const [currentContent, setCurrentContent] = useState(content)
  const [tags, setTags] = useState([])

  const handleSubmit = useCallback(() => {
    if (!currentContent || !currentContent.trim()) {
      return
    }
    post(currentContent, defaultPinned, tags)
      .then(() => {
        console.debug('Resetting new comment state from post success')
        showNotification({
          message: 'Your comment was posted!',
          color: 'success',
          autoClose: 3000
        })
        setTags([])
        setCurrentContent('')
      })
      .catch((error) => {
        console.debug('New comment post error', { currentContent, defaultPinned, tags, error })
        showNotification({
          title: 'New comment error',
          message: 'Something went wrong when trying to post your comment.',
          color: 'red',
          autoClose: 7000
        })
      })
  }, [currentContent, tags, post, defaultPinned])

  const onTagsUpdated = useCallback((newTags) => {
    console.debug('Called new comment on tags updated', { newTags })
    setTags(newTags)
  }, [])

  const withOptionsStyles = withOptionsDropdown ? optionStyles : noOptionStyles

  return (
    <Box>
      <Group align='center' wrap='nowrap' w='100%'>
        <Box style={flexGrowProps} pos='relative'>
          <LoadingOverlay loaderProps={loaderProps} visible={disableSend} />
          <Flex direction='column' justify='flex-end'>
            <Grid justify='flex-end' align='stretch' gutter={0} columns={24}>
              <Grid.Col span={19}>
                <CommentTextEditor content={currentContent} onSubmit={handleSubmit} disabled={disableSend} onUpdate={setCurrentContent} />
              </Grid.Col>
              <Grid.Col span={5}>
                <Flex mih='100%' justify='center' align='center' direction='column' wrap='nowrap'>
                  <Button.Group>
                    <Button onClick={handleSubmit} disabled={disableSend} style={withOptionsStyles}>
                      <IconSend />
                    </Button>
                    {withOptionsDropdown && (
                      <NewDropdown target={<Button px={0} variant='light' disabled={disableSend} style={dropdownStyle}><IconCaretDown /></Button>}>
                        <Stack p='xs'>
                          <Switch label='Automatically pin comments' checked={defaultPinned} onChange={event => setDefaultPinned(event.currentTarget.checked)} />
                          <Switch label='Hide unpinned comments from view' checked={hideUnpinnedFromView} onChange={event => setHideUnpinnedFromView(event.currentTarget.checked)} />
                        </Stack>
                      </NewDropdown>
                    )}
                  </Button.Group>
                </Flex>
              </Grid.Col>
              <Grid.Col span={19}>
                <CommentEditorInstructions>
                  <Box>
                    <CommentTags
                      tags={tags}
                      tagList={tagList}
                      onSubmit={onTagsUpdated}
                      hideUntilHover={false}
                      disabled={disableSend}
                    />
                  </Box>
                </CommentEditorInstructions>
              </Grid.Col>
              <Grid.Col span={5} />
            </Grid>
          </Flex>
        </Box>
      </Group>
    </Box>
  )
})
