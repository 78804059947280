import { useParams } from 'react-router-dom';
import { useIsAdmin } from '../CyclesHooks';
import { CyclePageTitle } from '../nav/CyclePageTitle';
import { Space } from '@mantine/core';
import { SyncCyclePassScoresButton } from '../passes/CyclePassDetail';
import { StageProgressesTable } from './StageProgressesTable';
import React from 'react';
import { DiagnosticsNavButtonTabs } from '../nav/DiagnosticsNavButton';

export function StageProgressesList () {
  const { cycleId } = useParams()
  const [isAdmin] = useIsAdmin()
  return (
    <>
      <CyclePageTitle cycleId={cycleId} pageName='Progress List' active={DiagnosticsNavButtonTabs.Progress} />
      {!!isAdmin && (
        <>
          <Space h='xs' />
          <SyncCyclePassScoresButton cycleId={cycleId} isAdmin={isAdmin} />
        </>
      )}
      <Space h='md' />
      <StageProgressesTable cycleId={cycleId} namespace='cycles-progresses' />
    </>
  )
}
