import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useGetCycleEmailTemplatesQuery,
  useGetCycleIntegrationsQuery,
  useGetCycleQuery
} from '../../../../redux/query/hire/cyclesApi.slice'
import ErrorLoading from '../../../core/ErrorLoading'
import Loading from '../../../core/Loading'
import { showNotification } from '@mantine/notifications'
import _ from 'lodash'
import { useUpdateTriggerMutation } from '../../../../redux/query/hire/triggersApi.slice'
import TriggerEditForm from './TriggerEditForm'
import LinkedBatteriesContext from '../../../../contexts/LinkedBatteriesContext'
import EmailTemplatesContext from '../../../../contexts/EmailTemplatesContext'
import {
  useGetInviteConfigTemplateByCycleAndTriggerQuery
} from '../../../../redux/query/build/inviteConfigTemplateApi.slice';
import InviteConfigTemplatesContext from '../../../../contexts/InviteConfigTemplatesContext';

export default function EditCycleTriggerApp () {
  const { id, triggerId } = useParams()
  const navigate = useNavigate()

  const { data: cycle, error: cycleError, isLoading: cycleLoading } = useGetCycleQuery(id)
  const { data: emailTemplates, error: emailTemplatesError, isLoading: emailTemplatesLoading } = useGetCycleEmailTemplatesQuery(id)
  const { data: integrations, error: integrationsError, isLoading: integrationsLoading } = useGetCycleIntegrationsQuery(id)
  const { data: inviteConfigTemplate, error: inviteConfigTemplateError, isLoading: inviteConfigTemplateLoading } = useGetInviteConfigTemplateByCycleAndTriggerQuery({ cycleId: id, triggerId: triggerId })

  const [updateTrigger] = useUpdateTriggerMutation()

  if (cycleError || emailTemplatesError || integrationsError || inviteConfigTemplateError) {
    return <ErrorLoading />
  }

  if (cycleLoading || emailTemplatesLoading || integrationsLoading || inviteConfigTemplateLoading) {
    return <Loading />
  }

  const trigger = _.find(cycle?.triggers ?? [], { id: parseInt(triggerId) })

  const onSubmit = data => {
    updateTrigger({ id: trigger.id, ...data })
      .unwrap()
      .then(function () {
        showNotification({
          message: 'Successfully edited a trigger',
          color: 'green'
        })
        navigate(`/hire/cycles/${cycle.id}/triggers`)
      })
      .catch(err => {
        if (err.data?.type === 'validation_error') {
          for (const message of err.data.errors) {
            showNotification({
              title: 'Validation error',
              message: message,
              color: 'red'
            })
          }
        } else {
          showNotification({
            title: 'Something went wrong',
            message: 'There was an error updating this trigger',
            color: 'red'
          })
        }
      })
  }

  return (
    <LinkedBatteriesContext.Provider value={cycle.cycle_batteries}>
      <EmailTemplatesContext.Provider value={emailTemplates.data}>
        <InviteConfigTemplatesContext.Provider value={inviteConfigTemplate}>
          <TriggerEditForm
            trigger={trigger}
            onSubmit={onSubmit}
            statusSet={cycle.status_set}
            integrations={integrations}
          />
        </InviteConfigTemplatesContext.Provider>
      </EmailTemplatesContext.Provider>
    </LinkedBatteriesContext.Provider>
  )
}
