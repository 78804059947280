import React, { memo, useCallback, useState } from 'react';
import { isBetaOnly, QuestionType } from '../../../../js/generated/enums/QuestionType';
import { QuestionStateUpdate } from './QuestionsState';
import { notifications } from '@mantine/notifications';
import { Button, Group, Select, Space } from '@mantine/core';
import { RatingScaleModal } from './RatingScaleModal';
import WarningAlert from '../../../core/Alert/WarningAlert';
import { useDispatch, useSelector } from 'react-redux';
import {
  associatedAnswersChanged,
  hasAnyLogicDependentOnResponse
} from './UnpublishedQuestionLogic/unpublishedLogicSlice';

export const AddNewQuestion = memo(function AddNewQuestion ({ questionTypes, dispatch }) {
  const [addingQuestion, setAddingQuestion] = useState(false)
  const [ratingScaleShowing, setRatingScaleShowing] = useState(false)

  const makeNewQuestion = useCallback((questionType) => {
    setAddingQuestion(false)
    if (questionType === QuestionType.RatingScale) {
      setRatingScaleShowing(true)
    } else {
      dispatch({ questionType: questionType, type: QuestionStateUpdate.NewQuestion })
      if (isBetaOnly(questionType)) {
        notifications.show(
          {
            color: 'yellow',
            title: 'Beta Only',
            message: 'Question type ' + questionType + ' can only be published in Beta-style assessments.'
          }
        )
      }
    }
  }, [setAddingQuestion, setRatingScaleShowing, dispatch])

  const startAddingQuestion = useCallback(() => {
    setAddingQuestion(true)
  }, [setAddingQuestion])

  const stopAddingQuestion = useCallback(() => {
    setAddingQuestion(false)
  }, [setAddingQuestion])

  return (
    <div>
      {addingQuestion
        ? (
          <Select data={questionTypes} placeholder="Select type for new question" onChange={makeNewQuestion} searchable />
          )
        : null }
      {addingQuestion
        ? (
          <div>
            <Space h='sm' />
            <Button color='gray.6' onClick={stopAddingQuestion}>Cancel</Button>
          </div>
          )
        : (
          <Button tcolor='success' onClick={startAddingQuestion}>Add question</Button>
          )}
      <div>
        <RatingScaleModal
          showing={ratingScaleShowing}
          setShowing={setRatingScaleShowing}
          dispatch={dispatch}
        />
      </div>
    </div>
  )
})

export const QuestionTypeSelection = memo(function QuestionTypeSelection ({ questionTypes, dispatch, onCancel, onClose, newPosition, modifying = null }) {
  const [ratingScaleShowing, setRatingScaleShowing] = useState(false)
  const hasDependentLogic = useSelector((state) => hasAnyLogicDependentOnResponse(state, modifying ?? 0))
  const reduxDispatch = useDispatch()

  const makeNewQuestion = useCallback((questionType) => {
    if (questionType === QuestionType.RatingScale) {
      setRatingScaleShowing(true)
    } else {
      onClose()
      dispatch({
        type: modifying ? QuestionStateUpdate.ChangeQuestionType : QuestionStateUpdate.NewQuestion,
        newPosition: modifying ? null : newPosition,
        questionType: questionType,
        questionId: modifying
      })
      if (modifying && hasDependentLogic) {
        reduxDispatch(associatedAnswersChanged({ questionId: modifying }))
      }
      if (isBetaOnly(questionType)) {
        notifications.show(
          {
            color: 'yellow',
            title: 'Beta Only',
            message: 'Question type ' + questionType + ' can only be published in Beta-style assessments.'
          }
        )
      }
    }
  }, [newPosition, modifying, hasDependentLogic, reduxDispatch, onClose, setRatingScaleShowing, dispatch])

  const ratingScaleDispatch = useCallback((action) => {
    console.debug('Dispatching modified rating scale action', { action, modifying })
    onClose()
    dispatch({
      ...action,
      type: modifying ? QuestionStateUpdate.ChangeQuestionType : QuestionStateUpdate.NewQuestion,
      newPosition: modifying ? null : newPosition,
      questionId: modifying
    })
    if (modifying && hasDependentLogic) {
      reduxDispatch(associatedAnswersChanged({ questionId: modifying }))
    }
  }, [newPosition, modifying, hasDependentLogic, reduxDispatch, onClose, dispatch])

  console.debug('QuestionTypeSelection updating', { newPosition, modifying, questionTypes })

  return (
    <div>
      {!!modifying && (
        <WarningAlert title='Answers will be reset'>
          All manual answer edits will be cleared and this question will have the default answers for its new type.
        </WarningAlert>
      )}
      <Group mx='xl' gap='xl' justify='space-between' grow>
        <Select
          data={questionTypes}
          placeholder={`Select ${modifying ? 'new ' : ''}type for ${modifying ? 'existing' : 'new'} question`}
          onChange={makeNewQuestion}
          searchable
        />
        <Button color='gray.6' onClick={onCancel}>Back</Button>
      </Group>
      <Space h='md' />
      <RatingScaleModal
        showing={ratingScaleShowing}
        setShowing={setRatingScaleShowing}
        dispatch={ratingScaleDispatch}
      />
    </div>
  )
})
