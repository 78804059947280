import React, { useMemo } from 'react'
import {
  useEditOrganizationMutation,
  useGetOrganizationQuery
} from '../../../redux/query/account/organizationsApi.slice'
import { useNavigate, useParams } from 'react-router-dom'
import ErrorLoading from '../../core/ErrorLoading'
import Loading from '../../core/Loading'
import OrganizationForm from './OrganizationForm'
import { showNotification } from '@mantine/notifications'

export default function OrganizationEdit () {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data: organization, isError, isLoading } = useGetOrganizationQuery(id)
  const [editOrganization, { isLoading: isProcessing }] = useEditOrganizationMutation()

  const initialValues = useMemo(() => ({
    name: organization?.name ?? '',
    abbreviation: organization?.abbreviation ?? '',
    from_address: organization?.from_address ?? '',
    manager: organization?.manager?.id.toString() ?? null,
    integrations: organization?.integrations.map(i => i.id.toString())
  }), [organization])

  if (isError) {
    return <ErrorLoading />
  }

  if (isLoading) {
    return <Loading />
  }

  const onSubmit = values => {
    editOrganization({ id: organization.id, ...values })
      .unwrap()
      .then(() => {
        showNotification({
          message: 'Successfully edited organization!',
          color: 'success',
          autoClose: 3000
        })
        navigate('../')
      })
      .catch(response => {
        if (response.status === 400 && response.data?.type === 'validation_error') {
          response.data.errors.forEach(error => {
            showNotification({
              title: 'Failed to edit organization!',
              message: error,
              color: 'red',
              autoClose: 10000
            })
          })
        } else {
          showNotification({
            title: 'Failed to edit organization!',
            message: 'Something went wrong',
            color: 'red',
            autoClose: 10000
          })
        }
      })
  }

  return <OrganizationForm initialValues={initialValues} onSubmit={onSubmit} isProcessing={isProcessing} />
}
