/* eslint react/prop-types: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, useRef } from 'react'
import { Input } from '@mantine/core'
import { RichTextEditor, Link } from '@mantine/tiptap'
import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import { useTimeout } from '@mantine/hooks'
import { PlaceholdersControl } from '../PlaceholdersControl';

export default function EmailEditor ({ onUpdate, errors, content, platform, category, onBlur = null }) {
  const lastContentRef = useRef(content)
  const editor = useEditor({
    extensions: [
      StarterKit,
      Link,
      Color,
      TextStyle
    ],
    content: content,
    onUpdate: function ({ editor }) {
      start(editor)
    },
    onBlur: function ({ editor, event }) {
      const currentContent = editor?.getHTML()
      console.debug('Editor onBlur.', { editor, event, currentContent, onBlur })
      if (currentContent && onBlur) {
        onBlur(currentContent)
      }
    }
  })

  const update = ([e]) => {
    const updatedContent = e?.getHTML() ?? ''
    lastContentRef.current = updatedContent
    onUpdate(updatedContent)
    return clear()
  }

  const { start, clear } = useTimeout(update, 300)

  useEffect(() => {
    if (lastContentRef.current !== content) {
      lastContentRef.current = content
      editor?.commands.setContent(content)
    }
  }, [content, editor?.commands])

  return (
    <Input.Wrapper
      aria-label='body'
      error={errors}
      label='Body'
      withAsterisk
    >
      <RichTextEditor editor={editor} mb='calc(10px / 2)' mih='400px' aria-labelledby='body'>
        <RichTextEditor.Toolbar sticky stickyOffset={50}>
          <RichTextEditor.ControlsGroup>
          <RichTextEditor.ColorPicker
            colors={[
              '#25262b',
              '#868e96',
              '#fa5252',
              '#e64980',
              '#be4bdb',
              '#7950f2',
              '#4c6ef5',
              '#228be6',
              '#15aabf',
              '#12b886',
              '#40c057',
              '#82c91e',
              '#fab005',
              '#fd7e14'
            ]}
          />
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Strikethrough />
            <RichTextEditor.ClearFormatting />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.H1 />
            <RichTextEditor.H2 />
            <RichTextEditor.H3 />
            <RichTextEditor.H4 />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.BulletList />
            <RichTextEditor.OrderedList />
            <RichTextEditor.Link />
            <RichTextEditor.Unlink />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <PlaceholdersControl editor={editor} platform={platform} category={category} />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>

        <RichTextEditor.Content h='100%' onClick={() => editor.commands.focus(!editor.view.hasFocus() && 'end') } />
      </RichTextEditor>
    </Input.Wrapper>
  )
}
