/* eslint react/prop-types: 0 */

import React, { memo, useCallback, useMemo, useRef, useState } from 'react'
import {
  Box,
  ScrollArea,
  ColorSwatch,
  Tooltip,
  Group,
  ActionIcon,
  Stack,
  Flex,
  Button,
  Center,
  HoverCard,
  Text,
  Transition,
  Popover,
  Checkbox,
  SimpleGrid,
  Overlay
} from '@mantine/core';
import * as DOMPurify from 'dompurify';
import { useDisclosure, useHover, useMergedRef } from '@mantine/hooks';
import { Tag, tagFromId } from '../../../js/generated/enums/Tag';
import { IconMail, IconX } from '@tabler/icons-react';
import * as styles from './DetailViewNotesCell.module.scss';
import {
  combineApplicantName,
  renderDateFromSource
} from './invites/formatUtil';
import { useEditCourtesyLetterSentAtDateMutation } from '../../../redux/query/hire/applicantsApi.slice';
import { BubbleMenu, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { RichTextEditor } from '@mantine/tiptap';
import { showNotification } from '@mantine/notifications';

const MAX_POPUP_HEIGHT = '15rem'
const DROPDOWN_OFFSET = 5

export function DetailViewNotesCell ({ value, row, mah }) {
  const { tag, id, courtesy_letter: letter } = row.original
  const courtesyLetter = letter?.sent ? letter : null
  const [focused, { open, close }] = useDisclosure(false)
  const hasComments = value.length > 0
  const viewport = useRef(null)
  const rootRef = useRef(null)
  const sizeRef = useRef(null)
  const { hovered, ref } = useHover()
  const popoverOffset = -(sizeRef.current?.clientHeight + DROPDOWN_OFFSET) ?? 0

  const showOpenNotesButton = useMemo(() => {
    const isScrolled = viewport.current?.scrollTop > 0

    return focused || hovered || isScrolled
  }, [focused, hovered])

  const mergedRef = useMergedRef(ref, rootRef)

  const handleOpen = useCallback((event) => {
    if (window.getSelection().toString().length > 0) return
    event.stopPropagation()
    open()
  }, [open])

  const stopEvent = e => e.stopPropagation()

  if (!hasComments && !tag && !courtesyLetter) {
    return (
      <Box mah={mah} className={styles.root} ref={mergedRef} h={mah} pos='relative'>
        <Box onClick={stopEvent} className={styles.commentsContainer} p='xs' w='100%' mb={0}>
          <NotesCellTopItems id={id} tag={tag} courtesyLetter={courtesyLetter} />
        </Box>
        <OpenNotesButton id={id} opened={showOpenNotesButton} h={mah} />
      </Box>
    )
  }

  return (
    <Box mah={mah} className={styles.root} ref={mergedRef} h={mah} pos='relative'>
      <Popover opened={focused} position='bottom' offset={popoverOffset} width='target'>
        <Popover.Target ref={sizeRef}>
          <Box style={{ borderRadius: 4 }} ref={ref}>
            <ScrollArea.Autosize mah={mah} onClick={stopEvent} viewportRef={viewport}>
              <Box onClick={stopEvent} className={styles.commentsContainer} p='xs' w='100%'>
                <NotesCellTopItems id={id} tag={tag} courtesyLetter={courtesyLetter} mb={0} />
              </Box>
              <OpenNotesButton id={id} opened={showOpenNotesButton} h={mah} />
              <Box onClick={handleOpen} className={styles.commentsContainer} p='xs' w='100%'>
                <NotesCell applicantId={id} comments={value} />
              </Box>
            </ScrollArea.Autosize>
          </Box>
        </Popover.Target>
        <Popover.Dropdown px={0} onClick={e => e.stopPropagation()} mih={mah}>
          <Box>
            <Flex justify='space-between' align='center' gap='sm' px='xs' mb={hasComments && 'sm'} onClick={stopEvent}>
              <ActionIcon onClick={close}><IconX /></ActionIcon>
            </Flex>
            <ScrollArea.Autosize mah={MAX_POPUP_HEIGHT} style={{ cursor: 'default', overflow: 'hidden', textWrap: 'wrap' }}>
              <Box px='xs'>
                <NotesCell applicantId={id} comments={value} px='xs' />
              </Box>
            </ScrollArea.Autosize>
          </Box>
        </Popover.Dropdown>
      </Popover>
    </Box>
  )
}

function OpenNotesButton ({ id, opened }) {
  const targetRef = useRef()
  const openCard = useCallback((e) => {
    e.stopPropagation()
    const parentRow = document.querySelector(`tbody tr[data-rowid="${id}"]`)
    if (parentRow) {
      parentRow.click()
    }
  }, [id])

  return (
    <Center>
      <Transition mounted={opened} transition='fade-down' duration={300}>
        {(transitionStyles => (
          <Box style={transitionStyles}>
            <HoverCard closeDelay={150} openDelay={300} shadow='md' defaultOpened={false} position='top' width='15rem'>
              <HoverCard.Target ref={targetRef}>
                <Button variant='outline' color='gray' style={styles} onClick={openCard} my='xs'>Open Notes</Button>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Text>Notes are private and will not be seen by anyone except you and authorized account members.</Text>
              </HoverCard.Dropdown>
            </HoverCard>
          </Box>
        ))}
      </Transition>
    </Center>
  )
}

function NotesCell ({ applicantId, comments, ...props }) {
  return comments.length > 0 && (
    <Stack style={{ cursor: 'auto' }} gap='xs' {...props}>
    {comments.map((comment) => {
      return (
      <Box key={`${applicantId}-comment-${comment.id}`}>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(comment.content) }}></div>
      </Box>
      )
    })}
  </Stack>
  )
}

function NotesCellTopItems ({ id, tag, courtesyLetter, ...props }) {
  return (
    <Group justify='center' {...props}>
      {!!tag && <ColorSwatch size={18} color={getTagColor(tagFromId(tag))} />}
      {!!courtesyLetter && <CourtesyLetterIcon id={id} courtesyLetter={courtesyLetter} />}
    </Group>
  )
}

function CourtesyLetterIcon ({ id, courtesyLetter }) {
  const [opened, setOpened] = useState(false)
  return (
    <>
      <Popover withArrow shadow="md" trapFocus opened={opened} onChange={setOpened} closeOnClickOutside={false} keepMounted={false}>
        <Popover.Target>
          <Tooltip label={<CourtesyLetterTooltipLabel courtesyLetter={courtesyLetter} />}>
            <ActionIcon size='md' variant='light' color='gray' onClick={() => setOpened(prev => !prev)}>
              <IconMail size='1.75rem' />
            </ActionIcon>
          </Tooltip>
        </Popover.Target>
        <Popover.Dropdown>
          <CourtesyLetterEditor id={id} courtesyLetter={courtesyLetter} setOpened={setOpened} />
        </Popover.Dropdown>
      </Popover>
    </>
  )
}

const CourtesyLetterTooltipLabel = memo(function CourtesyLetterTooltipLabel ({ courtesyLetter }) {
  const letterSentBy = courtesyLetter.system_message ? 'system' : (courtesyLetter.updated_by ? combineApplicantName(courtesyLetter.updated_by) : 'Unknown User')
  const letterHeader = `Courtesy letter marked sent by ${letterSentBy}:`
  const sanitizedContent = useMemo(() => {
    return courtesyLetter.content && !courtesyLetter.system_message ? DOMPurify.sanitize(courtesyLetter.content) : ''
  }, [courtesyLetter.content, courtesyLetter.system_message])
  return (
    <Stack gap='xs'>
      <Text size='sm'>{letterHeader}</Text>
      {!!sanitizedContent && <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />}
      <Group justify='space-between'>
        <Text fs='italic' size='sm'>(Click to edit)</Text>
        <Text fs='italic' size='sm'>{renderDateFromSource(courtesyLetter.updated_at, null, true)}</Text>
      </Group>
    </Stack>
  )
})

function CourtesyLetterEditor ({ id, courtesyLetter, setOpened }) {
  const [sent, setSent] = useState(!!courtesyLetter.sent)
  const [content, setContent] = useState(() =>
    courtesyLetter.system_message ? '' : DOMPurify.sanitize(courtesyLetter.content)
  )
  const [edit, { isLoading: processing }] = useEditCourtesyLetterSentAtDateMutation()
  const onClick = () => {
    edit({ applicantId: id, sent: sent, content: sent ? content : '' })
      .unwrap()
      .then((resp) => {
        console.debug('Update courtesy letter sent at success response', { resp })
        showNotification({
          message: 'Courtesy letter details updated!',
          color: 'success',
          autoClose: 3000
        })
        setOpened(false)
      })
      .catch((e) => {
        console.error('Update courtesy letter sent at error response', { e })
        showNotification({
          title: 'Courtesy letter error',
          message: 'Something went wrong when trying to edit courtesy letter details.',
          color: 'red',
          autoClose: 7000
        })
      })
  }

  return (
    <Box onClick={e => e.stopPropagation()}>
      <SimpleGrid cols={1}>
        <LetterTextEditor content={content} onUpdate={setContent} disabled={!sent} processing={processing} />
        <Checkbox
          disabled={processing}
          label='Sent'
          checked={sent}
          onChange={(event) => setSent(event.currentTarget.checked)}
          mb='md'
        />
      </SimpleGrid>
      <SimpleGrid cols={2}>
        <Button onClick={() => setOpened(false)} color='gray' disabled={processing}>Cancel</Button>
        <Button onClick={onClick} color='success' loading={processing} disabled={sent && !content}>Save</Button>
      </SimpleGrid>
    </Box>
  )
}

const tipTapOptions = { duration: 0 }

function LetterTextEditor ({ content, onUpdate, disabled = false, processing = false }) {
  const editor = useEditor({
    content: disabled ? '' : content,
    autofocus: 'end',
    editable: !disabled && !processing,
    onUpdate: ({ editor }) => {
      onUpdate(editor.getHTML())
    },
    extensions: [
      StarterKit
    ]
  }, [disabled, processing])

  return (
    <Box maw='30vw' w='20rem' mih='5rem' aria-label='Letter details editor' pos='relative'>
      {(disabled || processing) && <Overlay color="#000" backgroundOpacity={0.35} blur={processing && !disabled ? 3 : 30} />}
      <RichTextEditor
        editor={editor}
      >
        {!!editor && (
          <BubbleMenu editor={editor} tippyOptions={tipTapOptions}>
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold />
              <RichTextEditor.Italic />
              <RichTextEditor.Link />
            </RichTextEditor.ControlsGroup>
          </BubbleMenu>
        )}
        <RichTextEditor.Content />
      </RichTextEditor>
    </Box>
  )
}

export function getTagColor (tag) {
  switch (tag) {
    case Tag.Black:
      return 'rgba(0, 18, 25, 1)'
    case Tag.Blue:
      return 'rgba(0, 95, 155, 1)'
    case Tag.Green:
      return 'rgba(148, 230, 189, 1)'
    case Tag.Yellow:
      return 'rgba(238, 155, 0, 1)'
    case Tag.Red:
      return 'rgba(174, 32, 18, 1)'
    default:
      return 'rgba(255, 255, 255, 0)'
  }
}
