import { useParams } from 'react-router-dom';
import { CyclePageTitle } from '../nav/CyclePageTitle';
import { Space } from '@mantine/core';
import { OpenInvitesTable } from './OpenInvitesTable';
import React from 'react';
import { LinksNavButton } from '../nav/LinksNavButton';

export function PublicLinksList () {
  const { cycleId } = useParams()

  return (
    <>
      <CyclePageTitle cycleId={cycleId} pageName='Public Links' />
      <Space h='xs' />
      <LinksNavButton cycleId={cycleId} />
      <Space h='md' />
      <OpenInvitesTable cycleId={cycleId} />
    </>
  )
}
