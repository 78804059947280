import React, { useMemo } from 'react'
import { showNotification } from '@mantine/notifications'
import { useCreateOrganizationMutation } from '../../../redux/query/account/organizationsApi.slice'
import Loading from '../../core/Loading'
import ErrorLoading from '../../core/ErrorLoading'
import { useGetIntegrationsQuery } from '../../../redux/query/integration/integrationApi.slice'
import { useNavigate } from 'react-router-dom'
import OrganizationForm from './OrganizationForm'

export default function OrganizationCreate () {
  const navigate = useNavigate()
  const { data: integrations, isError, isLoading } = useGetIntegrationsQuery({ limit: 0 })
  const [createOrganization, { isLoading: isProcessing }] = useCreateOrganizationMutation()

  const initialValues = useMemo(() => ({
    name: '',
    abbreviation: '',
    from_address: '',
    manager: null,
    integrations: getDefaultIntegrations(integrations?.items ?? [])
  }), [integrations])

  if (isError) {
    return <ErrorLoading />
  }

  if (isLoading) {
    return <Loading />
  }

  const onSubmit = values => {
    createOrganization(values)
      .unwrap()
      .then(() => {
        showNotification({
          message: 'Successfully created organization!',
          color: 'success',
          autoClose: 3000
        })
        navigate('../')
      })
      .catch(response => {
        if (response.status === 400 && response.data?.type === 'validation_error') {
          response.data.errors.forEach(error => {
            showNotification({
              title: 'Failed to create organization!',
              message: error,
              color: 'red',
              autoClose: 10000
            })
          })
        } else {
          showNotification({
            title: 'Failed to create organization!',
            message: 'Something went wrong',
            color: 'red',
            autoClose: 10000
          })
        }
      })
  }

  return <OrganizationForm initialValues={initialValues} onSubmit={onSubmit} isProcessing={isProcessing} />
}

/**
 * Finds the default integrations from an array of integrations and returns their ids as string.
 * @param {array<object>} integrations The integrations to filter.
 * @returns {array<string>} Returns the default integration ids as string.
 */
const getDefaultIntegrations = integrations => {
  return integrations.filter(i => i.default === true).map(i => i.id.toString())
}
