import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Button, Group, Stack, Text } from '@mantine/core'
import { useDeleteOrganizationMutation } from '../../../redux/query/account/organizationsApi.slice'
import { showNotification } from '@mantine/notifications'

export default function OrganizationDelete () {
  const { id } = useParams()
  const navigate = useNavigate()
  const [deleteOrganization, { isLoading: processing }] = useDeleteOrganizationMutation()

  const onClick = () => {
    deleteOrganization({ id })
      .unwrap()
      .then(() => {
        showNotification({
          message: 'Successfully deleted organization!',
          color: 'success',
          autoClose: 3000
        })
        navigate('../')
      })
      .catch(response => {
        if (response.status === 400 && response.data?.type === 'validation_error') {
          console.debug(response.data.errors)
          response.data.errors.forEach(error => {
            showNotification({
              title: 'Failed to delete organization!',
              message: error,
              color: 'red',
              autoClose: 10000
            })
          })
        } else {
          showNotification({
            title: 'Failed to delete organization!',
            message: 'Something went wrong',
            color: 'red',
            autoClose: 10000
          })
        }
      })
  }

  return (
    <Stack>
      <Text>Are you sure you want to delete this organization? Deleting is IRREVERSIBLE</Text>
      <Group>
        <Button color='red.6' onClick={onClick} loading={processing}>Delete</Button>
        <Button component={Link} to='../' color='gray.6'>Cancel</Button>
      </Group>
    </Stack>
  )
}
