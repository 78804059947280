import { ActionIcon, Box, Divider, FloatingIndicator, SimpleGrid, DEFAULT_THEME, ColorPicker, Center, Button, Collapse, Tooltip, TextInput, Flex } from '@mantine/core';
import React, { useMemo, useState } from 'react'
import { getAllTags, tagIconFromEnum } from './TagUtil';
import { TagIcon } from '../../js/generated/enums/TagIcon';
import { IconCopy, IconPalette } from '@tabler/icons-react';

function TagView ({ onTagClick: handleTagClick, onColorClick: handleColorUpdate, currentColor = DEFAULT_THEME.colors.gray[6], currentTag = TagIcon.Flag }) {
  const tags = getAllTags()

  const [rootRef, setRootRef] = useState(null)
  const [controlsRefs, setControlsRefs] = useState({})
  const setControlRef = (name) => (node) => {
    controlsRefs[name] = node;
    setControlsRefs(controlsRefs);
  }

  const indicatorStyles = useMemo(() => {
    return {
      boxShadow: `0 0 6px 2px ${currentColor}`,
      borderRadius: '8px'
    }
  }, [currentColor])

  return (
    <Box w='24rem' pos='relative'>
      <SimpleGrid cols={9} pos='relative' ref={setRootRef}>
        <FloatingIndicator
          target={controlsRefs[currentTag]}
          parent={rootRef}
          style={indicatorStyles}
        />
        {tags.map((tag) => (
          <Center key={tag}>
            <ActionIcon
              color={currentColor}
              onClick={() => handleTagClick(tag)}
              size='lg'
              ref={setControlRef(tag)}
              mod={{ active: currentTag === tag }}
            >
              {tagIconFromEnum(tag, { size: '2rem' })}
            </ActionIcon>
          </Center>
        ))}
      </SimpleGrid>
      <Divider my='md' />
      <ColorView currentColor={currentColor} onColorUpdate={handleColorUpdate} />
    </Box>
  )
}

function ColorView ({ currentColor, onColorUpdate: handleColorUpdate }) {
  const [showColorPicker, setShowColorPicker] = useState(false)
  const colors = useMemo(() => {
    // remove element at "dark" key from DEFAULT_THEME.colors
    const newColors = { ...DEFAULT_THEME.colors }
    delete newColors.dark

    return Object.keys(newColors).map((name) => ({
      name: name,
      hex: newColors[name][6]
    }))
  }, [])

  const [rootRef, setRootRef] = useState(null)
  const [controlsRefs, setControlsRefs] = useState({})
  const setControlRef = (name) => (node) => {
    controlsRefs[name] = node;
    setControlsRefs(controlsRefs);
  }

  const indicatorStyles = useMemo(() => {
    return {
      boxShadow: 'inset 0 0 0 5px var(--mantine-color-blue-4)',
      borderRadius: '4px'
    }
  }, [])

  return (
    <>
      <SimpleGrid cols={7} pos='relative' spacing={6} my='md' ref={setRootRef}>
        {colors.map((colorProps) => {
          return (
            <SwatchButton
              key={`${colorProps.name}-swatch`}
              colorProps={colorProps}
              onColorUpdate={handleColorUpdate}
              ref={setControlRef(colorProps.hex)}
            />
          )
        })}
        <Tooltip key={'custom-swatch'} label='Custom color'>
          <Button color={currentColor} variant='light' onClick={() => setShowColorPicker(show => !show)} p={0}><IconPalette /></Button>
        </Tooltip>
        <FloatingIndicator
          target={controlsRefs[currentColor]}
          parent={rootRef}
          style={indicatorStyles}
        />
      </SimpleGrid>
      <Collapse in={showColorPicker}>
        <Flex mb='xs' justify='flex-end'>
          <TextInput
            value={currentColor}
            onChange={event => handleColorUpdate(event.currentTarget.value)}
            rightSection={
              <ActionIcon onClick={() => navigator.clipboard.writeText(currentColor)}>
                <IconCopy />
              </ActionIcon>
            }
          />
        </Flex>
        <ColorPicker
          format='hex'
          value={currentColor}
          onChange={handleColorUpdate}
          fullWidth
        />
      </Collapse>
    </>
  )
}

const SwatchButton = React.forwardRef(function SwatchButton ({ colorProps, onColorUpdate: handleColorUpdate }, ref) {
  const { name, hex } = colorProps
  const formattedName = name?.charAt(0).toUpperCase() + name?.slice(1) ?? ''

  return (
    <Tooltip label={formattedName}>
      <Button bg={hex} ref={ref} fullWidth p={0} onClick={() => handleColorUpdate(hex)} />
    </Tooltip>
  )
})

export { TagView }
