import React from 'react'
import { Header, TableDataProvider } from '../../core/ReactTable/CleanTable'
import { Anchor, Text } from '@mantine/core'
import { ReactTable, ReactTableContextsProvider } from '../../core/ReactTable/ReactTable'
import { useSelector } from 'react-redux'
import { selectAllParamsQueryData } from '../../core/ReactTable/paramsSlice'
import { useGetOrganizationsQuery } from '../../../redux/query/account/organizationsApi.slice'
import { format } from '../../../util/date.util'
import { IconChartHistogram, IconPencil, IconSettings, IconTrash } from '@tabler/icons-react'

const COLUMNS = [
  {
    Header: <Header centered={true}>Created At</Header>,
    id: 'create_date',
    accessor: 'create_date',
    Cell: ({ cell: { value } }) => <Text ta='center'>{format(value)}</Text>,
    sortable: true,
    hideable: true
  },
  {
    Header: <Header centered={true}>Name</Header>,
    id: 'name',
    accessor: 'name',
    Cell: ({ cell: { value } }) => <Text>{value}</Text>,
    sortable: true,
    hideable: true
  },
  {
    Header: <Header centered={true}>Abbreviation</Header>,
    id: 'abbreviation',
    accessor: 'abbreviation',
    Cell: ({ cell: { value } }) => <Text>{value ?? '-'}</Text>,
    sortable: true,
    hideable: true
  },
  {
    Header: <Header centered={true}>Project Manager</Header>,
    id: 'manager',
    accessor: 'manager.email',
    Cell: ({ cell: { value } }) => <Text>{value ?? '-'}</Text>,
    sortable: true,
    hideable: true
  },
  {
    Header: <Header centered={true}>From Address</Header>,
    id: 'from_address',
    accessor: 'from_address',
    Cell: ({ cell: { value } }) => <Text>{value ?? 'no-reply@hirescore.com'}</Text>,
    sortable: true,
    hideable: true
  }
]

const ACTIONS = [
  {
    label: 'Edit',
    leftSection: <IconPencil />,
    href: id => `/clients/organizations/${id}/edit`,
    component: Anchor
  },
  {
    label: 'SkillBuilder Dashboard',
    leftSection: <IconChartHistogram />,
    href: id => `/skillbuilder/dashboard/organizations/${id}`,
    component: Anchor
  },
  {
    label: 'Configuration',
    leftSection: <IconSettings />,
    href: id => `/clients/organizations/${id}/configuration`,
    component: Anchor
  },
  {
    label: 'Delete',
    leftSection: <IconTrash />,
    href: id => `/clients/organizations/${id}/delete`,
    component: Anchor
  }
]

const TABLE_PROPS = {
  defaultHiddenColumns: [],
  defaultFilters: {},
  columns: COLUMNS,
  filters: [],
  actions: ACTIONS,
  searchable: true
}

export default function OrganizationTable () {
  const { columns, defaultFilters, defaultHiddenColumns, ...otherTableProps } = TABLE_PROPS

  return (
    <ReactTableContextsProvider
      columns={columns}
      namespace='organization-table'
      defaultFilters={defaultFilters}
      defaultHiddenColumns={defaultHiddenColumns}
    >
      <OrganizationTableDataProvider>
        <ReactTable {...otherTableProps} />
      </OrganizationTableDataProvider>
    </ReactTableContextsProvider>
  )
}

/**
 * @param {string} namespace
 * @param {boolean} [skip]
 * @returns {array}
 */
function useOrganizationQuery (namespace, skip = false) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data: collection, isFetching: querying } = useGetOrganizationsQuery({ ...queryParams }, { skip })
  return [collection ?? null, querying]
}

function OrganizationTableDataProvider ({ children }) {
  return <TableDataProvider queryFn={useOrganizationQuery}>{children}</TableDataProvider>
}
